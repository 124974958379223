<template>
    <CInputCheckbox
        :checked="value"
        @update:checked="valueChanged"
        :label="label"
        :invalidFeedback="invalidFeedback"
        :isValid="isValid"
        :required="required"
        :description="description"
    />
</template>

<script>
export default {
    name: 'JetCheckbox',

    emits: ['update:checked'],

    props: {
        value: {
            default: null,
        },
        label: String,
        invalidFeedback: {
            type: String,
            default: '',
        },
        horizontal: {
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
    },

    computed: {
        isValid() {
            return this.invalidFeedback == '' ? null : false;
        },
    },

    methods: {
        valueChanged(value) {
            this.$emit('input', value);
        },
    },
};
</script>
