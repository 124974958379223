<template>
    <CDropdown
        add-menu-classes="py-0 overflow-hidden"
        class="c-header-nav-items"
        inNav
        placement="bottom-end"
    >
        <template #toggler>
            <CHeaderNavLink>
                <div
                    v-c-tooltip="{
                        content: __('navigation.profile'),
                        popperOptions: {},
                    }"
                    class="c-avatar"
                >
                    <CIcon name="cil-user" />
                </div>
            </CHeaderNavLink>
        </template>
        <Link
            :href="route('profile.show')"
            class="dropdown-item"
            role="menuitem"
            style="gap: 1rem"
        >
            <CIcon name="cil-user" />
            {{ __('navigation.profile') }}
        </Link>

        <Link
            :href="route('logout')"
            as="button"
            class="dropdown-item"
            method="post"
            role="menuitem"
            style="gap: 1rem"
            type="button"
        >
            <CIcon name="cil-lock-locked" />
            {{ __('navigation.logout') }}
        </Link>
    </CDropdown>
</template>

<script>
export default {
    name: 'TheHeaderDropdownAccnt',
};
</script>
