<template>
    <dialog-modal
        :closeable="false"
        :show.sync="show"
        max-width="sm"
        @close="$emit('close')"
    >
        <template #title>{{ title }}</template>

        <template #content>
            <slot />
        </template>

        <template #footer>
            <jet-button color="secondary" @click.stop="$emit('close')"
                >{{ cancelButtonText || __('general.action.cancel') }}
            </jet-button>
            <jet-button color="primary" @click.stop="$emit('confirm')">
                {{ primaryButtonText || __('general.action.confirm') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import WorkOrderForm from '@/Pages/WorkOrders/WorkOrderForm/Form.vue';
import AssetSelectionField from '@/Pages/WorkOrders/WorkOrderForm/AssetSelectionField.vue';

export default {
    components: {
        AssetSelectionField,
        WorkOrderForm,
        DialogModal,
        JetButton,
        JetInput,
    },
    props: {
        show: { type: Boolean, default: false },
        title: { type: String },
        cancelButtonText: { type: String },
        primaryButtonText: { type: String },
        performAction: { type: Function },
    },
    emits: ['close', 'confirm'],
};
</script>
