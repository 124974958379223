<template>
    <div>
        <div ref="sticky" aria-hidden="true" class="sticky-header-wrapper">
            <slot/>
        </div>
        <div ref="orig" class="sticky-header-orig">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StickyTableHeader',
    mounted() {
        const el =
            this.$refs.orig.getElementsByClassName('table-responsive')?.[0];
        const sticky =
            this.$refs.sticky.getElementsByClassName('table-responsive')?.[0];

        el?.addEventListener('scroll', this.onScrollEl);
        sticky?.addEventListener('scroll', this.onScrollSticky);
    },
    beforeDestroy() {
        const el =
            this.$refs.orig.getElementsByClassName('table-responsive')?.[0];
        const sticky =
            this.$refs.sticky.getElementsByClassName('table-responsive')?.[0];

        el?.removeEventListener('scroll', this.scrollSticky);
        sticky?.removeEventListener('scroll', this.scrollEl);
    },
    methods: {
        onScrollSticky(event) {
            let el = this.$refs.orig.getElementsByClassName('table-responsive')?.[0];
            el.scrollLeft = event.target.scrollLeft;
        },
        onScrollEl(event) {
            let el = this.$refs.sticky.getElementsByClassName('table-responsive')?.[0];
            el.scrollLeft = event.target.scrollLeft;
        }
    }
};
</script>

<style scoped>
.sticky-header-wrapper {
    position: sticky;
    top: 56px;
    border-top: 8px solid #eaedef;
    overflow: hidden;
    height: 57px;
    z-index: 1;
}

.card-body .sticky-header-wrapper {
    border-top-color: #fff;
}

.sticky-header-orig {
    margin-top: -48px;
}
</style>
