<script>
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import JetSelect from '@/Jetstream/Select.vue';
import { DateTime } from 'luxon';

export default {
    name: 'AssetCadenceConfiguration.vue',
    props: ['value', 'config'],
    components: { JetDatepicker, JetSelect },
    data() {
        return {
            cadence: {
                unit: null,
                value: null,
                startDate: null,
            },
        };
    },
    computed: {
        options() {
            return this.config.options;
        },
        isConfigurable() {
            return this.cadence.unit && this.cadence.unit !== 'never';
        },
        cadenceOptions() {
            return this.options.map((option) => ({
                label: option.label,
                value: option.unit,
            }));
        },
        payload() {
            return Object.assign(
                {},
                {
                    unit: this.cadence.unit || 'never',
                    value: this.cadence.value
                        ? parseInt(this.cadence.value)
                        : null,
                    startDate:
                        this.cadence.startDate?.toFormat('yyyy-MM-dd') || null,
                }
            );
        },
        rangeOptions() {
            let range = this.options.find(
                (option) => option.unit === this.cadence.unit
            )?.range;

            return range
                ? range.map((val) => ({
                      label: this.__(
                          `cadence.unit.${this.cadence.unit}.value`,
                          {
                              '%d': val,
                          }
                      ),
                      value: val.toString(),
                  }))
                : [];
        },
    },
    methods: {
        updateCadenceUnit(unit) {
            if (unit === 'never') {
                this.cadence.value = null;
                this.cadence.startDate = null;
            } else {
                this.cadence.value = this.rangeOptions.first()?.value ?? null;
                this.cadence.startDate =
                    this.cadence.startDate ?? DateTime.now();
            }

            this.$emit('update', this.payload);
        },
    },
};
</script>

<template>
    <div class="d-md-flex">
        <jet-select
            v-model="cadence.unit"
            :horizontal="false"
            :options="cadenceOptions"
            :placeholder="__('cadence.unit.label')"
            class="mb-md-0"
            @input="updateCadenceUnit($event)"
        />
        <jet-select
            v-model="cadence.value"
            :disabled="!isConfigurable"
            :horizontal="false"
            :options="rangeOptions"
            :placeholder="__('cadence.value.label')"
            class="mb-md-0 mx-md-3"
            @input="$emit('update', payload)"
        />
        <jet-datepicker
            v-model="cadence.startDate"
            :deletable="false"
            :disabled="!isConfigurable"
            :horizontal="false"
            :placeholder="__('cadence.start_date.label')"
            class="mb-0"
            @input="$emit('update', payload)"
        />
    </div>
</template>
