<template>
    <a :href="link" target="_blank">{{ email }}</a>
</template>
<script>
export default {
    name: 'EmailLink',
    props: {
        email: String,
    },
    computed: {
        link() {
            return 'mailto:' + this.email;
        },
    },
};
</script>
