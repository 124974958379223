<template>
    <div v-if="value">
        <table class="table table-unstyled">
            <thead>
                <tr>
                    <th>{{ __('maintainers.sla.priority.title') }}</th>
                    <th>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div>
                                {{
                                    __('maintainers.sla.time_to_respond.title')
                                }}
                            </div>
                            <info-icon
                                :tooltip="
                                    __(
                                        'maintainers.sla.time_to_respond.tooltip'
                                    )
                                "
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('high')"
                        />
                    </td>
                    <td>
                        <duration-selector v-model="value.timeToRespond.high" />
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('medium')"
                        />
                    </td>
                    <td>
                        <duration-selector
                            v-model="value.timeToRespond.medium"
                            class="py-1"
                        />
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('low')"
                        />
                    </td>
                    <td>
                        <duration-selector v-model="value.timeToRespond.low" />
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <div
                            class="d-flex justify-content-between align-items-center py-1"
                        >
                            <div>
                                {{
                                    __('maintainers.sla.time_to_resolve.title')
                                }}
                            </div>
                            <info-icon
                                :tooltip="
                                    __(
                                        'maintainers.sla.time_to_resolve.tooltip'
                                    )
                                "
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('high')"
                        />
                    </td>
                    <td>
                        <duration-selector v-model="value.timeToResolve.high" />
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('medium')"
                        />
                    </td>
                    <td>
                        <duration-selector
                            v-model="value.timeToResolve.medium"
                            class="py-1"
                        />
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('low')"
                        />
                    </td>
                    <td>
                        <duration-selector v-model="value.timeToResolve.low" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <label class="required">{{
                __('maintainers.sla.working_hours.title')
            }}</label>
            <working-hours-selector
                v-model="value.workingHours"
                :error="getError('sla.workingHours')"
            />
        </div>
        <div>
            <label>{{ __('maintainers.sla.agreement_term.title') }}</label>
            <jet-datepicker
                :horizontal="false"
                :invalid-feedback="getError('sla.agreement.startDate')"
                :label="__('maintainers.sla.agreement_term.from.title')"
                :placeholder="__('general.selection.placeholder')"
                :required="true"
                :value="value.agreement.startDate"
                class="mb-2"
                @input="setStartDate($event)"
            />
            <jet-datepicker
                :horizontal="false"
                :invalid-feedback="getError('sla.agreement.endDate')"
                :label="__('maintainers.sla.agreement_term.to.title')"
                :min-date="value.agreement.startDate"
                :placeholder="__('general.selection.placeholder')"
                :required="true"
                :value="value.agreement.endDate"
                class="mb-0"
                @input="setEndDate($event)"
            />
        </div>
    </div>
</template>
<script>
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';
import InfoIcon from '@/Components/InfoIcon.vue';
import DurationSelector from '@/Pages/Stakeholders/Maintainers/DurationSelector.vue';
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import JetRadioGroup from '@/Jetstream/RadioGroup.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import SimpleSelect from '@/Pages/Stakeholders/Maintainers/SimpleSelect.vue';
import WorkingHoursSelector from '@/Pages/Stakeholders/Maintainers/WorkingHoursSelector.vue';

export default {
    name: 'ServiceLevelAgreementForm',
    components: {
        WorkingHoursSelector,
        SimpleSelect,
        JetCheckbox,
        JetRadioGroup,
        JetDatepicker,
        DurationSelector,
        InfoIcon,
        WorkOrderPriority,
    },
    props: {
        value: {},
        errors: {},
    },
    methods: {
        getPriorityLabel(priority) {
            return (
                this.$page.props.priorities?.data.find(
                    (el) => el.value === priority
                ) || {
                    value: priority,
                    label: priority,
                }
            );
        },
        setStartDate(date) {
            this.value.agreement.startDate = date?.toFormat('y-MM-dd');
        },
        setEndDate(date) {
            this.value.agreement.endDate = date?.toFormat('y-MM-dd');
        },
        getError(field) {
            return this.errors?.[field];
        },
    },
};
</script>
