<template>
    <CCard>
        <CustomCardHeader
            :hasPermission="hasAnyPermission('configuration.create')"
            :href="route('assets.configurations.create', asset.id)"
            action-icon="cil-plus"
            :title="__('assets.configurations.overview.header')"
            icon="cil-layers"
        />
        <CCardBody>
            <div v-if="!configurations.data || configurations.data.length == 0">
                <h3>
                    {{ __('assets.configurations.overview.empty.headline') }}
                </h3>
                <p>
                    {{ __('assets.configurations.overview.empty.description') }}
                </p>
            </div>
            <div v-else>
                <CCard
                    v-for="(config, index) in configurations.data"
                    :key="config.id"
                    :show="true"
                    class="mb-0"
                >
                    <CCardHeader
                        body-wrapper
                        class="d-flex justify-content-between align-items-center cursor-pointer bg-blue-light"
                        @click="selectedConfig = config"
                    >
                        <div>
                            <h5 class="mb-0">
                                {{
                                    __(
                                        'assets.configurations.overview.header.configuration'
                                    )
                                }}
                                {{ config.version }}
                            </h5>
                            <small class="text-muted">{{
                                activationPeriod(config, index)
                            }}</small>
                        </div>
                        <div
                            v-if="hasAnyPermission('configuration.update')"
                            class="d-flex"
                        >
                            <Link
                                v-if="hasAnyPermission('configuration.update')"
                                :href="
                                    route('assets.configurations.edit', [
                                        asset.id,
                                        config.id,
                                    ])
                                "
                                class="btn btn-primary btn-icon mx-2"
                            >
                                <CIcon name="cil-pencil" />
                            </Link>
                            <jet-button
                                v-if="hasAnyPermission('configuration.delete')"
                                class="btn-icon"
                                color="danger"
                                @click.stop="deleteConfiguration(config)"
                            >
                                <CIcon name="cil-trash" />
                            </jet-button>
                        </div>
                        <div v-if="index > 0 && !isSelected(config)">
                            <CIcon name="cil-caret-bottom" />
                        </div>
                    </CCardHeader>
                    <CCollapse :show="isSelected(config)">
                        <CCardBody>
                            <dl>
                                <div class="my-1 row">
                                    <dt class="col-md-5 text-uppercase">
                                        {{
                                            __(
                                                'assets.configurations.overview.label.hardware_version'
                                            )
                                        }}
                                    </dt>
                                    <dd class="col-md-7 text-muted">
                                        <span
                                            v-if="!!config.hardware_version"
                                            >{{
                                                config.hardware_version.name
                                            }}</span
                                        >
                                        <span v-else>{{
                                            __('general.not_available')
                                        }}</span>
                                    </dd>
                                </div>
                                <div class="my-1 row">
                                    <dt class="col-md-5 text-uppercase">
                                        {{
                                            __(
                                                'assets.configurations.overview.label.software_version'
                                            )
                                        }}
                                    </dt>
                                    <dd class="col-md-7 text-muted">
                                        <span
                                            v-if="!!config.software_version"
                                            >{{
                                                config.software_version.name
                                            }}</span
                                        >
                                        <span v-else>{{
                                            __('general.not_available')
                                        }}</span>
                                    </dd>
                                </div>
                                <div class="my-1 row">
                                    <dt class="col-md-5 text-uppercase">
                                        {{
                                            __(
                                                'assets.configurations.overview.label.conops'
                                            )
                                        }}
                                    </dt>
                                    <dd class="col-md-7 text-muted">
                                        <span v-if="!!config.conops">{{
                                            config.conops
                                        }}</span>
                                        <span v-else>{{
                                            __('general.not_available')
                                        }}</span>
                                    </dd>
                                </div>
                                <div class="my-1 row">
                                    <dt class="col-md-5 text-uppercase">
                                        {{
                                            __(
                                                'assets.configurations.overview.label.standards_obtained'
                                            )
                                        }}
                                    </dt>
                                    <dd class="col-md-7 text-muted">
                                        <span v-if="!!config.standards">{{
                                            config.standards.join(', ')
                                        }}</span>
                                        <span v-else>{{
                                            __('general.not_available')
                                        }}</span>
                                    </dd>
                                </div>
                                <div class="my-1 row">
                                    <dt class="col-md-5 text-uppercase">
                                        {{
                                            __(
                                                'assets.configurations.overview.label.detection_algorithm'
                                            )
                                        }}
                                    </dt>
                                    <dd class="col-md-7 text-muted">
                                        <div
                                            v-if="
                                                config.detection_algorithms
                                                    .length > 0
                                            "
                                        >
                                            <div
                                                v-for="algorithm in config.detection_algorithms"
                                                :key="algorithm.id"
                                            >
                                                {{ algorithm.name }}
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{ __('general.not_available') }}
                                        </div>
                                    </dd>
                                </div>
                                <div class="my-1 row">
                                    <dt class="col-md-5 text-uppercase">
                                        {{
                                            __(
                                                'assets.configurations.overview.label.auxiliary_hardware'
                                            )
                                        }}
                                    </dt>
                                    <dd class="col-md-7 text-muted">
                                        <div
                                            v-if="
                                                config.auxiliary_hardware
                                                    .length > 0
                                            "
                                        >
                                            <div
                                                v-for="hardware in config.auxiliary_hardware"
                                                :key="hardware.id"
                                            >
                                                {{ hardware.name }}
                                                <small
                                                    v-if="
                                                        hardware.serial_number
                                                    "
                                                    class="text-muted"
                                                    >({{
                                                        hardware.serial_number
                                                    }})</small
                                                >
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{ __('general.not_available') }}
                                        </div>
                                    </dd>
                                </div>
                                <div class="my-1 row">
                                    <dt class="col-md-5 text-uppercase">
                                        {{
                                            __(
                                                'assets.configurations.overview.label.qualifications'
                                            )
                                        }}
                                    </dt>
                                    <dd class="col-md-7 text-muted">
                                        <span v-if="!!config.qualifications"
                                            >({{
                                                config.qualifications.length
                                            }})</span
                                        >
                                        <span v-else>{{
                                            __('general.not_available')
                                        }}</span>
                                    </dd>
                                </div>
                            </dl>
                            <CAlert
                                v-for="qualification in config.qualifications"
                                :key="qualification"
                                class="mb-2"
                                color="success"
                            >
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                >
                                    {{ qualification }}
                                    <CIcon name="cil-check-circle" />
                                </div>
                            </CAlert>
                            <div
                                v-if="
                                    hasAnyPermission(
                                        'configuration.activate'
                                    ) && index != 0
                                "
                                class="d-flex justify-content-between mt-3 bordered py-2"
                            >
                                <div>
                                    <jet-button
                                        color="success"
                                        @click.stop="
                                            activateConfiguration(config)
                                        "
                                    >
                                        {{ __('general.action.activate') }}
                                    </jet-button>
                                </div>
                            </div>
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </div>
        </CCardBody>
    </CCard>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    props: ['asset', 'configurations', 'qualifications'],

    data() {
        return {
            selectedConfig: null,
        };
    },

    components: { CustomCardHeader, JetButton },

    methods: {
        isSelected(config) {
            if (!this.selectedConfig) {
                return false;
            }

            return this.selectedConfig.id === config.id;
        },

        activationPeriod(config, index) {
            const date = this.$options.filters.dateTimeMedium(
                config.activated_at,
                this.currentLocale
            );

            if (index === 0) {
                return (
                    this.__(
                        'assets.configurations.overview.label.active_since'
                    ) +
                    ': ' +
                    date
                );
            }

            let prevDate = this.configurations.data[index - 1];

            if (prevDate) {
                prevDate = this.$options.filters.dateTimeMedium(
                    prevDate.activated_at,
                    this.currentLocale
                );

                return (
                    this.__('assets.configurations.overview.label.active') +
                    ': ' +
                    date +
                    '-' +
                    prevDate
                );
            }
        },

        getQualificationColor(config, qualification) {
            if (
                config.qualifications &&
                config.qualifications.includes(qualification)
            ) {
                return 'success';
            }

            return 'danger';
        },

        getQualificationUrl(qualification) {
            return 'https://letmegooglethat.com/';
        },

        deleteConfiguration(configuration) {
            this.$inertia.delete(
                route('assets.configurations.destroy', [
                    this.asset.id,
                    configuration.id,
                ])
            );
        },

        activateConfiguration(configuration) {
            this.$inertia.post(
                route('assets.configurations.activate', [
                    this.asset.id,
                    configuration.id,
                ])
            );
        },
    },

    mounted() {
        if (this.configurations && this.configurations.data.length) {
            this.selectedConfig = this.configurations.data[0];
        }
    },
};
</script>
<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.bordered {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
</style>
