<template>
    <CDropdown
        v-if="languages.length > 1"
        add-menu-classes="py-0"
        class="c-header-nav-items"
        inNav
        placement="bottom-end"
    >
        <template #toggler>
            <CHeaderNavLink
                ><span
                    v-c-tooltip="{
                        content: __('navigation.language'),
                        popperOptions: { positionFixed: true },
                    }"
                    style="line-height: 36px"
                    >{{ current.toUpperCase() }}</span
                ></CHeaderNavLink
            >
        </template>
        <a
            v-for="lang in languages"
            :key="lang.value"
            :href="route('language', lang.value)"
            class="dropdown-item"
            role="menuitem"
            >{{ lang.label }}
        </a>
    </CDropdown>
</template>

<script>
import { getLabelForLocale } from '@/Utils/getLabelForLocale';

export default {
    name: 'LanguageSelector',
    computed: {
        current() {
            return this.currentLocale;
        },
        languages() {
            return (
                this.$page.props.supported_locales?.map((locale) => ({
                    value: locale,
                    label: getLabelForLocale(locale),
                })) || []
            );
        },
    },
};
</script>
<style scoped>
>>> .dropdown-menu {
    min-width: auto;
    overflow: hidden;
}

>>> .dropdown-item {
    min-width: auto !important;
}
</style>
