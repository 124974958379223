<template>
    <div>
        <div v-if="attachments && attachments.length && canRead">
            <CListGroup>
                <CListGroupItem
                    v-for="attachment in attachments"
                    :key="attachment.id"
                    :class="{ 'border-0': inline }"
                    class="d-flex p-0"
                >
                    <div v-if="!inline" class="d-flex align-items-center">
                        <div
                            v-if="attachment.thumbnail"
                            :style="{
                                background:
                                    'url(' +
                                    attachment.thumbnail +
                                    ') center center no-repeat',
                            }"
                            class="media"
                        ></div>
                        <div
                            v-else
                            class="media d-flex align-items-center justify-content-center"
                        >
                            <CIcon name="cil-file" />
                        </div>
                    </div>
                    <div
                        class="d-flex justify-content-between p-2"
                        style="flex-grow: 1"
                    >
                        <div class="d-flex flex-column justify-content-center">
                            <div>
                                <a
                                    :href="
                                        attachment.uri ||
                                        route('attachments.show', attachment.id)
                                    "
                                    target="_blank"
                                    >{{ attachment.fileName }}
                                </a>
                                <CIcon
                                    v-if="!attachment.exists"
                                    v-c-tooltip="{
                                        content: __(
                                            'general.attachment.not_found'
                                        ),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    name="cil-warning"
                                />
                            </div>
                            <small class="text-muted">
                                {{ __('attachments.overview.size') }}:
                                {{ attachment.size | prettyBytes }}
                                &nbsp;&nbsp;&nbsp;{{
                                    attachment.createdAt
                                        | dateTimeMedium(currentLocale)
                                }}
                            </small>
                        </div>
                        <div v-if="!readonly" class="actions d-flex">
                            <div v-if="canRead" class="p-3">
                                <a
                                    v-c-tooltip="{
                                        content: __('general.action.download'),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    :href="
                                        route(
                                            'attachments.download',
                                            attachment.id
                                        )
                                    "
                                >
                                    <CIcon name="cil-cloud-download" />
                                </a>
                            </div>
                            <div v-if="canEdit" class="p-3">
                                <CLink
                                    v-c-tooltip="{
                                        content: __('general.action.edit'),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    @click.prevent="editFilename(attachment)"
                                >
                                    <CIcon name="cil-pencil" />
                                </CLink>
                            </div>
                            <div v-if="canDelete" class="p-3">
                                <div v-if="isDeletingAttachment(attachment)">
                                    <CSpinner color="primary" size="sm" />
                                </div>
                                <div v-else>
                                    <a
                                        v-c-tooltip="{
                                            content: __(
                                                'general.action.delete'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        :href="
                                            route(
                                                'attachments.destroy',
                                                attachment.id
                                            )
                                        "
                                        @click.prevent="
                                            deleteAttachment(attachment)
                                        "
                                    >
                                        <CIcon name="cil-trash" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </CListGroupItem>
            </CListGroup>
        </div>
        <div v-else>
            {{ __('attachments.overview.empty') }}
        </div>
        <jet-dialog-modal
            :show="!!selectedAttachment"
            @close="closeModal"
            :closeable="false"
        >
            <template #title>
                {{ __('attachments.modal.update_file_name.header') }}
            </template>

            <template #content>
                <div class="mt-2">
                    <jet-input
                        ref="filename"
                        v-model="form.name"
                        :horizontal="false"
                        :invalidFeedback="form.errors.name"
                        :label="__('attachments.modal.update_file_name.label')"
                        :placeholder="
                            __('attachments.modal.update_file_name.placeholder')
                        "
                        class="mt-1 block w-3/4"
                        type="text"
                        @keyup.enter.prevent="submitForm"
                        :autofocus="true"
                    >
                        <template #append>
                            <span v-if="selectedAttachment">
                                .{{ selectedAttachment.extension }}
                            </span>
                        </template>
                    </jet-input>
                </div>
            </template>

            <template #footer>
                <jet-button
                    :disabled="form.processing"
                    color="secondary"
                    @click.prevent="closeModal"
                >
                    {{ __('general.action.cancel') }}
                </jet-button>

                <jet-button
                    :disabled="!isFormSubmittable"
                    :is-processing="form.processing"
                    color="primary"
                    @click.prevent="submitForm"
                >
                    {{ __('general.action.save') }}
                </jet-button>
            </template>
        </jet-dialog-modal>
    </div>
</template>
<script>
import JetDialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import { read } from '@popperjs/core';

export default {
    components: { JetDialogModal, JetButton, JetInput },

    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        attachments: Array,
        inline: {
            type: Boolean,
            default: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        canRead: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            selectedAttachment: null,
            isDeleting: [],
            form: this.$inertia.form({
                name: null,
            }),
        };
    },

    methods: {
        read() {
            return read;
        },
        getFileTypeIcon(mimeType) {
            switch (mimeType) {
                case 'application/pdf':
                    return 'cil-file';
                case 'image/jpeg':
                case 'image/jpg':
                case 'image/png':
                    return;
            }
        },
        editFilename(attachment) {
            this.form = this.$inertia.form({ name: attachment.basename });
            this.selectedAttachment = attachment;
        },
        submitForm() {
            const id = this.selectedAttachment.id;

            this.form.processing = true;

            this.form.put(this.route('attachments.update', id), {
                preserveScroll: true,
                onSuccess: () => {
                    this.closeModal();

                    this.$inertia.visit(window.location.pathname, {
                        preserveScroll: true,
                    });
                },
                onError: (error) => {
                    this.$refs.filename.focus();
                },
                onFinish: () => {
                    this.form.processing = false;
                },
            });
        },
        deleteAttachment(attachment) {
            this.isDeleting = [...this.isDeleting, attachment.id];

            this.$inertia.delete(
                this.route('attachments.destroy', attachment.id),
                {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$emit('deleted', attachment);
                    },
                    onFinish: () => {
                        this.isDeleting = this.isDeleting.filter(
                            (id) => id !== attachment.id
                        );
                    },
                }
            );
        },
        closeModal() {
            this.selectedAttachment = null;
        },
        isDeletingAttachment(attachment) {
            return this.isDeleting.includes(attachment.id);
        },
    },
};
</script>
<style scoped>
.media {
    width: 100px;
    height: 100%;
}
</style>
