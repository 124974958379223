<template>
    <div class="d-flex overflow-hidden">
        <div style="width: 40px" class="d-flex align-items-center justify-content-center flex-shrink-0">
            <c-icon v-if="icon" :name="icon" size="sm" style="color: #636F83" />
        </div>
        <div class="overflow-hidden">
            <div>
                <b class="text-truncate d-block">{{ title }}</b>
            </div>
            <small class="text-truncate d-block text-muted">{{
                description
            }}</small>
        </div>
    </div>
</template>
<script>
export default {
    name: 'default',
    props: {
        notification: {},
        icon: {},
    },
    computed: {
        title() {
            return this.__(
                `notification.${this.notification.type}.title`,
                this.notification.payload
            );
        },
        description() {
            return this.__(`notification.${this.notification.type}`, this.notification.payload);
        },
    },
};
</script>
