<template>
    <div style="height: 18px">
        <CSwitch
            :checked.sync="selected"
            :class="{ onboarded: disabled }"
            :color="color"
            :disabled="disabled"
            class="operability-switch flex-shrink-0"
            shape="pill"
            size="sm"
            @update:checked="onChange"
        />
    </div>
</template>
<script>
export default {
    name: 'AssetOperabilitySwitch',
    props: {
        value: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
    },
    emits: ['changed'],
    data() {
        return {
            selected: true,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.selected = val;
            },
        },
    },
    computed: {
        color() {
            if (this.disabled) {
                return 'secondary';
            }

            return this.value ? 'operable' : 'inoperable';
        },
    },
    methods: {
        onChange() {
            this.$emit('input', this.selected);
            this.$emit('changed', this.selected);
        },
    },
};
</script>
