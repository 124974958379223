<template>
    <app-layout>
        <asset-details-header :asset="asset" />
        <div class="p-3">
            <ActivityLog :activities="activities" context="asset" />
            <Pagination :paginator="activities" />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import ActivityLog from '@/Components/ActivityLog/ActivityLog.vue';
import Pagination from '@/Components/Pagination.vue';

export default {
    components: {
        Pagination,
        AppLayout,
        AssetDetailsHeader,
        ActivityLog,
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        activities() {
            return this.$page.props.activities;
        },
    },
};
</script>
