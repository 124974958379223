<template>
    <CAlert v-if="hasFlashMessage" :color="color">
        <dynamic-content :template="content"/>
    </CAlert>
</template>
<script>
import {compileToFunctions} from 'vue-template-compiler';

const DynamicContent = {
    props: ['template'],
    data() {
        return {
            templateRender: null,
        };
    },
    render(h) {
        if (!this.templateRender) {
            return h('div');
        } else {
            // If there is a template, show it
            return this.templateRender();
        }
    },
    watch: {
        // Every time the template prop changes, I recompile it to update the DOM
        template: {
            immediate: true, // makes the watcher fire on first render, too.
            handler() {
                var res = compileToFunctions(this.template);

                this.templateRender = res.render;

                // staticRenderFns belong into $options
                this.$options.staticRenderFns = [];

                // clean the cache of static elements
                // this is a cache with the results from the staticRenderFns
                this._staticTrees = [];

                // Fill it with the new staticRenderFns
                for (var i in res.staticRenderFns) {
                    this.$options.staticRenderFns.push(res.staticRenderFns[i]);
                }
            },
        },
    },
};

export default {
    components: {DynamicContent},
    computed: {
        color() {
            return this.isSuccessMessage ? 'success' : 'danger';
        },
        flash() {
            return this.$page.props.flash;
        },
        isSuccessMessage() {
            return (
                this.flash &&
                this.flash.message &&
                this.flash.message.length > 0
            );
        },
        isErrorMessage() {
            return (
                this.flash && this.flash.error && this.flash.error.length > 0
            );
        },
        content() {
            const message = this.isSuccessMessage
                ? this.flash.message
                : this.flash.error;
            return '<div>' + message + '</div>';
        },
        hasFlashMessage() {
            return this.isSuccessMessage || this.isErrorMessage;
        },
    },
};
</script>
