<template>
    <button
        class="btn btn-link"
        style="min-width: 0; padding-left: 2.25rem"
        type="button"
    >
        <c-icon
            name="cil-plus"
            size="sm"
            style="position: absolute; left: 19px"
        />
        <slot>
            {{ __('general.action.add_new') }}
        </slot>
    </button>
</template>
<script>
export default {
    name: 'button-add-new',
};
</script>
