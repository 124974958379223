export default {
    methods: {
        preventParallelRequests() {
            this.$inertia.isRequestInProgress = false;

            this.$once(
                'hook:destroyed',
                this.$inertia.on('before', () => {
                    return !this.$inertia.isRequestInProgress;
                })
            );

            this.$once(
                'hook:destroyed',
                this.$inertia.on('start', () => {
                    this.$inertia.isRequestInProgress = true;
                })
            );

            this.$once(
                'hook:destroyed',
                this.$inertia.on('finish', () => {
                    this.$inertia.isRequestInProgress = false;
                })
            );
        },
    },
};
