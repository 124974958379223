<template>
    <CCardHeader
        class="d-flex justify-content-between align-items-center"
        style="min-height: 50px"
    >
        <h5 class="mb-0">{{ title }}</h5>
        <Link
            v-if="!!href && hasPermission"
            :href="href"
            class="btn btn-primary"
            :class="{ 'btn-icon': actionIcon }"
        >
            <slot name="label">
                <CIcon :name="actionIcon" v-if="actionIcon" />
                <span v-if="label">{{ label }}</span>
            </slot>
        </Link>
    </CCardHeader>
</template>
<script>
export default {
    props: ['title', 'label', 'href', 'icon', 'hasPermission', 'actionIcon'],
};
</script>
<style scoped>
>>> .disabled a {
    color: #ccc;
}
</style>
