<template>
    <app-layout>
        <div class="mb-3">
            <h4>{{ __('stakeholders.overview.title') }}</h4>
        </div>
        <owners-overview v-if="hasAnyPermission('owner.index')" />
        <operators-overview v-if="hasAnyPermission('operator.index')" />
        <maintainers-overview v-if="hasAnyPermission('maintainer.index')" />
        <delete-stakeholder-modal />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import OwnersTable from '@/Pages/Stakeholders/Owners/OwnersOverview.vue';
import OwnersOverview from '@/Pages/Stakeholders/Owners/OwnersOverview.vue';
import OperatorsOverview from '@/Pages/Stakeholders/Operators/OperatorsOverview.vue';
import MaintainersOverview from '@/Pages/Stakeholders/Maintainers/MaintainersOverview.vue';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';

export default {
    name: 'StakeholdersOverview',
    components: {
        DeleteStakeholderModal,
        MaintainersOverview,
        OperatorsOverview,
        OwnersOverview,
        OwnersTable,
        StickyTableHeader,
        CustomCardHeader,
        AppLayout,
    },
};
</script>
