<template>
    <dialog-modal
        :closeable="false"
        :show.sync="show"
        max-width="lg"
        :key="componentKey"
        @close="$emit('close')"
    >
        <template #title>{{ __('workorder.modal.create.title') }}</template>

        <template #content>
            <asset-selection-field
                v-if="showAssetSelection"
                :error="$page.props.errors.assetId"
                :label="__('workorder.modal.create.asset_selection.label')"
                :placeholder="
                    __('workorder.modal.create.asset_selection.placeholder')
                "
                :required="true"
                :search-url="route('workorders.create')"
                @selected="loadAssetDetails"
            />
            <work-order-form
                :asset-id="asset"
                :form="form"
                :is-type-forced="!!forcedWorkOrderType"
                :show-title="showAssetTitle"
                @upload-started="uploadInProgress = true"
                @upload-completed="uploadInProgress = false"
            />
        </template>

        <template #footer>
            <jet-button
                color="secondary"
                :disabled="uploadInProgress"
                @click.stop="$emit('close')"
                >{{ __('general.action.cancel') }}
            </jet-button>
            <jet-button
                :disabled="!asset || !form.isDirty || uploadInProgress"
                :is-processing="form.processing"
                color="primary"
                @click.stop="submitForm"
            >
                {{ __('general.action.create') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import WorkOrderForm from '@/Pages/WorkOrders/WorkOrderForm/Form.vue';
import AssetSelectionField from '@/Pages/WorkOrders/WorkOrderForm/AssetSelectionField.vue';

export default {
    components: {
        AssetSelectionField,
        WorkOrderForm,
        DialogModal,
        JetButton,
        JetInput,
    },
    props: ['assetId', 'show', 'forcedWorkOrderType'],
    emits: ['close', 'created'],
    data() {
        return {
            asset: null,
            uploadInProgress: false,
            form: this.$inertia.form({
                type: 'corrective_maintenance',
                priority: 'medium',
                assignee: 'unassigned',
                startDate: null,
                dueDate: null,
                title: null,
                description: null,
                ticketId: null,
                ticketLink: null,
                attachments: null,
            }),
            componentKey: 0,
        };
    },
    computed: {
        showAssetSelection() {
            return this.route().current() === 'workorders.index';
        },
        showAssetTitle() {
            return !!this.assetId;
        },
    },
    watch: {
        show(newValue) {
            if (newValue) {
                this.form = this.$inertia.form({
                    type: this.forcedWorkOrderType || 'corrective_maintenance',
                    priority: 'medium',
                    assignee: 'unassigned',
                    startDate: null,
                    dueDate: null,
                    title: null,
                    description: null,
                    ticketId: null,
                    ticketLink: null,
                    attachments: null,
                });

                this.form.clearErrors();
            }

            // Force rerender
            this.componentKey += 1;
            this.$nextTick(() => {
                this.asset = newValue ? this.assetId : null;
            });
        },
    },
    methods: {
        loadAssetDetails(asset) {
            this.asset = asset.id;
        },
        submitForm() {
            if (!this.asset) {
                return;
            }

            this.form
                .transform((data) => {
                    data.startDate = data.startDate?.toISODate();
                    data.dueDate = data.dueDate?.toISODate();
                    data.assetId = this.asset;

                    if (!data.attachments) {
                        delete data.attachments;
                    }

                    return data;
                })
                .post(this.route('workorders.store'), {
                    preserveState: true,
                    onSuccess: () => {
                        this.$emit('created');
                        this.$emit('close');
                    },
                });
        },
    },
};
</script>
