// Returns and array that includes the permission and its broader parent permissions.
// 'asset.status_check.read' => ['asset.*', 'asset.status_check.*', 'asset.status_check.read']
function getPermissionsWithParentScopes(permission) {
    let permissionParentScopes = permission.split('.');

    return permissionParentScopes.map((scope, index, array) => {
        // Return the original permission in case of the last item
        if (array.length == index + 1) {
            return permission;
        }

        // Match everything starting from scope to end of string
        const pattern = `(${scope}).*$`;
        const regex = new RegExp(pattern);
        return permission.replace(regex, '$1.*');
    });
}

export default {
    methods: {
        hasAnyPermission: function (requiredPermissions) {
            if (!Array.isArray(requiredPermissions)) {
                requiredPermissions = [requiredPermissions];
            }

            // Current permissions of the user
            const assignedPermissions = this.$page.props.auth.can;

            // Add parent scopes to allow access if a user has broader permissions.
            // Example:
            // - Required permision: 'asset.read'
            // A user with the broader permission `asset.*` also has access
            requiredPermissions = requiredPermissions.flatMap((permission) =>
                getPermissionsWithParentScopes(permission)
            );

            return requiredPermissions.some((permission) =>
                assignedPermissions.includes(permission)
            );
        },
    },
};
