<template>
    <app-layout>
        <CCard>
            <CustomCardHeader
                :hasPermission="hasAnyPermission('user.create')"
                :href="route('users.create')"
                :label="__('users.overview.action.add_user')"
                :title="__('users.overview.title')"
            />
            <CCardBody>
                <div class="d-flex justify-content-end align-items-center">
                    <small class="text-muted"
                        >{{
                            __('users.overview.results.count', {
                                users_count: `${users.data.length}/${users.meta.total}`,
                            })
                        }}
                    </small>
                </div>
                <sticky-table-header>
                    <CDataTable
                        :border="true"
                        :clickableRows="true"
                        :fields="fields"
                        :items="users.data"
                        :noItemsView="noItemsView"
                        :outlined="true"
                        :sorter="{ external: true, resetable: false }"
                        :sorter-value="sortOrder"
                        :striped="true"
                        hover
                        @update:sorter-value="updateSorting"
                        @row-clicked="showDetails"
                    >
                        <template #sorting-icon="{ classes, state }">
                            <sort-icon :class="classes" :state="state" />
                        </template>
                        <template #name="{ item }">
                            <td class="align-middle">
                                <div>
                                    {{ item.name }}
                                </div>
                                <small
                                    v-if="item.lastLoginAt !== undefined"
                                    class="text-muted"
                                    >{{ __('users.overview.last_login') }}
                                    <span
                                        v-if="!!item.lastLoginAt"
                                        class="text-nowrap"
                                        >{{
                                            item.lastLoginAt
                                                | dateTimeMedium(currentLocale)
                                        }}</span
                                    >
                                    <span v-else>{{
                                        __('general.not_available')
                                    }}</span>
                                </small>
                            </td>
                        </template>
                        <template #email="{ item }">
                            <td class="align-middle">
                                <email-link :email="item.email" />
                            </td>
                        </template>

                        <template #role="{ item }">
                            <td class="align-middle text-center">
                                <RoleBadge
                                    v-for="role in item.roles"
                                    :key="role.id"
                                    :role="role"
                                />
                            </td>
                        </template>

                        <template #status="{ item }">
                            <td class="align-middle text-center">
                                <CBadge
                                    :color="getStatusBadge(item.status)"
                                    class="status-badge"
                                >
                                    {{ item.status.label.toUpperCase() }}
                                </CBadge>
                            </td>
                        </template>

                        <template #actions="{ item }">
                            <td class="align-middle">
                                <div class="d-flex justify-content-end">
                                    <jet-button
                                        v-if="hasAnyPermission('user.update')"
                                        v-c-tooltip="{
                                            content: __(
                                                'users.overview.tooltip.edit_user'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="primary"
                                        @click.stop="editUser(item)"
                                    >
                                        <CIcon name="cil-pencil" />
                                    </jet-button>
                                    <jet-button
                                        v-if="
                                            hasAnyPermission('user.create') &&
                                            item.status.value === 'invited'
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'users.overview.tooltip.resend_invitation'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="primary"
                                        @click.stop="inviteUser(item)"
                                    >
                                        <CIcon name="cil-paper-plane" />
                                    </jet-button>
                                    <jet-button
                                        v-if="
                                            hasAnyPermission('user.revoke') &&
                                            item.status.value !== 'blocked'
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'users.overview.tooltip.revoke_access'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="primary"
                                        @click.stop="revokeAccess(item)"
                                    >
                                        <CIcon name="cil-lock-locked" />
                                    </jet-button>
                                    <jet-button
                                        v-if="
                                            hasAnyPermission('user.revoke') &&
                                            item.status.value === 'blocked'
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'users.overview.tooltip.restore_access'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="primary"
                                        @click.stop="restoreAccess(item)"
                                    >
                                        <CIcon name="cil-lock-unlocked" />
                                    </jet-button>
                                    <jet-button
                                        v-if="
                                            hasAnyPermission(
                                                'user.impersonate'
                                            ) &&
                                            !isCurrentUser(item) &&
                                            !isSuperAdmin(item)
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'users.overview.tooltip.impersonate'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        :class="{
                                            disabled:
                                                item.status.value === 'blocked',
                                        }"
                                        class="mx-1 btn-icon"
                                        color="warning"
                                        @click.stop="impersonate(item)"
                                    >
                                        <CIcon name="cil-user" />
                                    </jet-button>
                                    <jet-button
                                        v-if="hasAnyPermission('user.delete')"
                                        v-c-tooltip="{
                                            content: __(
                                                'users.overview.tooltip.delete_user'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="danger"
                                        @click.stop="deleteUser(item)"
                                    >
                                        <CIcon name="cil-trash" />
                                    </jet-button>
                                </div>
                            </td>
                        </template>
                    </CDataTable>
                </sticky-table-header>
            </CCardBody>
            <CCardFooter>
                <Pagination :paginator="users" />
            </CCardFooter>
        </CCard>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSectionTitle from '@/Jetstream/SectionTitle.vue';
import Pagination from '@/Components/Pagination.vue';
import RoleBadge from '@/Components/RoleBadge.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import SortIcon from '@/Pages/Assets/SortIcon.vue';
import EmailLink from '@/Components/EmailLink.vue';

export default {
    components: {
        EmailLink,
        SortIcon,
        StickyTableHeader,
        AppLayout,
        JetButton,
        JetSectionTitle,
        Pagination,
        RoleBadge,
        CustomCardHeader,
    },

    data() {
        return {
            sortOrder: {},
            fields: [
                {
                    key: 'name',
                    label: this.__('users.overview.cell.name'),
                },
                { key: 'email', label: this.__('users.overview.cell.email') },
                {
                    key: 'role',
                    label: this.__('users.overview.cell.role'),
                },
                {
                    key: 'status',
                    label: this.__('users.overview.cell.status'),
                    sorter: false,
                },
                {
                    key: 'actions',
                    label: '',
                    sorter: false,
                    filter: false,
                },
            ],
            noItemsView: {
                noResults: this.__('users.overview.filters.empty'),
                noItems: this.__('users.overview.results.empty'),
            },
        };
    },

    computed: {
        users() {
            return this.$page.props.users;
        },
    },

    mounted() {
        const primarySortOption = this.$page.props.sortOrder[0];
        this.sortOrder = {
            column: primarySortOption.column,
            asc: primarySortOption.direction === 'asc',
        };

        this.preventParallelRequests();
    },

    methods: {
        showDetails(user) {
            this.$inertia.visit(this.route('users.show', user.id), {
                preserveState: true,
            });
        },
        editUser(user) {
            this.$inertia.visit(this.route('users.edit', user.id), {
                preserveState: true,
            });
        },
        getMailLink(email) {
            return 'mailto:' + email;
        },
        getRoleBadge(role) {
            switch (role.name) {
                case 'super-admin':
                    return 'warning';
                case 'admin':
                    return 'primary';
                default:
                    return 'secondary';
            }
        },
        getStatusBadge(status) {
            switch (status.value) {
                case 'active':
                    return 'success';
                case 'invited':
                    return 'info';
                default:
                    return 'danger';
            }
        },
        revokeAccess(user) {
            this.$inertia.post(
                this.route('users.revoke_access', user.id),
                {},
                { preserveState: true }
            );
        },
        restoreAccess(user) {
            this.$inertia.get(
                this.route('users.restore_access', user.id),
                {},
                {
                    preserveState: true,
                }
            );
        },
        inviteUser(user) {
            this.$inertia.post(
                this.route('users.invite', user.id),
                {},
                {
                    preserveState: true,
                }
            );
        },
        deleteUser(user) {
            this.$inertia.delete(this.route('users.destroy', user.id), {
                preserveState: true,
            });
        },
        impersonate(user) {
            this.$inertia.get(
                this.route('users.impersonate', user.id),
                {},
                {
                    preserveState: true,
                }
            );
        },
        isCurrentUser(user) {
            return user.id === this.user.id;
        },
        isSuperAdmin(user) {
            return user.roles.map((el) => el.name).includes('super-admin');
        },
        updateSorting(event) {
            let sortOrder = event.asc ? 'asc' : 'desc';
            this.$inertia.visit(
                this.route(this.route().current(), {
                    sort: `${event.column}.${sortOrder}`,
                }),
                {
                    preserveState: true,
                }
            );
        },
    },
};
</script>
