<template>
    <app-layout>
        <h2>
            {{ __('settings.information.title') }}
        </h2>
        <div class="row">
            <div class="col-md-6">
                <update-tenant-logo-form />
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import UpdateTenantLogoForm from '@/Pages/Settings/UpdateTenantLogoForm.vue';

export default {
    components: {
        AppLayout,
        UpdateTenantLogoForm,
    },
};
</script>
