<template>
    <div>
        <h3 class="h5 mb-3">{{ __('dashboard.asset_management.title') }}</h3>
        <div class="db-card">
            <c-row>
                <c-col md="4">
                    <DashboardAssetTotal :total="$page.props.totalAssets" />
                </c-col>
                <c-col md="8">
                    <AssetTypeDistribution
                        :assets-by-type="$page.props.assetsByType.data"
                        @selected="$emit('selected', $event)"
                    />
                </c-col>
            </c-row>
            <div class="divider border-top my-3" />
            <c-row>
                <c-col>
                    <AssetStatus
                        :active-filters="activeFilters"
                        :operable-assets="$page.props.operableAssetsCount"
                        :inoperable-assets="$page.props.inoperableAssetsCount"
                        :is-processing="isProcessing"
                        @filter="$emit('filter', $event)"
                    />
                </c-col>
            </c-row>
            <c-row class="mt-4 mt-sm-2">
                <c-col>
                    <asset-status-details />
                </c-col>
            </c-row>
        </div>
    </div>
</template>
<script>
import DashboardAssetTotal from '@/Pages/Dashboard/Components/AssetTotal.vue';
import AssetTypeDistribution from '@/Pages/Dashboard/Components/AssetTypeDistribution.vue';
import AssetStatus from '@/Pages/Dashboard/Components/AssetStatus/Main.vue';
import AssetStatusDetails from '@/Pages/Dashboard/Components/AssetStatus/Details.vue';

export default {
    name: 'AssetManagement',
    components: {
        AssetStatusDetails,
        AssetStatus,
        AssetTypeDistribution,
        DashboardAssetTotal,
    },
    props: {
        isProcessing: {
            type: Boolean,
            default: false,
        },
        activeFilters: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
