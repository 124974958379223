<template>
    <div
        @click.stop="toggleValue()"
        class="btn-toggle align-items-center border-primary rounded-pill position-relative flex-shrink-0"
        style="max-width: 100%"
    >
        <div
            v-for="option in options"
            :key="option.value"
            style="z-index: 1"
            class="btn-toogle-option text-truncate"
            :class="{ selected: option.value === value }"
        >
            {{ option.label }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'toggle-button',
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        value: {},
    },
    computed: {
        selected() {
            return this.options.find((option) => option.value === this.value);
        },
    },
    methods: {
        toggleValue() {
            if (this.value === this.options[0].value) {
                this.$emit('input', this.options[1].value);
            } else {
                this.$emit('input', this.options[0].value);
            }
        },
    },
};
</script>
