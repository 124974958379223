<template>
    <div>
        <CBadge :class="{'clickable-badge': issue.url}" :color="color" @click.stop="openIssue">{{ label | uppercase }}</CBadge>
    </div>
</template>
<script>
export default {
    props: ['issue'],
    computed: {
        color() {
            switch (this.issue.priority) {
                case 'high':
                    return 'danger';
                case 'medium':
                    return 'warning';
                case 'low':
                    return 'info';
                default:
                    return 'secondary';
            }
        },
        label() {
            return this.__('issue.' + this.issue.type);
        },
    },
    methods: {
        openIssue(event) {
            event.preventDefault()

            if (this.issue.url) {
                this.$inertia.visit(this.issue.url);
            }
        },
    },
};
</script>
<style scoped>
.clickable-badge {
    cursor: pointer;
}
</style>
