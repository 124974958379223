import './bootstrap';
import './utils';

// Import modules...
import Vue from 'vue';
import { createInertiaApp, Link } from '@inertiajs/vue2';
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from '../icons/icons.js';
import store from './store.js';
import PortalVue from 'portal-vue';
import nl2brComponent from './Components/nl2br';
import hasAnyPermission from './mixins/hasAnyPermission';
import i18n from './mixins/i18n';
import removeNullValues from './mixins/removeNullValues';
import apiDate from './mixins/apiDate';
import locale from './mixins/locale';
import user from './mixins/user';
import isFormSubmittable from './mixins/isFormSubmittable';
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes';
import dateFilter from './filters/date';
import fullDateFilter from './filters/fullDate';
import durationFilter from './filters/duration';
import shortDateFilter from './filters/shortDate';
import dateTimeMediumFilter from './filters/dateTimeMedium';
import relativeDateFilter from './filters/relativeDate';
import timeFilter from './filters/time';
import '../scss/style.scss';
import preventParallelRequests from './mixins/preventParallelRequests';
import hasFeature from './mixins/hasFeature';
import linkify from '@/filters/linkify';
import nl2br from '@/filters/nl2br';
import '@formatjs/intl-durationformat/polyfill';
import setupErrorReporting from '@/setupErrorReporting';
import CoreuiVueCharts from '@coreui/vue-chartjs';

const el = document.getElementById('app');

Vue.component('Link', Link);
Vue.component('nl2br', nl2brComponent);

Vue.use(CoreuiVue);
Vue.use(PortalVue);
Vue.use(vueFilterPrettyBytes);
Vue.use(CoreuiVueCharts);

// Mixins

Vue.mixin({ methods: { route } });
Vue.mixin(hasAnyPermission);
Vue.mixin(hasFeature);
Vue.mixin(i18n);
Vue.mixin(removeNullValues);
Vue.mixin(apiDate);
Vue.mixin(locale);
Vue.mixin(user);
Vue.mixin(isFormSubmittable);
Vue.mixin(preventParallelRequests);

// Filter

Vue.filter('date', dateFilter);
Vue.filter('shortDate', shortDateFilter);
Vue.filter('fullDate', fullDateFilter);
Vue.filter('duration', durationFilter);
Vue.filter('dateTimeMedium', dateTimeMediumFilter);
Vue.filter('relativeDate', relativeDateFilter);
Vue.filter('time', timeFilter);
Vue.filter('linkify', linkify);
Vue.filter('nl2br', nl2br);
Vue.filter('uppercase', function (value) {
    return value.toUpperCase();
});

// Error Reporting

setupErrorReporting();

// App

createInertiaApp({
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
        return pages[`./Pages/${name}.vue`];
    },
    progress: {
        color: '#4B5563',
    },
    setup({ el, App, props, plugin }) {
        Vue.use(plugin);

        new Vue({
            icons,
            store,
            render: (h) => h(App, props),
        }).$mount(el);
    },
});
