<template>
    <div :class="{ 'wo-card-active': active }" class="wo-card">
        <header class="wo-card-header">
            <div class="d-flex align-items-center justify-content-between">
                <work-order-type-icon
                    :text="workOrder.code"
                    :work-order="workOrder"
                    style="min-height: 30px"
                />
                <div @click.stop="">
                    <c-dropdown
                        v-if="
                            canBeEdited && hasAnyPermission('workorder.update')
                        "
                        :customPopperOptions="{
                            strategy: 'fixed',
                            placement: 'left-start',
                        }"
                        @click.stop=""
                    >
                        <template #toggler>
                            <button
                                :disabled="isLoading"
                                class="btn-text btn-icon bg-transparent"
                            >
                                <more v-if="!isLoading"/>
                                <c-spinner v-else color="primary" size="sm"/>
                            </button>
                        </template>

                        <c-dropdown-item
                            v-if="isToDo"
                            @click="
                                $emit('move-to', {
                                    workOrder,
                                    status: 'in_progress',
                                })
                            "
                        >{{ __('workorder.action.move_to.in_progress') }}
                        </c-dropdown-item>
                        <c-dropdown-item
                            v-if="isToDo || isInProgress"
                            @click="
                                $emit('move-to', {
                                    workOrder,
                                    status: 'resolved',
                                })
                            "
                        >{{ __('workorder.action.move_to.resolved') }}
                        </c-dropdown-item>
                        <c-dropdown-item
                            v-if="isInProgress"
                            @click="
                                $emit('move-to', {
                                    workOrder,
                                    status: 'to_do',
                                })
                            "
                        >{{ __('workorder.action.move_to.to_do') }}
                        </c-dropdown-item>
                        <c-dropdown-item
                            v-if="isToDo || isInProgress"
                            @click="
                                $emit('move-to', {
                                    workOrder,
                                    status: 'closed',
                                })
                            "
                        >{{ __('workorder.action.move_to.closed') }}
                        </c-dropdown-item>
                        <div
                            v-if="
                                canBeDeleted &&
                                hasAnyPermission('workorder.delete')
                            "
                        >
                            <c-dropdown-divider/>
                            <c-dropdown-item
                                @click="$emit('delete', workOrder)"
                            >
                                <div
                                    class="d-flex w-100 align-items-center justify-content-between"
                                >
                                    {{ __('general.action.delete') }}
                                    <c-icon name="cil-trash"/>
                                </div>
                            </c-dropdown-item>
                        </div>
                    </c-dropdown>
                </div>
            </div>
        </header>
        <section class="wo-card-body">
            <h3 class="wo-card-title text-max-2-lines mt-2 mb-3">
                {{ workOrder.title }}
            </h3>
            <dl class="mb-0">
                <div v-if="!isDone" class="row">
                    <dt class="col-5 font-weight-bold text-truncate">
                        {{ __('workorder.card.label.due_date') }}
                    </dt>
                    <dd class="col-7 mb-1 text-truncate font-weight-bold">
                        <span
                            v-if="workOrder.dueDate"
                            :class="{
                                'text-warning': isUpcoming,
                                'text-danger': isOverdue,
                            }"
                        >{{
                                workOrder.dueDate | fullDate(currentLocale)
                            }}</span
                        >
                        <span v-else>-</span>
                    </dd>
                </div>
                <div v-if="isResolved" class="row">
                    <dt class="col-5 font-weight-bold text-truncate">
                        {{ __('workorder.card.label.resolved_date') }}
                    </dt>
                    <dd class="col-7 mb-1 text-truncate font-weight-bold">
                        <span
                            v-if="workOrder.statusChangedAt"
                            :class="{
                                'text-warning': isUpcoming,
                                'text-danger': isOverdue,
                            }"
                        >{{
                                workOrder.statusChangedAt
                                    | fullDate(currentLocale)
                            }}</span
                        >
                        <span v-else>-</span>
                    </dd>
                </div>
                <div v-if="isClosed" class="row">
                    <dt class="col-5 font-weight-bold text-truncate">
                        {{ __('workorder.card.label.closed_date') }}
                    </dt>
                    <dd class="col-7 mb-1 text-truncate font-weight-bold">
                        <span
                            v-if="workOrder.statusChangedAt"
                            :class="{
                                'text-warning': isUpcoming,
                                'text-danger': isOverdue,
                            }"
                        >{{
                                workOrder.statusChangedAt
                                    | fullDate(currentLocale)
                            }}</span
                        >
                        <span v-else>-</span>
                    </dd>
                </div>
                <div class="row">
                    <dt class="col-5 text-truncate">
                        {{ __('workorder.card.label.assignee') }}
                    </dt>
                    <dd class="col-7 mb-1 text-truncate">
                        {{
                            workOrder.assignee?.name ||
                            __('workorder.assignee.unassigned')
                        }}
                    </dd>
                </div>
                <div class="row">
                    <dt class="col-5 text-truncate">
                        {{ __('workorder.card.label.priority') }}
                    </dt>
                    <dd class="col-7 mb-1 text-truncate">
                        <work-order-priority :priority="workOrder.priority"/>
                    </dd>
                </div>
            </dl>
        </section>
    </div>
</template>
<script>
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';
import WorkOrderTypeIcon from '@/Pages/WorkOrders/Modals/WorkOrderTypeIcon.vue';
import More from '../../../icons/More.vue';
import {DateTime} from 'luxon';

export default {
    name: 'word-order-card',
    components: {WorkOrderTypeIcon, WorkOrderPriority, More},
    emits: ['move-to', 'click', 'delete'],
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        workOrder: {},
    },
    computed: {
        isClosed() {
            return this.workOrder.status.value === 'closed';
        },
        isToDo() {
            return this.workOrder.status.value === 'to_do';
        },
        isInProgress() {
            return this.workOrder.status.value === 'in_progress';
        },
        isResolved() {
            return this.workOrder.status.value === 'resolved';
        },
        isDone() {
            return this.isResolved || this.isClosed;
        },
        canBeEdited() {
            return !this.isDone && this.workOrder.isEditable;
        },
        canBeDeleted() {
            return !this.isDone && this.workOrder.isEditable;
        },
        hoursToDueDate() {
            if (!this.workOrder.dueDate) {
                return null;
            }

            let dateTime = DateTime.fromISO(this.workOrder.dueDate);
            let diff = dateTime.diffNow('hour').hours;
            return Math.floor(diff);
        },
        isUpcoming() {
            if (this.isDone || this.hoursToDueDate === null) {
                return false;
            }
            return this.hoursToDueDate <= 48;
        },
        isOverdue() {
            if (this.isDone || this.hoursToDueDate === null) {
                return false;
            }
            return this.hoursToDueDate <= 24;
        },
    },
    methods: {
        handleClick(event) {
            event.stopImmediatePropagation();
            event.preventDefault();
        },
    },
};
</script>
