<template>
    <div>
        <CCard>
            <CustomCardHeader
                :hasPermission="hasActiveFilters"
                :href="route('assets.tests.index', asset)"
                :label="__('general.action.show_all')"
                :title="__('test_records.overview.header')"
            />
            <CCardBody>
                <CRow v-if="isTestOverdue">
                    <CCol>
                        <CAlert
                            class="pl-2 d-flex align-items-start"
                            color="danger"
                        >
                            <div>
                                <span class="badge badge-pill badge-danger mx-1"
                                >!</span
                                >
                            </div>
                            <div>
                                <span>{{
                                        __('test.overview.alert.overdue_test')
                                    }}</span
                                >&nbsp;
                                <span v-if="readableCadence">{{
                                        __(
                                            'test.overview.alert.overdue_test.cadence',
                                            {
                                                cadence: readableCadence,
                                            }
                                        )
                                    }}</span>
                            </div>
                        </CAlert>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <sticky-table-header>
                            <CDataTable
                                v-if="records"
                                :border="true"
                                :clickableRows="true"
                                :fields="fields"
                                :items="records.data"
                                :noItemsView="noItemsView"
                                :outlined="true"
                                :striped="true"
                                hover
                                @row-clicked="toggleReport"
                            >
                                <template #sorting-icon="{ classes, state }">
                                    <sort-icon :class="classes" :state="state"/>
                                </template>
                                <template #date="{ item }">
                                    <td class="align-top">
                                        <div class="text-muted">
                                            <div class="text-nowrap">
                                                {{
                                                    item.date
                                                        | fullDate(
                                                        currentLocale
                                                        )
                                                }}
                                            </div>
                                            <small>
                                                <CIcon name="cil-clock"/>
                                                {{
                                                    item.date
                                                        | time(currentLocale)
                                                }}</small
                                            >
                                        </div>
                                    </td>
                                </template>

                                <template #user="{ item }">
                                    <td class="align-top">
                                        <user-information
                                            v-if="item.user"
                                            :info="item.user"
                                        />
                                        <span v-else>-</span>
                                    </td>
                                </template>

                                <template #successful="{ item }">
                                    <td class="align-top text-center">
                                        <CIcon
                                            v-if="
                                                item.resolution.value ===
                                                'success'
                                            "
                                            :key="item.id"
                                            v-c-tooltip="{
                                                content: __(
                                                    'test_records.overview.tooltip.calibration_passed'
                                                ),
                                                popperOptions: {
                                                    positionFixed: true,
                                                },
                                            }"
                                            class="text-success"
                                            name="cil-check-alt"
                                        />
                                        <CIcon
                                            v-else
                                            :key="item.id"
                                            v-c-tooltip="{
                                                content: __(
                                                    'test_records.overview.tooltip.calibration_failed'
                                                ),
                                                popperOptions: {
                                                    positionFixed: true,
                                                },
                                            }"
                                            class="text-danger"
                                            name="cil-warning"
                                        />
                                    </td>
                                </template>

                                <template #report="{ item }">
                                    <td class="align-top">
                                        <div
                                            class="text-break"
                                            :class="{
                                                'text-max-2-lines':
                                                    !showFullDescription(item),
                                            }"
                                            style="
                                                -webkit-line-clamp: 3;
                                                min-width: 280px;
                                            "
                                        >
                                            {{ item.description }}
                                        </div>
                                    </td>
                                </template>

                                <template #attachments="{ item }">
                                    <td class="align-top">
                                        <FileList
                                            :attachments="item.attachments"
                                            :can-read="
                                                hasAnyPermission([
                                                    'test.attachment.index',
                                                ])
                                            "
                                            :inline="true"
                                            :readonly="true"
                                        />
                                    </td>
                                </template>

                                <template #actions="{ item }">
                                    <td class="align-top">
                                        <div class="d-flex justify-content-end">
                                            <jet-button
                                                v-if="
                                                    hasAnyPermission(
                                                        'test.delete'
                                                    )
                                                "
                                                @click.stop="
                                                    recordToDelete = item
                                                "
                                                color="danger"
                                                class="mx-1 btn-icon"
                                                v-c-tooltip="{
                                                    content: __(
                                                        'general.action.delete'
                                                    ),
                                                    popperOptions: {
                                                        positionFixed: true,
                                                    },
                                                }"
                                                :isProcessing="
                                                    item.id ===
                                                        recordToDelete?.id &&
                                                    isDeletingTestRecord
                                                "
                                            >
                                                <span
                                                ><c-icon
                                                    name="cil-trash"
                                                    size="sm"
                                                /></span>
                                            </jet-button>
                                        </div>
                                    </td>
                                </template>
                            </CDataTable>
                        </sticky-table-header>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        <jet-confirmation-modal
            :show="showConfirmDeletionDialog"
            @close="recordToDelete = null"
            type="danger"
        >
            <template #title>
                {{
                    __(
                        'test_records.overview.confirmation.delete_calibration.title'
                    )
                }}
            </template>

            <template #content>
                {{
                    __(
                        'test_records.overview.confirmation.delete_calibration.content'
                    )
                }}
            </template>

            <template #footer>
                <slot name="footer">
                    <jet-button
                        color="danger"
                        @click.stop="deleteTestRecord(recordToDelete)"
                        :isProcessing="isDeletingTestRecord"
                    >
                        {{ __('general.action.delete') }}
                    </jet-button>
                </slot>
            </template>
        </jet-confirmation-modal>
    </div>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import fullDate from '@/filters/fullDate';
import ConfirmedDelete from '@/Components/ConfirmedDelete.vue';
import FileList from '@/Components/FileList.vue';
import UserInformation from '@/Components/UserInformation.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import SortIcon from "@/Pages/Assets/SortIcon.vue";

export default {
    components: {
        SortIcon,
        JetButton,
        JetConfirmationModal,
        StickyTableHeader,
        CustomCardHeader,
        ConfirmedDelete,
        FileList,
        UserInformation,
    },

    data() {
        return {
            openReports: [],
            basicFields: [
                {
                    key: 'date',
                    label: this.__('test_records.overview.cell.date'),
                },
                {
                    key: 'user',
                    label: this.__('test_records.overview.cell.user'),
                },
                {
                    key: 'successful',
                    label: this.__('test_records.overview.cell.successful'),
                },
                {
                    key: 'report',
                    label: this.__('test_records.overview.cell.report'),
                },
                {
                    key: 'attachments',
                    label: this.__('test_records.overview.cell.attachments'),
                },
            ],
            noItemsView: {
                noItems: this.__('test_records.overview.results.empty'),
            },
            selectedActivity: null,
            showActivityDetails: false,
            transformers: {
                scheduled_for: (value) => {
                    return fullDate(value, this.currentLocale);
                },
            },
            recordToDelete: null,
            isDeletingTestRecord: false,
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        fields() {
            if (this.hasAnyPermission('test.delete')) {
                return this.basicFields.concat([
                    {
                        key: 'actions',
                        label: '',
                    },
                ]);
            }

            return this.basicFields;
        },
        records() {
            return this.$page.props.tests;
        },
        hasActiveFilters() {
            return this.$page.props.is_filter_active;
        },
        isTestOverdue() {
            return this.$page.props.isTestOverdue;
        },
        cadenceConfig() {
            return this.$page.props.cadenceConfig;
        },
        readableCadence() {
            return !!this.cadenceConfig
                ? `${this.cadenceConfig.value} ${this.cadenceConfig.unit}`
                : null;
        },
        showConfirmDeletionDialog() {
            return !!this.recordToDelete;
        },
    },

    methods: {
        deleteTestRecord(item) {
            if (!item) {
                return;
            }

            this.isDeletingTestRecord = true;

            this.$inertia.delete(
                this.route('assets.tests.destroy', {
                    asset: this.asset,
                    test: item,
                }),
                {
                    preserveState: true,
                    onSuccess: () => {
                        this.recordToDelete = null;
                    },
                    onFinish: () => {
                        this.isDeletingTestRecord = false;
                    },
                }
            );
        },
        showFullDescription(item) {
            return this.openReports.includes(item.id);
        },
        toggleReport(item) {
            if (this.openReports.includes(item.id)) {
                this.openReports = this.openReports.filter(
                    (id) => id !== item.id
                );
            } else {
                this.openReports.push(item.id);
            }
        },
    },
};
</script>
