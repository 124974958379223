<template>
    <div>
        <dl>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.details.workorder.type.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <span>{{ workOrder?.type?.label ?? 'None' }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.details.workorder.status.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-select
                        v-model="form.status"
                        :disabled="statuses.length < 2"
                        :horizontal="false"
                        :invalid-feedback="form.errors.status"
                        :options="statuses"
                        :readonly="!isEditable || statuses.length < 2"
                        :required="true"
                        add-toggler-classes="dropdown-inline"
                        class="mb-0 d-flex h-auto"
                    >
                        <template #toggler-content="item">
                            <div
                                v-if="item.disabled"
                                style="
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    z-index: 1;
                                "
                                @click.stop=""
                            />
                            <div v-html="item.props?.label" />
                        </template>
                        <template #readonly="item">
                            {{ workOrder?.status?.label || __('general.none') }}
                        </template>
                    </jet-select>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.details.workorder.priority.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-select
                        v-model="form.priority"
                        :disabled="priorities.length === 0"
                        :horizontal="false"
                        :invalid-feedback="form.errors.priority"
                        :options="priorities"
                        :readonly="!isEditable || priorities.length === 0"
                        :required="true"
                        add-toggler-classes="dropdown-inline"
                        class="mb-0 d-flex h-auto"
                    >
                        <template #toggler-content="item">
                            <div
                                v-if="item.disabled"
                                style="
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    z-index: 1;
                                "
                                @click.stop=""
                            />
                            <work-order-priority
                                :priority="{
                                    value: item.props?.value,
                                    label: item.props.label,
                                }"
                            />
                        </template>
                        <template #readonly>
                            <work-order-priority
                                v-if="workOrder"
                                :priority="{
                                    value: workOrder?.priority.value,
                                    label: workOrder?.priority.label,
                                }"
                            />
                            <span v-else>{{ __('general.none') }}</span>
                        </template>
                    </jet-select>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.details.workorder.assignee.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-select
                        v-model="form.assignee"
                        :disabled="assignees.length === 0"
                        :horizontal="false"
                        :invalid-feedback="form.errors.assignee"
                        :options="assignees"
                        :placeholder="__('general.form.select.placeholder')"
                        :readonly="!isEditable || assignees.length === 0"
                        add-toggler-classes="dropdown-inline"
                        class="mb-0 d-flex h-auto"
                        :resettable="false"
                        :enable-search="true"
                    >
                        <template #toggler-content="item">
                            <div
                                v-if="item.disabled"
                                style="
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    z-index: 1;
                                "
                                @click.stop=""
                            />
                            <div
                                v-html="item.props?.label ?? item.placeholder"
                            />
                        </template>
                        <template #readonly>
                            {{
                                workOrder?.assignee?.name || __('general.none')
                            }}
                        </template>
                    </jet-select>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.details.workorder.reporter.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <span v-if="workOrder?.reportedBy">{{
                        workOrder?.reportedBy.name
                    }}</span>
                    <span v-else>{{ __('general.none') }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.details.workorder.reported_at.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <span v-if="workOrder?.reportedAt">{{
                        workOrder?.reportedAt | dateTimeMedium(currentLocale)
                    }}</span>
                    <span v-else>{{ __('general.none') }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.details.workorder.start_date.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-inline-datepicker
                        v-model="form.startDate"
                        :deletable="true"
                        :horizontal="false"
                        :invalid-feedback="form?.errors?.startDate"
                        :placeholder="__('general.none')"
                        :readonly="!isEditable"
                        class="mb-0 d-flex"
                    />
                </dd>
            </div>
            <div class="row">
                <dt
                    class="col-3 font-weight-normal text-truncate"
                    :class="{ required: isDueDateRequired && isEditable }"
                >
                    {{ __('workorder.details.workorder.due_date.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-inline-datepicker
                        v-model="form.dueDate"
                        :deletable="true"
                        :horizontal="false"
                        :invalid-feedback="form?.errors?.dueDate"
                        :min-date="form.startDate"
                        :placeholder="__('general.none')"
                        :readonly="!isEditable"
                        :required="isDueDateRequired"
                        class="mb-0 d-flex"
                    />
                </dd>
            </div>
            <div v-if="showResolvedDate || showClosedDate" class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    <span v-if="showResolvedDate">{{
                        __('workorder.details.workorder.resolved_at.label')
                    }}</span>
                    <span v-if="showClosedDate">{{
                        __('workorder.details.workorder.closed_at.label')
                    }}</span>
                </dt>
                <dd class="col-9 text-muted">
                    {{
                        workOrder.statusChangedAt
                            | dateTimeMedium(currentLocale)
                    }}
                </dd>
            </div>
        </dl>
    </div>
</template>
<script>
import JetInlineDatepicker from '@/Jetstream/InlineDatepicker.vue';
import JetSelect from '@/Jetstream/Select.vue';
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';

export default {
    name: 'work-order-info',
    components: {
        WorkOrderPriority,
        JetSelect,
        JetInlineDatepicker,
    },
    props: {
        form: {},
        workOrder: {},
        statuses: {},
        priorities: {},
        assignees: {},
        isEditable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        showResolvedDate() {
            return this.workOrder?.status.value === 'resolved';
        },
        showClosedDate() {
            return this.workOrder?.status.value === 'closed';
        },
        isDueDateRequired() {
            return this.workOrder?.type.value === 'preventative_maintenance';
        },
    },
};
</script>
