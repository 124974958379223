import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

/**
 * Returns a value from localStorage.
 * If the value cannot be found,
 * the fallback value will be returned.
 *
 * @param {String} key
 * @param {Any} fallback
 * @returns
 */
function getConfig(key, fallback) {
    if (localStorage && localStorage.getItem(key)) {
        const value = localStorage.getItem(key);

        if (value === 'true') {
            return true;
        }

        if (value === 'false') {
            return false;
        }

        return value;
    }

    return fallback;
}

/**
 * Saves a value in localStorage.
 * @param {String} key
 * @param {Any} value
 */
function setConfig(key, value) {
    if (localStorage) {
        localStorage.setItem(key, value);
    }
}

const state = {
    sidebarShow: getConfig('sidebarShow', 'responsive'),
    sidebarMinimize: getConfig('sidebarMinimize', false),
    asideShow: getConfig('asideShow', false),
    darkMode: getConfig('darkMode', false),
};

const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
        const value = sidebarOpened ? false : 'responsive';
        state.sidebarShow = value;
        setConfig('sidebarShow', value);
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
        const value = sidebarClosed ? true : 'responsive';
        state.sidebarShow = value;
        setConfig('sidebarMinimize', value);
    },
    set(state, [variable, value]) {
        state[variable] = value;
        setConfig(variable, value);
    },
    toggle(state, variable) {
        const value = !state[variable];
        state[variable] = value;
        setConfig(variable, value);
    },
};

export default new Vuex.Store({
    state,
    mutations,
});
