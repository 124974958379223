<template>
    <app-layout>
        <div>
            <CRow>
                <CCol>
                    <h4 class="mb-0">
                        {{ __('assets.activation.headline') }}
                    </h4>

                    <p>{{ __('assets.activation.description') }}</p>
                </CCol>
            </CRow>
            <filter-bar
                :is-processing="isProcessing"
                class="my-2"
                @change="updateFilter"
            />
            <div class="d-flex justify-content-end">
                <small class="text-muted"
                    >{{
                        __('assets.overview.results.count', {
                            assets_count: `${transformedAssets.length}/${totalAssets}`,
                        })
                    }}
                </small>
            </div>
            <sticky-table-header>
                <CDataTable
                    v-if="assets"
                    :border="true"
                    :clickableRows="true"
                    :fields="visibleFields"
                    :items="transformedAssets"
                    :noItemsView="noItemsView"
                    :outlined="true"
                    :sorter="{ external: true, resetable: false }"
                    :sorter-value="activeQueryParams.sort"
                    :striped="true"
                    hover
                    @row-clicked="showDetails"
                    @update:sorter-value="updateSorting($event)"
                >
                    <template #sorting-icon="{ classes, state }">
                        <sort-icon :class="classes" :state="state" />
                    </template>
                    <template #manufacturer="{ item }">
                        <td>
                            {{ item.manufacturer }}
                        </td>
                    </template>

                    <template #designation="{ item }">
                        <td>
                            <div>
                                {{ item.designation }}
                            </div>
                            <small class="text-nowrap text-dark"
                                >{{ __('assets.overview.cell.serial_number') }}:
                                {{ item.serialNumber }}</small
                            >
                        </td>
                    </template>

                    <template #airport="{ item }">
                        <td>
                            <span
                                v-c-tooltip="{
                                    content: item.airport_name,
                                    popperOptions: {
                                        positionFixed: true,
                                    },
                                }"
                            >
                                {{ item.airport }}
                            </span>
                        </td>
                    </template>

                    <template #location="{ item }">
                        <td>
                            {{ item.location }}
                        </td>
                    </template>

                    <template #actions="{ item }">
                        <td>
                            <jet-button @click.stop="openQrCodeScanner(item)"
                                >{{ __('assets.overview.scanner.title') }}
                            </jet-button>
                        </td>
                    </template>
                </CDataTable>
            </sticky-table-header>
        </div>
        <Pagination :paginator="assets" :query="activeQueryParams" />
        <petitec-id-scanner
            ref="petitecIdScanner"
            :pid="selectedAsset ? selectedAsset.petitec_identifier : null"
            :show="isPetitecIdScannerVisible"
            :submitButtonLabel="
                !!selectedAsset
                    ? __('general.action.save')
                    : __('general.action.go')
            "
            @close="closePetitecIdScanner"
            @error="onPetitecIdScannerError"
            @success="onPetitecIdScannerSuccess"
        />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import PetitecIdScanner from '@/Components/PetitecIdScanner.vue';
import FilterBar from '@/Components/FilterBar.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import BatchJobs from '@/Pages/Assets/BatchJobs/BatchJobs.vue';
import { debounce } from 'lodash';
import { removeEmptyProperties } from '@/utils';
import OnboardedAssetIssue from '@/Pages/Assets/OnboardedAssetIssue.vue';
import WorkOrderBadge from '@/Components/WorkorderBadge.vue';
import SortIcon from '@/Pages/Assets/SortIcon.vue';

export default {
    components: {
        SortIcon,
        OnboardedAssetIssue,
        WorkOrderBadge,
        BatchJobs,
        StickyTableHeader,
        AppLayout,
        JetButton,
        Pagination,
        PetitecIdScanner,
        IssueBadge,
        FilterBar,
        StatusBadge,
        StatusCheckDate,
    },

    data() {
        return {
            activeQueryParams: { column: 'status', asc: false },
            isProcessing: false,
            allFields: [
                {
                    key: 'airport',
                    label: this.__('assets.overview.cell.airport'),
                    _style: 'width: 90px',
                },
                {
                    key: 'location',
                    label: this.__('assets.overview.cell.location'),
                    _style: 'width: 15%;white-space: nowrap;',
                },
                {
                    key: 'type',
                    label: this.__('assets.overview.cell.type'),
                    _style: 'width: 90px',
                },
                {
                    key: 'designation',
                    label: this.__('assets.overview.cell.designation'),
                    _style: 'width: 20%',
                },
                {
                    key: 'manufacturer',
                    label: this.__('assets.overview.cell.manufacturer'),
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            visibleFields: [],
            noItemsView: {
                noResults: this.__('assets.overview.filters.empty'),
                noItems: this.__('assets.overview.results.empty'),
            },
            isSearching: false,
            isPetitecIdScannerVisible: false,
            selectedAsset: null,
        };
    },

    computed: {
        assets() {
            return this.$page.props.assets;
        },
        totalAssets() {
            return this.assets.meta.total;
        },
        hasCameraSupport() {
            return !!navigator.mediaDevices;
        },
        transformedAssets() {
            const notAvailable = this.__('general.not_available');

            return this.assets.data.map((asset) => ({
                id: asset.id,
                type: asset.type.value || notAvailable,
                designation: asset.designation
                    ? asset.designation.label
                    : notAvailable,
                manufacturer: asset.manufacturer
                    ? asset.manufacturer.name
                    : notAvailable,
                petitec_identifier: asset.petitec_identifier,
                serialNumber: asset.serialNumber || notAvailable,
                airport:
                    asset.location.physical_location.iata_code || notAvailable,

                airport_name:
                    asset.location.physical_location.name || notAvailable,
                location: this.getLocationName(asset.location),
            }));
        },
        scanner() {
            return this.$refs.petitecIdScanner;
        },
        defaultSorting() {
            return { column: 'type', asc: true };
        },
        activeQueryParamsWithoutSorting() {
            const params = Object.assign({}, this.activeQueryParams);
            delete params.sort;
            return params;
        },
    },

    methods: {
        getLocationName(location) {
            if (!location) {
                return this.__('general.not_available');
            }

            return [location.name]
                .reduce(
                    (unique, item) =>
                        unique.includes(item) ? unique : [...unique, item],
                    []
                )
                .filter((item) => item)
                .join(' - ');
        },
        showDetails(asset) {
            this.$inertia.visit(this.route('assets.show', asset.id));
        },
        openQrCodeScanner(item) {
            this.selectedAsset = item || null;
            this.isPetitecIdScannerVisible = true;
        },
        closePetitecIdScanner() {
            this.isPetitecIdScannerVisible = false;

            window.location.hash = '';

            setTimeout(() => {
                this.selectedAsset = null;
            }, 500);
        },
        onPetitecIdScannerSuccess(data) {
            const petitecId = data.uuid;

            if (this.selectedAsset) {
                this.assignPetitecId(petitecId);
            } else {
                this.searchAsset(petitecId);
            }
        },
        onPetitecIdScannerError() {},
        assignPetitecId(pid) {
            if (!this.selectedAsset) {
                return;
            }

            this.scanner.form.put(
                this.route('assets.assign_pid', this.selectedAsset.id),
                {
                    onSuccess: () => {
                        this.scanner.isValidQrCode = true;
                        setTimeout(() => {
                            this.closePetitecIdScanner();
                        }, 300);
                    },
                    onError: () => {
                        this.scanner.isValidQrCode = false;
                    },
                }
            );
        },
        reportScannerError() {
            this.scanner.isValidQrCode = false;
            this.scanner.form.errors.petitec_identifier = this.__(
                'assets.overview.scanner.error.asset_not_found'
            );
        },
        loadAssets() {
            const { sort } = this.activeQueryParams;

            let queryParams = Object.assign(
                {},
                removeEmptyProperties(this.activeQueryParams),
                {
                    sort: this.transformSortToQuery(sort),
                }
            );

            this.$inertia.get(
                this.route('assets.activation', queryParams),
                {},
                {
                    only: ['assets'],
                    preserveState: true,
                    preserveScroll: true,
                    onBefore: () => {
                        this.isProcessing = true;
                    },
                    onFinish: () => {
                        this.updateTable();
                        this.isProcessing = false;
                    },
                }
            );
        },
        updateTable() {
            this.visibleFields = this.allFields;
        },
        updateFilter: debounce(function (filters) {
            this.activeQueryParams = { ...filters, sort: this.defaultSorting };
            this.loadAssets();
        }, 300),
        updateSorting(event) {
            this.activeQueryParams.sort = event ?? undefined;
            this.loadAssets();
        },
        transformSortToQuery(sort) {
            if (!sort) {
                return undefined;
            }

            let sortDirection = sort.asc ? 'asc' : 'desc';
            return `${sort.column}.${sortDirection}`;
        },
        transformQueryToSort(sort) {
            if (sort) {
                const parts = sort.split('.');
                if (parts.length === 2) {
                    return {
                        column: parts[0],
                        asc: parts[1] === 'asc',
                    };
                }
            }

            return this.defaultSorting;
        },
        startPetitecScannerIfNecessary() {
            const parts = window.location.hash.split('#assign-petitec-id=');

            if (parts.length === 2) {
                this.openQrCodeScanner({ id: parts[1] });
            }
        },
    },
    mounted() {
        const { search, airports, types, sort } = this.route().params;

        this.activeQueryParams = {
            search,
            airports,
            types,
            sort: this.transformQueryToSort(sort),
        };

        this.updateTable();

        window.addEventListener(
            'hashchange',
            this.startPetitecScannerIfNecessary
        );
    },
    beforeDestroy() {
        window.removeEventListener(
            'hashchange',
            this.startPetitecScannerIfNecessary
        );
    },
};
</script>
