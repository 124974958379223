<template>
    <div
        class="dropdown-item px-2 d-flex justify-content-between align-items-start"
        style="gap: 15px"
        @click="markAsRead"
    >
        <div
            :is="notificationTypeWrapper"
            :notification="notification"
            :icon="icon"
        />
        <div>
            <div
                class="d-flex justify-content-end align-items-center"
                style="height: 22.5px"
            >
                <em
                    v-c-tooltip="{
                        content: __('notification_center.tooltip.mark_as_read'),
                        popperOptions: {
                            positionFixed: true,
                        },
                    }"
                    class="status-badge bg-primary"
                    @click.stop.prevent="markAsRead"
                ></em>
            </div>
            <small class="d-block text-muted">{{
                notification.created_at | relativeDate(currentLocale)
            }}</small>
        </div>
    </div>
</template>
<script>
import AssetOperabilityChanged from './NotificationTypes/AssetOperabilityChanged.vue';
import DefaultNotificationType from './NotificationTypes/DefaultNotificationType.vue';
import MaintenanceOverdue from './NotificationTypes/MaintenanceOverdue.vue';
import AssetStatusChanged from './NotificationTypes/AssetStatusChanged.vue';
import WorkOrderNotification from './NotificationTypes/WorkOrderNotification.vue';
import AirportUserSynched from '@/Components/NotificationCenter/NotificationTypes/AirportUserSynched.vue';

export default {
    name: 'Notification',
    props: {
        notification: {},
    },
    components: {
        'asset.operability.changed': AssetOperabilityChanged,
        'asset.status.changed': AssetStatusChanged,
        'location.access.denied': AirportUserSynched,
        'location.access.granted': AirportUserSynched,
        'maintenance.overdue': MaintenanceOverdue,
        'workorder.created': WorkOrderNotification,
        'workorder.resolved': WorkOrderNotification,
        'workorder.closed': WorkOrderNotification,
        'workorder.assigned': WorkOrderNotification,
        'workorder.overdue': WorkOrderNotification,
        defaultNotification: DefaultNotificationType,
    },
    computed: {
        notificationTypeWrapper() {
            return (
                this.getSpecificNotificationComponent(this.notification.type) ||
                'defaultNotification'
            );
        },
        icon() {
            switch (this.notification.type) {
                case 'asset.deleted':
                    return 'cil-trash';
                default:
                    return null;
            }
        },
    },
    methods: {
        markAsRead(notification) {
            if (!this.notification.id) {
                return;
            }

            this.$http
                .post(this.route('notifications.mark_as_read'), {
                    id: this.notification.id,
                })
                .finally(() => {
                    this.$emit('read', notification);
                });
        },
        getSpecificNotificationComponent(type) {
            return this.$options.components[this.notification.type];
        },
    },
};
</script>
<style scoped>
.status-badge {
    width: 10px;
    height: 10px;
    display: block;
    border: 1px solid #fff;
    border-radius: 50em;
    transition: transform ease-in-out 0.3s;
    z-index: 9999;
}

.status-badge:hover,
.status-badge:focus {
    transform: scale(1.5);
    background: #17ffc6 !important;
    cursor: pointer;
}
</style>
