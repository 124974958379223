<template>
    <li
        class="c-sidebar-nav-item c-sidebar-subnav-item d-flex justify-content-center"
    >
        <Link
            :href="href"
            class="c-sidebar-nav-link"
            :class="{ 'c-active': active }"
        >
            <span><slot /></span
        ></Link>
    </li>
</template>
<script>
export default {
    props: ['href', 'active'],
};
</script>
