<template>
    <portal v-if="render" to="body">
        <CModal
            :add-content-classes="addContentClasses"
            :centered="true"
            :closeOnBackdrop="closeable"
            :show="fadeIn"
            :size="maxWidth"
            class=""
        >
            <template #header-wrapper>
                <header
                    :class="[typeBasedClass, headerClass]"
                    class="modal-header"
                >
                    <h5 class="modal-title">
                        <slot name="title" />
                    </h5>
                </header>
            </template>
            <template>
                <slot name="content" />
            </template>
            <template #footer>
                <slot name="footer" />
            </template>
        </CModal>
    </portal>
</template>

<script>
export default {
    name: 'JetModal',

    emits: ['close'],

    props: {
        show: {
            default: false,
        },
        type: {
            default: '',
        },
        maxWidth: {
            default: '',
        },
        closeable: {
            default: true,
        },
        headerClass: String,
        addContentClasses: String | Array,
    },

    data() {
        return {
            render: false,
            fadeIn: false,
        };
    },

    computed: {
        typeBasedClass() {
            if (this.type === 'danger') {
                return 'bg-danger text-white border-0';
            }

            return '';
        },
    },

    watch: {
        show: function (newValue, oldValue) {
            document.body.style.overflow = newValue ? 'hidden' : null;

            if (newValue) {
                // Fade-in
                this.render = true;
                setTimeout(() => {
                    this.fadeIn = true;
                }, 150);
            } else {
                // Fade-out
                this.fadeIn = false;
                setTimeout(() => {
                    this.render = false;
                }, 150);
            }
        },
    },

    destroyed() {
        document.body.style.overflow = '';
    },
};
</script>
