<template>
    <app-layout>
        <h2>Buttons</h2>
        <c-row>
            <c-col>
                <jet-button>Test</jet-button>
            </c-col>
            <c-col>
                <jet-button :disabled="true">Test</jet-button>
            </c-col>
            <c-col>
                <jet-button :is-processing="true">Test</jet-button>
            </c-col>
            <c-col>
                <jet-button shape="pill" :is-processing="false"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button shape="pill" :is-processing="true"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button
                    shape="pill"
                    variant="outline"
                    :is-processing="false"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button shape="pill" variant="outline" :is-processing="true"
                    >Test
                </jet-button>
            </c-col>
        </c-row>
        <c-row class="mt-3">
            <c-col>
                <jet-button color="warning">Test</jet-button>
            </c-col>
            <c-col>
                <jet-button color="warning" :disabled="true">Test</jet-button>
            </c-col>
            <c-col>
                <jet-button color="warning" :is-processing="true"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button color="warning" shape="pill" :is-processing="false"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button color="warning" shape="pill" :is-processing="true"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button
                    color="warning"
                    shape="pill"
                    variant="outline"
                    :is-processing="false"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button
                    color="warning"
                    shape="pill"
                    variant="outline"
                    :is-processing="true"
                    >Test
                </jet-button>
            </c-col>
        </c-row>
        <c-row class="mt-3">
            <c-col>
                <jet-button color="danger">Test</jet-button>
            </c-col>
            <c-col>
                <jet-button color="danger" :disabled="true">Test</jet-button>
            </c-col>
            <c-col>
                <jet-button color="danger" :is-processing="true"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button color="danger" shape="pill" :is-processing="false"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button color="danger" shape="pill" :is-processing="true"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button
                    color="danger"
                    shape="pill"
                    variant="outline"
                    :is-processing="false"
                    >Test
                </jet-button>
            </c-col>
            <c-col>
                <jet-button
                    color="danger"
                    shape="pill"
                    variant="outline"
                    :is-processing="true"
                    >Test
                </jet-button>
            </c-col>
        </c-row>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    components: {
        JetButton,
        AppLayout,
    },
};
</script>
