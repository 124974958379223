<template>
    <auth-layout>
        <CContainer class="align-items-center min-vh-100">
            <CRow class="min-vh-100 justify-content-center align-items-center">
                <CCol md="6">
                    <CCard class="p-4 m-0">
                        <CCardBody>
                            <CForm @submit.prevent="submit">
                                <h1 v-if="isReset">
                                    {{ __('auth.reset_password.header') }}
                                </h1>
                                <h1 v-else>
                                    {{ __('auth.set_password.header') }}
                                </h1>
                                <p class="text-muted">
                                    {{ __('auth.reset_password.description') }}
                                </p>
                                <div>
                                    <jet-input
                                        id="email"
                                        :horizontal="false"
                                        v-model="email"
                                        :placeholder="__('auth.general.email')"
                                        autocomplete="username email"
                                        :readonly="true"
                                        :invalid-feedback="
                                            $page.props.errors.email
                                        "
                                    >
                                        <template #prepend>
                                            <c-icon name="cil-user" />
                                        </template>
                                    </jet-input>
                                </div>
                                <jet-input
                                    id="password"
                                    :horizontal="false"
                                    v-model="form.password"
                                    :placeholder="__('auth.general.password')"
                                    type="password"
                                    autocomplete="new-password"
                                    required
                                    autofocus
                                    :invalid-feedback="hasErrors ? ' ' : ''"
                                    :isValid="!hasErrors"
                                >
                                    <template #prepend>
                                        <CIcon name="cil-lock-locked" />
                                    </template>
                                </jet-input>
                                <jet-input
                                    id="confirm-password"
                                    :horizontal="false"
                                    v-model="form.password_confirmation"
                                    :placeholder="
                                        __('auth.general.password_confirmation')
                                    "
                                    type="password"
                                    autocomplete="new-password"
                                    required
                                    :isValid="!hasErrors ? null : false"
                                    :invalid-feedback="passwordError"
                                >
                                    <template #prepend>
                                        <c-icon name="cil-lock-locked" />
                                    </template>
                                </jet-input>
                                <div class="d-lg-flex mt-4" style="gap: 20px">
                                    <password-validation-errors
                                        class="mb-3"
                                        :errors="errors"
                                        :show-status="formSubmitted"
                                    />
                                    <div
                                        class="d-flex flew-column justify-content-start align-items-start"
                                    >
                                        <jet-button
                                            type="submit"
                                            color="primary"
                                            class="px-4 flex-grow-0"
                                            :is-processing="form.processing"
                                            >{{
                                                __(
                                                    isReset
                                                        ? 'auth.reset_password.action.submit'
                                                        : 'auth.set_password.action.submit'
                                                )
                                            }}
                                        </jet-button>
                                    </div>
                                </div>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import PasswordValidationErrors from '@/Components/PasswordValidationErrors.vue';
import JetInput from '@/Jetstream/Input.vue';
import PasswordRuleValidationLine from '@/Pages/Auth/PasswordRuleValidationLine.vue';

export default {
    components: {
        PasswordRuleValidationLine,
        JetInput,
        PasswordValidationErrors,
        JetButton,
        AuthLayout,
    },

    props: {
        email: String,
        token: String,
        isReset: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            formSubmitted: false,
            form: this.$inertia.form({
                email: this.email,
                token: this.token,
                password: '',
                password_confirmation: '',
            }),
        };
    },

    computed: {
        errors() {
            return this.$page.props.errorBags.default?.password || [];
        },

        hasErrors() {
            return (
                this.$page.props.errorBags.default?.password?.length > 0 ||
                false
            );
        },
        passwordError() {
            if (!this.hasErrors) {
                return '';
            }

            if (this.$page.props.errors.password?.includes('password.')) {
                return ' ';
            }

            return this.$page.props.errors.password;
        },
    },

    methods: {
        submit() {
            this.form.post(this.route('password.update'), {
                onFinish: () => {
                    this.form.reset('password', 'password_confirmation');
                    this.formSubmitted = true;
                },
            });
        },
    },
};
</script>
