<template>
    <CCard>
        <CCardHeader
            class="d-flex justify-content-between align-items-center cursor-pointer bg-blue-light"
        >
            <div>
                <h5 class="mb-0">
                    {{ title }}
                </h5>
            </div>
            <div v-if="hasAnyPermission('configuration.update')" class="d-flex">
                <Button
                    v-if="hasAnyPermission('configuration.update')"
                    class="btn btn-primary btn-icon"
                    @click="showModal = true"
                >
                    <CIcon name="cil-pencil" />
                </Button>
            </div>
        </CCardHeader>
        <CCardBody>
            <dl>
                <div class="my-1 row">
                    <dt class="col-md-7 text-uppercase">
                        {{ labelCadence }}
                    </dt>
                    <dd class="col-md-5 text-muted">
                        {{ valueCadence }}
                    </dd>
                </div>
                <div class="my-1 row">
                    <dt class="col-md-7 text-uppercase">
                        {{ labelNextDueDate }}
                    </dt>
                    <dd class="col-md-5 text-muted">
                        <div v-if="configuration?.nextDueDate">
                            {{
                                configuration.nextDueDate
                                    | dateTimeMedium(currentLocale)
                            }}
                        </div>
                        <div v-else>-</div>
                    </dd>
                </div>
                <div
                    v-if="configuration && configuration.performedBy"
                    class="my-1 row"
                >
                    <dt class="col-md-7 text-uppercase">
                        {{ labelPerformedBy }}
                    </dt>
                    <dd class="col-md-5 text-muted">
                        {{ configuration.performedBy.name }}
                    </dd>
                </div>
                <div class="my-1 row">
                    <dt class="col-md-7 text-uppercase">
                        {{ labelAlert }}
                    </dt>
                    <dd class="col-md-5 text-muted">
                        <div v-if="issues && issues.length">
                            <issue-badge
                                v-for="issue in issues"
                                :key="issue.id"
                                :issue="issue"
                            />
                        </div>
                        <span v-else>{{ valueNone }}</span>
                    </dd>
                </div>
            </dl>
            <dialog-modal
                :scrollable="false"
                :show="showModal"
                @close="$emit('cancel')"
            >
                <template #title>{{ modalTitle }}</template>

                <template #content>
                    <cadence-config-form
                        v-model="form.configuration"
                        :errors="form.errors"
                        :next-cadence-date-message-translation-key-never="`${translationKey}.configuration.form.cadence.next_interval_date.never`"
                        :next-cadence-date-message-translation-key-specified="`${translationKey}.configuration.form.cadence.next_interval_date.specified`"
                        :next-due-date-route="nextDueDateUrl"
                        :update-due-date="showModal"
                    />
                </template>

                <template #footer>
                    <CLink
                        class="btn btn-link btn-secondary mx-1"
                        @click.stop="showModal = false"
                        >{{ __('general.action.cancel') }}
                    </CLink>
                    <jet-button
                        :disabled="!isFormSubmittable"
                        color="primary"
                        @click.stop="handleSubmit"
                    >
                        {{ __('general.action.apply') }}
                    </jet-button>
                </template>
            </dialog-modal>
        </CCardBody>
    </CCard>
</template>

<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import JetButton from '@/Jetstream/Button.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import CadenceConfigForm from '@/Components/CadenceConfigForm.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        CadenceConfigForm,
        JetInput,
        DialogModal,
        JetSelect,
        IssueBadge,
        JetButton,
        CustomCardHeader,
    },
    props: [
        'asset',
        'translationKey',
        'storeUrl',
        'nextDueDateUrl',
        'configuration',
        'type',
        'issues',
    ],
    data() {
        return {
            showModal: false,
            form: this.$inertia.form({
                configuration: this.configuration,
            }),
        };
    },
    computed: {
        valueNone() {
            return this.__('general.none');
        },
        valueCadence() {
            if (!this.configuration) {
                return this.__('qa.configuration.detail.cadence.never');
            }

            let { unit, value } = this.configuration;
            return this.__(`qa.configuration.detail.cadence.${unit}`, {
                interval: value?.toString(),
            });
        },
        labelAlert() {
            return this.__(
                `${this.translationKey}.configuration.details.alert.label`
            );
        },
        labelCadence() {
            return this.__(
                `${this.translationKey}.configuration.details.cadence.label`
            );
        },
        labelNextDueDate() {
            return this.__(
                `${this.translationKey}.configuration.details.next_due_date.label`
            );
        },
        labelPerformedBy() {
            return this.__(
                `${this.translationKey}.configuration.details.performed_by.label`
            );
        },
        title() {
            return this.__(
                `${this.translationKey}.configuration.details.title`
            );
        },
        modalTitle() {
            return this.__(`${this.translationKey}.configuration.modal.title`);
        },
    },
    methods: {
        handleSubmit() {
            this.form.transform(this.transformData).post(this.storeUrl, {
                onSuccess: () => {
                    this.showModal = false;
                },
            });
        },
        transformData(data) {
            let { startDate } = data.configuration;

            if (startDate instanceof DateTime) {
                startDate = startDate
                    .set({ hours: 0, minutes: 0, seconds: 0 })
                    .toFormat('yyyy-MM-dd');
            }

            return { ...data.configuration, startDate, type: this.type };
        },
    },
};
</script>

<style scoped>
>>> .badge {
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 100%;
}
</style>
