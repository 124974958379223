<template>
    <dialog-modal :closeable="false" :show.sync="show" @close="$emit('close')">
        <template #title
            >{{ __('workorder.modal.confirm_delete.title') }}
        </template>

        <template #content>
            <div>{{ __('workorder.modal.confirm_delete.description') }}</div>
            <div class="font-xl text-center my-3">{{ workOrder?.code }}</div>
            <div class="text-danger d-flex align-items-start">
                <div class="mr-2">
                    <c-icon color="danger" name="cil-warning" />
                </div>
                <div>{{ __('workorder.modal.confirm_delete.warning') }}</div>
            </div>
        </template>

        <template #footer>
            <jet-button color="secondary" @click.stop="$emit('close')"
                >{{ __('general.action.cancel') }}
            </jet-button>
            <jet-button
                :is-processing="processing"
                color="primary"
                @click.stop="$emit('confirm', workOrder)"
            >
                {{ __('general.action.delete') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';

export default {
    components: {
        DialogModal,
        JetButton,
    },
    props: ['workOrder', 'show', 'processing'],
};
</script>
