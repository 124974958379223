<template>
    <form @submit.prevent="$emit('submitted')" class="mb-5">
        <CCard :id="id" class="mb-2">
            <CCardHeader
                class="d-flex justify-content-between align-items-center bg-blue-light"
            >
                <h5 class="mb-0"><slot name="title"></slot></h5>
                <div class="small text-muted">
                    <slot name="description"></slot>
                </div>
            </CCardHeader>

            <CCardBody>
                <slot name="form"></slot>
            </CCardBody>
            <CCardFooter>
                <div class="mb-4">
                    <small
                        ><em class="text-danger">*</em>
                        {{ __('general.required_fields') }}</small
                    >
                </div>
                <div
                    v-if="hasActions"
                    class="d-flex align-items-center justify-content-center"
                >
                    <slot name="actions"></slot>
                </div>
            </CCardFooter>
        </CCard>
    </form>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
    name: 'JetFormSection',

    props: ['id'],

    emits: ['submitted'],

    computed: {
        hasActions() {
            return !!this.$slots.actions;
        },
    },
});
</script>
