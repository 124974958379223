<template>
    <div>
        <jet-select
            :disabled="isDisabled"
            :horizontal="horizontal"
            :invalidFeedback="invalidFeedback"
            :label="label"
            :options="availableOptions || []"
            :placeholder="placeholder"
            :required="required"
            :value="value"
            @input="$emit('input', $event)"
        >
            <template #actions>
                <button-add-new v-if="showAction" @click.native="handleClick">
                    {{ actionButtonTitle || __('general.action.add_new') }}
                </button-add-new>
            </template>
        </jet-select>
        <slot name="modal" v-bind:modal="{ loadOptions }">
            <create-dropdown-option-modal
                v-if="showAction"
                :createRoute="routes.store"
                :label="label"
                :show="isModalVisible"
                :title="title || __('general.action.add_new')"
                @cancel="handleModalCancel"
                @success="handleModalSuccess"
            />
        </slot>
    </div>
</template>
<script>
import JetSelect from '@/Jetstream/Select.vue';
import CreateDropdownOptionModal from '@/Pages/Assets/Modals/CreateDropdownOptionModal.vue';
import ButtonAddNew from '@/Components/ButtonAddNew.vue';

export default {
    props: {
        title: String,
        label: String,
        actionButtonTitle: String,
        options: Array,
        required: {
            type: Boolean,
            default: false,
        },
        value: String,
        horizontal: {
            default: true,
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        disabled: Boolean,
        showAction: {
            type: Boolean,
            default: false,
        },
        routes: Object,
        dependentOn: String,
        optionTransformer: {
            type: Function,
            default: (option) => ({
                value: option.id,
                label: option.name,
            }),
        },
    },
    emits: ['input', 'open-modal'],
    components: {
        ButtonAddNew,
        JetSelect,
        CreateDropdownOptionModal,
    },
    data() {
        return {
            isLoading: false,
            isModalVisible: false,
            availableOptions: [],
        };
    },
    computed: {
        isDisabled() {
            if (this.dependentOn !== undefined && !this.dependentOn) {
                return true;
            }
            return this.isLoading;
        },
    },
    watch: {
        dependentOn: function () {
            this.loadOptions(this.value);
        },
    },
    mounted() {
        if (this.$options.propsData.dependentOn === undefined) {
            this.loadOptions();
        }
        this.availableOptions = this.options;
    },
    methods: {
        handleClick() {
            this.$emit('open');
            this.isModalVisible = true;
        },
        handleModalCancel() {
            this.isModalVisible = false;
        },
        handleModalSuccess(data) {
            this.isModalVisible = false;
            this.loadOptions(data.id);
        },
        loadOptions(selectedOptionId) {
            this.isLoading = true;

            this.$http(this.routes.index)
                .then((response) => {
                    if (response.data.data) {
                        this.availableOptions = this.transformOptions(
                            response.data.data
                        );

                        if (selectedOptionId) {
                            this.$emit('input', selectedOptionId);
                        } else if (this.availableOptions.length === 1) {
                            this.$emit('input', this.availableOptions[0].value);
                        } else if (this.dependentOn) {
                            this.$emit('input', null);
                        }
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        transformOptions(options) {
            return options.map(this.optionTransformer);
        },
    },
};
</script>
