<template>
    <CHeader with-subheader class="justify-content-between">
        <CToggler
            in-header
            class="ml-3 d-lg-none"
            @click="$store.commit('toggleSidebarMobile')"
        />
        <CToggler
            in-header
            class="ml-3 d-md-down-none"
            @click="$store.commit('toggleSidebarDesktop')"
        />

        <CHeaderNav>
            <TheHeaderDropdownAccnt />
            <NotificationCenter />
            <CHeaderNavItem
                class="mr-2"
                v-if="!!supportLink"
                v-c-tooltip="{
                    content: __('navigation.support'),
                    popperOptions: {
                        positionFixed: true,
                    },
                }"
            >
                <CHeaderNavLink class="header-support-link" :href="supportLink">
                    <span>?</span>
                </CHeaderNavLink>
            </CHeaderNavItem>
            <language-selector />
            <CHeaderNavItem class="px-2">
                <CIcon
                    name="logoLarge"
                    class="mx-4 d-md-block d-none"
                    style="width: 80px; height: 28px"
                />
            </CHeaderNavItem>
        </CHeaderNav>
    </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from '@/Containers/TheHeaderDropdownAccnt.vue';
import CMenu from '@/Containers/Menu.vue';
import NotificationCenter from '@/Components/NotificationCenter/NotificationCenter.vue';
import JetSelect from '@/Jetstream/Select.vue';
import LanguageSelector from './LanguageSelector.vue';

export default {
    name: 'TheHeader',
    components: {
        LanguageSelector,
        TheHeaderDropdownAccnt,
        NotificationCenter,
        CMenu,
        JetSelect,
    },
    computed: {
        supportLink() {
            return this.$page.props.supportLink;
        },
    },
};
</script>
<style scoped>
.header-support-link {
    width: 3.25rem;
    height: 36px;
    justify-content: center;
}

.header-support-link span {
    font-size: 1.1rem;
    line-height: 1rem;
    display: block;
}

>>> .dropdown-toggle {
    padding: 0 5px !important;
}
</style>
