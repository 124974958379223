import Vue from 'vue';

export const eventBus = new Vue();

setInterval(() => {
    const isActiveTab = (document.hasFocus && document.hasFocus()) ?? false;

    if (isActiveTab) {
        eventBus.$emit(
            events.clockUpdated,
            Math.floor(new Date().getTime() / 1000)
        );
    }
}, 1000);

export const events = {
    updateNotifications: 'notifications.update',
    assetOperabilityUpdated: 'asset.operability.updated',
    clockUpdated: 'clock.updated',
    openCreatePerformanceDataModal: 'open-create-performance-data-modal',
    editPerformanceDataItem: 'edit-performance-data-item',
    openTestRecordsModal: 'open-test-records-modal',
    openCreateStatusCheckModal: 'open-create-status-check-modal',
    openEditOwnerModal: 'open-edit-owner-modal',
    openCreateOwnerModal: 'open-create-owner-modal',
    openEditOperatorModal: 'open-edit-operator-modal',
    openCreateOperatorModal: 'open-create-operator-modal',
    openEditMaintainerModal: 'open-edit-maintainer-modal',
    openCreateMaintainerModal: 'open-create-maintainer-modal',
    openConfirmDeleteStakeholderModal: 'open-confirm-delete-stakeholder-modal',
};
