<template>
    <jet-action-section>
        <template #title>
            {{ __('profile.information.delete_profile.header') }}
        </template>

        <template #description>
            {{ __('profile.information.delete_profile.description') }}
        </template>

        <template #content>
            <div class="max-w-xl text-sm text-gray-600">
                {{ __('profile.information.delete_profile.content') }}
            </div>

            <div class="mt-5 d-flex justify-content-end">
                <jet-button color="danger" @click="confirmUserDeletion">
                    {{ __('profile.information.delete_profile.action.delete') }}
                </jet-button>
            </div>

            <!-- Delete Account Confirmation Modal -->
            <jet-dialog-modal
                :show="confirmingUserDeletion"
                @close="closeModal"
            >
                <template #title>
                    {{
                        __(
                            'profile.information.delete_profile.modal.delete_account.title'
                        )
                    }}
                </template>

                <template #content>
                    {{
                        __(
                            'profile.information.delete_profile.modal.delete_account.content'
                        )
                    }}

                    <div class="mt-4">
                        <jet-input
                            type="password"
                            class="mt-1 block w-3/4"
                            placeholder="Password"
                            ref="password"
                            v-model="form.password"
                            @keyup.enter="deleteUser"
                            :invalidFeedback="form.errors.password"
                        />
                    </div>
                </template>

                <template #footer>
                    <jet-button color="secondary" @click="closeModal">
                        {{ __('general.action.cancel') }}
                    </jet-button>

                    <jet-button
                        color="danger"
                        class="ml-2"
                        @click="deleteUser"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        {{
                            __(
                                'profile.information.delete_profile.action.delete'
                            )
                        }}
                    </jet-button>
                </template>
            </jet-dialog-modal>
        </template>
    </jet-action-section>
</template>

<script>
import JetActionSection from '@/Jetstream/ActionSection.vue';
import JetDialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    components: {
        JetActionSection,
        JetDialogModal,
        JetInput,
        JetInputError,
        JetButton,
    },

    data() {
        return {
            confirmingUserDeletion: false,

            form: this.$inertia.form({
                password: '',
            }),
        };
    },

    methods: {
        confirmUserDeletion() {
            this.confirmingUserDeletion = true;

            setTimeout(() => this.$refs.password.focus(), 250);
        },

        deleteUser() {
            this.form.delete(route('current-user.destroy'), {
                preserveScroll: true,
                onSuccess: () => this.closeModal(),
                onError: () => this.$refs.password.focus(),
                onFinish: () => this.form.reset(),
            });
        },

        closeModal() {
            this.confirmingUserDeletion = false;

            this.form.reset();
        },
    },
};
</script>
