<template>
    <div
        v-c-clickaway="hideElement"
        :class="{ 'c-show': show }"
        class="c-multi-select date-filter"
        @click="toggleVisibility"
        @keydown.esc="show = false"
        tabindex="0"
        style="user-select: none; cursor: pointer"
    >
        <span
            class="c-multi-select-selection d-flex justify-content-between align-items-center"
            style="min-height: 2rem"
        >
            <span class="text-truncate text-nowrap">{{
                value | fullDate(currentLocale)
            }}</span>
        </span>
        <div class="c-multi-select-options" style="width: auto" @click.stop="">
            <calendar
                is-expanded
                :first-day-of-week="2"
                :attributes="attributes"
                @dayclick="onDateSelect"
                :locale="currentLocale"
                :disabled-dates="disabledDates"
                trim-weeks
            >
                <template #day-popover="{ day, dayEvents }">
                    <div>
                        <div>
                            {{
                                DateTime.fromJSDate(day.date)
                                    | fullDate(currentLocale)
                            }}
                        </div>
                        <div v-for="obj in day.attributes">
                            <span v-if="obj.popover">{{
                                obj.popover.label
                            }}</span>
                        </div>
                    </div>
                </template>
            </calendar>
        </div>
    </div>
</template>
<script>
import { Calendar } from 'v-calendar';
import { DateTime } from 'luxon';

export default {
    name: 'date-filter',
    computed: {
        DateTime() {
            return DateTime;
        },
    },
    components: { Calendar },
    props: {
        value: String,
        attributes: Array,
        disabledDates: {
            type: Array,
            default: () => [],
        },
        selectionMode: {
            type: String,
            default: 'daily',
        },
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        hideElement() {
            this.show = false;
        },
        toggleVisibility() {
            if (this.disabled) {
                this.show = false;
            }
            this.show = !this.show;
        },
        onDateSelect(event) {
            let date = DateTime.fromJSDate(event.date);
            if (!!event.isDisabled) {
                return;
            }

            if (this.selectionMode === 'weekly') {
                date = date.endOf('week');
            }

            this.$emit('input', date.toFormat('yyyy-MM-dd'));
        },
    },
};
</script>
<style scoped>
>>> .vc-day.is-not-in-month * {
    opacity: 1 !important;
}

>>> .vc-day.is-not-in-month .vc-day-content {
    opacity: 0.3 !important;
}
</style>
