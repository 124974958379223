<template>
    <sticky-table-header>
        <CDataTable
            :border="true"
            :fields="fields"
            :items="permissions"
            :outlined="true"
            :striped="true"
        >
            <template #description="{ item }">
                <td class="align-middle">
                    <span v-c-tooltip="item.name">
                        {{ item.description }}
                    </span>
                </td>
            </template>
            <template #super-admin="{ item }">
                <td class="align-middle text-center">
                    <span
                        v-if="item['super-admin']"
                        class="d-inline-block bg-success rounded-circle"
                        style="width: 24px; height: 24px"
                    >
                        <c-icon class="text-black-100" name="cil-check-alt" />
                    </span>
                </td>
            </template>
            <template #admin="{ item }">
                <td class="align-middle text-center">
                    <span
                        v-if="item['admin']"
                        class="d-inline-block bg-success rounded-circle"
                        style="width: 24px; height: 24px"
                    >
                        <c-icon class="text-black-100" name="cil-check-alt" />
                    </span>
                </td>
            </template>
            <template #manager="{ item }">
                <td class="align-middle text-center">
                    <span
                        v-if="item['manager']"
                        class="d-inline-block bg-success rounded-circle"
                        style="width: 24px; height: 24px"
                    >
                        <c-icon class="text-black-100" name="cil-check-alt" />
                    </span>
                </td>
            </template>
            <template #user="{ item }">
                <td class="align-middle text-center">
                    <span
                        v-if="item['user']"
                        class="d-inline-block bg-success rounded-circle"
                        style="width: 24px; height: 24px"
                    >
                        <c-icon class="text-black-100" name="cil-check-alt" />
                    </span>
                </td>
            </template>
            <template #viewer="{ item }">
                <td class="align-middle text-center">
                    <span
                        v-if="item['viewer']"
                        class="d-inline-block bg-success rounded-circle"
                        style="width: 24px; height: 24px"
                    >
                        <c-icon class="text-black-100" name="cil-check-alt" />
                    </span>
                </td>
            </template>
        </CDataTable>
    </sticky-table-header>
</template>
<script>
import StickyTableHeader from '@/Components/StickyTableHeader.vue';

export default {
    name: 'PermissionsTable',
    components: { StickyTableHeader },
    props: {
        permissions: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        fields() {
            return [
                { key: 'description', label: 'Description' },
                {
                    key: 'super-admin',
                    label: 'System User',
                    _style: 'width: 100px; white-space: nowrap; text-align: center',
                },
                {
                    key: 'admin',
                    label: 'Admin',
                    _style: 'width: 100px; white-space: nowrap; text-align: center',
                },
                {
                    key: 'manager',
                    label: 'Manager',
                    _style: 'width: 100px; white-space: nowrap; text-align: center',
                },
                {
                    key: 'user',
                    label: 'User',
                    _style: 'width: 100px; white-space: nowrap; text-align: center',
                },
                {
                    key: 'viewer',
                    label: 'Viewer',
                    _style: 'width: 100px; white-space: nowrap; text-align: center',
                },
            ];
        },
    },
};
</script>
