<template>
    <app-layout>
        <CRow>
            <CCol md="6">
                <jet-form-section @submitted="handleSubmit">
                    <template #title>
                        <CIcon class="mx-1" name="cil-cog" />
                        {{ title }}
                    </template>

                    <template #form>
                        <h3>
                            {{
                                __('configurations.asset_configuration.header')
                            }}
                        </h3>
                        <p>
                            {{
                                __(
                                    'configurations.asset_configuration.description'
                                )
                            }}
                        </p>

                        <div class="my-5">
                            <CRow>
                                <CCol md="6">
                                    <jet-select
                                        v-model="form.hardware_version"
                                        :disabled="
                                            hardware_versions.length == 0
                                        "
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.hardware_version
                                        "
                                        :label="
                                            __(
                                                'configurations.field.hardware_version.label'
                                            )
                                        "
                                        :options="hardware_versions"
                                        :placeholder="
                                            __(
                                                'configurations.field.hardware_version.placeholder'
                                            )
                                        "
                                    />
                                </CCol>
                                <CCol md="6">
                                    <jet-select
                                        v-model="form.software_version"
                                        :disabled="
                                            software_versions.length == 0
                                        "
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.software_version
                                        "
                                        :label="
                                            __(
                                                'configurations.field.software_version.label'
                                            )
                                        "
                                        :options="software_versions"
                                        :placeholder="
                                            __(
                                                'configurations.field.software_version.placeholder'
                                            )
                                        "
                                    />
                                </CCol>
                                <CCol md="6">
                                    <jet-select
                                        v-model="form.conops"
                                        :disabled="conops.length == 0"
                                        :horizontal="false"
                                        :invalidFeedback="form.errors.conops"
                                        :label="
                                            __(
                                                'configurations.field.conops.label'
                                            )
                                        "
                                        :options="conops"
                                        :placeholder="
                                            __(
                                                'configurations.field.conops.placeholder'
                                            )
                                        "
                                    />
                                </CCol>
                                <CCol v-if="isSuperAdmin" md="6">
                                    <jet-select
                                        v-model="form.standards"
                                        :disabled="standards.length === 0"
                                        :horizontal="false"
                                        :invalidFeedback="form.errors.standards"
                                        :label="
                                            __(
                                                'configurations.field.standards.label'
                                            )
                                        "
                                        :options="standards"
                                        :placeholder="
                                            __(
                                                'configurations.field.standards.placeholder'
                                            )
                                        "
                                    />
                                </CCol>
                            </CRow>
                            <multi-select-dropdown
                                v-model="form.detection_algorithms"
                                :disabled="!detectionAlgorithms.length"
                                :horizontal="false"
                                :invalidFeedback="
                                    form.errors.detection_algorithms
                                "
                                :is-form-field="true"
                                :label="
                                    __(
                                        'configurations.field.detection_algorithm.label'
                                    )
                                "
                                :options="detectionAlgorithms"
                                :placeholder="
                                    __(
                                        'configurations.field.detection_algorithm.placeholder'
                                    )
                                "
                            />
                            <CRow>
                                <CCol>
                                    <multi-select-dropdown
                                        v-model="form.auxiliary_hardware"
                                        :disabled="!auxiliaryHardware.length"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.auxiliary_hardware
                                        "
                                        :is-form-field="true"
                                        :label="
                                            __(
                                                'configurations.field.auxiliary_hardware.label'
                                            )
                                        "
                                        :options="auxiliaryHardware"
                                        :placeholder="
                                            __(
                                                'configurations.field.auxiliary_hardware.placeholder'
                                            )
                                        "
                                    />
                                </CCol>
                            </CRow>
                        </div>

                        <div>
                            <h3>
                                {{ __('configurations.qualifications.header') }}
                            </h3>
                            <p>
                                {{
                                    __(
                                        'configurations.qualifications.description'
                                    )
                                }}
                            </p>
                        </div>

                        <multi-select-dropdown
                            v-model="form.qualifications"
                            :is-form-field="true"
                            :options="qualifications"
                            :placeholder="__('general.selection.placeholder')"
                            :show-select-all-button="false"
                        />
                    </template>

                    <template #actions>
                        <JetFormButtons
                            :cancelRoute="route('assets.show', asset.id)"
                            :disabled="!isFormSubmittable"
                            :is-processing="form.processing"
                            :isUpdateForm="isUpdateForm"
                        />
                    </template>
                </jet-form-section>
            </CCol>
        </CRow>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';

export default {
    components: {
        MultiSelectDropdown,
        AppLayout,
        CustomCardHeader,
        JetFormSection,
        JetFormButtons,
        JetSelect,
    },

    data() {
        return {
            form: this.$inertia.form({
                hardware_version: null,
                software_version: null,
                conops: null,
                standards: null,
                detection_algorithms: null,
                auxiliary_hardware: null,
                qualifications: null,
            }),
        };
    },

    computed: {
        isUpdateForm() {
            return !!this.configuration;
        },
        title() {
            return this.__('configurations.header');
        },
        asset() {
            return this.$page.props.asset;
        },
        configuration() {
            return this.$page.props.configuration;
        },
        hardware_versions() {
            return this.createOptions(this.$page.props.hardware_versions);
        },
        software_versions() {
            return this.createOptions(this.$page.props.software_versions);
        },
        conops() {
            return this.createOptions(this.$page.props.conops);
        },
        standards() {
            return this.createOptions(this.$page.props.standards);
        },
        detectionAlgorithms() {
            return this.$page.props.detection_algorithms.map((algorithm) => ({
                label: algorithm.name,
                value: algorithm.id,
            }));
        },
        auxiliaryHardware() {
            return this.$page.props.auxiliary_hardware.map((hardware) => ({
                label: hardware.name,
                value: hardware.id,
            }));
        },
        qualifications() {
            return this.$page.props.qualifications.data;
        },
        nextVersionNumber() {
            return this.configuration ? this.configuration.version + 1 : 1;
        },
        isSuperAdmin() {
            return this.user.roles.includes('super-admin');
        },
    },

    methods: {
        handleSubmit() {
            if (!this.isFormSubmittable) {
                return;
            }

            if (this.isUpdateForm) {
                this.updateConfiguration();
            } else {
                this.createConfiguration();
            }
        },
        createConfiguration() {
            this.form
                .transform(this.transformData)
                .post(route('assets.configurations.store', this.asset.id));
        },
        updateConfiguration() {
            this.form
                .transform(this.transformData)
                .put(
                    route('assets.configurations.update', [
                        this.asset.id,
                        this.configuration.id,
                    ])
                );
        },
        transformData(data) {
            return data;
        },

        createOptions(data, useProps = true) {
            return data
                ? data.map((item) => ({
                      label: useProps ? item.name : item,
                      value: useProps ? item.id : item,
                  }))
                : [];
        },
    },

    mounted() {
        if (this.configuration) {
            const {
                hardware_version,
                software_version,
                conops,
                standards,
                detection_algorithms,
                auxiliary_hardware,
                qualifications,
            } = this.configuration;

            this.form = this.$inertia.form({
                hardware_version: hardware_version ? hardware_version.id : null,
                software_version: software_version ? software_version.id : null,
                conops: conops,
                standards: standards,
                detection_algorithms: detection_algorithms.map(
                    (item) => item.value
                ),
                auxiliary_hardware: auxiliary_hardware.map(
                    (item) => item.value
                ),
                qualifications: qualifications,
            });
        }
    },
};
</script>
