<template>
    <span>
        <span @click="startConfirmingPassword">
            <slot />
        </span>

        <jet-dialog-modal :show="confirmingPassword" @close="closeModal">
            <template #title>
                {{ title }}
            </template>

            <template #content>
                {{ content }}

                <div class="mt-4">
                    <form @submit.prevent="$emit('submitted')">
                        <jet-input
                            type="password"
                            class="mt-1 block w-3/4"
                            :placeholder="__('auth.general.password')"
                            ref="password"
                            v-model="form.password"
                            @keyup.enter="confirmPassword"
                            :invalidFeedback="form.error"
                            autocomplete="current-password"
                        />
                    </form>
                </div>
            </template>

            <template #footer>
                <jet-button @click="closeModal" color="secondary">
                    {{ __('general.action.cancel') }}
                </jet-button>

                <jet-button
                    class="ml-2"
                    @click="confirmPassword"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    {{ button }}
                </jet-button>
            </template>
        </jet-dialog-modal>
    </span>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetDialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';

export default {
    name: 'JetConfirmsPassword',

    emits: ['confirmed'],

    props: {
        title: {
            default: 'Confirm Password',
        },
        content: {
            default:
                'For your security, please confirm your password to continue.',
        },
        button: {
            default: 'Confirm',
        },
    },

    components: {
        JetButton,
        JetDialogModal,
        JetInput,
        JetInputError,
    },

    data() {
        return {
            confirmingPassword: false,
            form: {
                password: '',
                error: '',
            },
        };
    },

    methods: {
        startConfirmingPassword() {
            this.$http.get(route('password.confirmation')).then((response) => {
                if (response.data.confirmed) {
                    this.$emit('confirmed');
                } else {
                    this.confirmingPassword = true;

                    setTimeout(() => this.$refs.password.focus(), 250);
                }
            });
        },

        confirmPassword() {
            this.form.processing = true;

            this.$http
                .post(route('password.confirm'), {
                    password: this.form.password,
                })
                .then(() => {
                    this.form.processing = false;
                    this.closeModal();
                    this.$nextTick(() => this.$emit('confirmed'));
                })
                .catch((error) => {
                    this.form.processing = false;
                    this.form.error = error.response.data.errors.password[0];
                    this.$refs.password.focus();
                });
        },

        closeModal() {
            this.confirmingPassword = false;
            this.form.password = '';
            this.form.error = '';
        },
    },
};
</script>
