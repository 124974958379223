<template>
    <div class="d-flex overflow-hidden">
        <div
            class="d-flex justify-content-center align-items-start flex-shrink-0"
            style="width: 40px"
        >
            <c-icon
                name="cil-check-circle"
                v-if="notification.type === 'location.access.granted'"
                style="height: 22px"
            />
            <c-icon
                name="cil-x-circle"
                v-else
                style="height: 22px"
                class="text-danger"
            />
        </div>
        <div class="overflow-hidden">
            <div>
                <b class="text-truncate d-block">{{ title }}</b>
            </div>
            <small class="d-block text-muted" v-html="description" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'airport.user.synched',
    props: {
        notification: {},
    },
    computed: {
        title() {
            return this.__(`notification.${this.notification.type}.title`);
        },
        description() {
            let { type, payload } = this.notification;
            const airports = payload
                .map((a) => `${a.name} (${a.iataCode})`)
                .join('<br />');
            // Translate asset status
            return this.__(`notification.${type}`, { airports });
        },
    },
};
</script>
