<template>
    <jet-form-section>
        <template #title>
            {{ __('profile.information.two_factor_authentication.title') }}
        </template>

        <template #form>
            <p class="mb-5">
                {{
                    __(
                        'profile.information.two_factor_authentication.description'
                    )
                }}
            </p>
            <h4 v-if="twoFactorEnabled" class="text-success">
                {{
                    __(
                        'profile.information.two_factor_authentication.enabled.content.header'
                    )
                }}
            </h4>

            <h4 v-else>
                {{
                    __(
                        'profile.information.two_factor_authentication.disabled.content.header'
                    )
                }}
            </h4>

            <div class="mt-3">
                <p>
                    {{
                        __(
                            'profile.information.two_factor_authentication.content.description'
                        )
                    }}
                </p>
            </div>

            <div v-if="twoFactorEnabled" class="d-flex">
                <div v-if="qrCode" class="p-2">
                    <div class="mt-4 max-w-xl text-sm text-gray-600">
                        <p class="small text-muted">
                            {{
                                __(
                                    'profile.information.two_factor_authentication.content.qr_code.description'
                                )
                            }}
                        </p>
                    </div>

                    <div
                        class="mt-4 d-flex justify-content-center"
                        v-html="qrCode"
                    ></div>
                </div>

                <div v-if="recoveryCodes.length > 0" class="p-2">
                    <div class="mt-4 max-w-xl text-sm text-gray-600">
                        <p class="font-semibold">
                            {{
                                __(
                                    'profile.information.two_factor_authentication.content.recovery_codes.description'
                                )
                            }}
                        </p>
                    </div>

                    <div
                        class="grid gap-1 max-w-xl mt-4 px-4 py-4 font-mono text-sm bg-gray-100 rounded-lg"
                    >
                        <div v-for="code in recoveryCodes" :key="code">
                            {{ code }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <div
                    v-if="!twoFactorEnabled"
                    class="d-flex justify-content-center align-items-center"
                >
                    <jet-confirms-password
                        @confirmed="enableTwoFactorAuthentication"
                    >
                        <jet-button type="button" :disabled="enabling">
                            {{
                                __(
                                    'profile.information.two_factor_authentication.action.enable'
                                )
                            }}
                        </jet-button>
                    </jet-confirms-password>
                </div>

                <div
                    v-else
                    class="d-flex justify-content-center align-items-center"
                >
                    <jet-confirms-password @confirmed="regenerateRecoveryCodes">
                        <jet-button
                            color="secondary"
                            class="mr-3"
                            v-if="recoveryCodes.length > 0"
                        >
                            {{
                                __(
                                    'profile.information.two_factor_authentication.action.regenerate_recovery_codes'
                                )
                            }}
                        </jet-button>
                    </jet-confirms-password>

                    <jet-confirms-password @confirmed="showRecoveryCodes">
                        <jet-button
                            color="secondary"
                            class="mr-3"
                            v-if="recoveryCodes.length === 0"
                        >
                            {{
                                __(
                                    'profile.information.two_factor_authentication.action.show_recovery_codes'
                                )
                            }}
                        </jet-button>
                    </jet-confirms-password>

                    <jet-confirms-password
                        @confirmed="disableTwoFactorAuthentication"
                    >
                        <jet-button :disabled="disabling" color="danger">
                            {{
                                __(
                                    'profile.information.two_factor_authentication.action.disable'
                                )
                            }}
                        </jet-button>
                    </jet-confirms-password>
                </div>
            </div>
        </template>
    </jet-form-section>
</template>

<script>
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetConfirmsPassword from '@/Jetstream/ConfirmsPassword.vue';

export default {
    components: {
        JetFormSection,
        JetButton,
        JetConfirmsPassword,
    },

    data() {
        return {
            enabling: false,
            disabling: false,

            qrCode: null,
            recoveryCodes: [],
        };
    },

    methods: {
        enableTwoFactorAuthentication() {
            this.enabling = true;

            this.$inertia.post(
                '/user/two-factor-authentication',
                {},
                {
                    preserveScroll: true,
                    onSuccess: () =>
                        Promise.all([
                            this.showQrCode(),
                            this.showRecoveryCodes(),
                        ]),
                    onFinish: () => (this.enabling = false),
                }
            );
        },

        showQrCode() {
            return this.$http
                .get('/user/two-factor-qr-code')
                .then((response) => {
                    this.qrCode = response.data.svg;
                });
        },

        showRecoveryCodes() {
            return this.$http
                .get('/user/two-factor-recovery-codes')
                .then((response) => {
                    this.recoveryCodes = response.data;
                });
        },

        regenerateRecoveryCodes() {
            this.$http
                .post('/user/two-factor-recovery-codes')
                .then((response) => {
                    this.showRecoveryCodes();
                });
        },

        disableTwoFactorAuthentication() {
            this.disabling = true;

            this.$inertia.delete('/user/two-factor-authentication', {
                preserveScroll: true,
                onSuccess: () => (this.disabling = false),
            });
        },
    },

    computed: {
        twoFactorEnabled() {
            return (
                !this.enabling && this.$page.props.auth.user.two_factor_enabled
            );
        },
    },
};
</script>
