<template>
    <div>
        <CCard>
            <CCardHeader
                class="d-flex justify-content-between align-items-center"
                style="min-height: 50px"
            >
                <h5 class="mb-0">{{ __('operators.overview.title') }}</h5>
                <Link
                    v-if="hasAnyPermission('operator.create')"
                    class="btn btn-primary"
                    @click.prevent="createOperator"
                    >{{ __('operators.overview.action.add') }}
                </Link>
            </CCardHeader>
            <CCardBody>
                <total-table-items
                    :text="
                        __('operators.overview.results.count', {
                            total: operators?.meta.total || 0,
                        })
                    "
                />
                <sticky-table-header>
                    <CDataTable
                        :border="true"
                        :clickableRows="false"
                        :fields="fields"
                        :items="operators?.data || []"
                        :noItemsView="noItemsView"
                        :outlined="true"
                        :sorter="{ external: true, resetable: false }"
                        :sorter-value="sortOrder"
                        :striped="true"
                        hover
                        @update:sorter-value="updateSorting"
                    >
                        <template #sorting-icon="{ classes, state }">
                            <sort-icon :class="classes" :state="state" />
                        </template>
                        <template #contact="{ item }">
                            <td class="align-middle">
                                <div v-if="item.email || item.description">
                                    <div v-if="item.email">
                                        <email-link :email="item.email" />
                                    </div>
                                    <div v-if="item.description">
                                        {{ item.description }}
                                    </div>
                                </div>
                                <div v-else>
                                    {{ __('general.not_available') }}
                                </div>
                            </td>
                        </template>
                        <template #assets_count="{ item }">
                            <td class="align-middle">
                                <div>{{ item.assets_count || 0 }}</div>
                            </td>
                        </template>
                        <template #locations_count="{ item }">
                            <td class="align-middle">
                                <div>{{ item.locations_count || 0 }}</div>
                            </td>
                        </template>
                        <template #actions="{ item }">
                            <td class="align-middle">
                                <div class="d-flex justify-content-end">
                                    <jet-button
                                        v-if="
                                            hasAnyPermission('operator.update')
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'operators.overview.tooltip.edit'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="primary"
                                        @click.stop="editOperator(item)"
                                    >
                                        <CIcon name="cil-pencil" />
                                    </jet-button>
                                    <jet-button
                                        v-if="
                                            hasAnyPermission('operator.delete')
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'operators.overview.tooltip.delete'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="danger"
                                        @click.stop="deleteOperator(item)"
                                    >
                                        <CIcon name="cil-trash" />
                                    </jet-button>
                                </div>
                            </td>
                        </template>
                    </CDataTable>
                </sticky-table-header>
                <Pagination
                    :only="['operators']"
                    :paginator="operators"
                    class="mt-2"
                />
            </CCardBody>
        </CCard>
        <update-or-create-operator-form-modal />
    </div>
</template>
<script>
import SortIcon from '@/Pages/Assets/SortIcon.vue';
import JetButton from '@/Jetstream/Button.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import Pagination from '@/Components/Pagination.vue';
import TotalTableItems from '@/Components/TotalTableItems.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import EmailLink from '@/Components/EmailLink.vue';
import UpdateOrCreateOperatorFormModal from '@/Pages/Stakeholders/Operators/UpdateOrCreateOperatorFormModal.vue';
import { eventBus, events } from '@/eventBus.js';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';

export default {
    name: 'OperatorsOverview',
    components: {
        DeleteStakeholderModal,
        UpdateOrCreateOperatorFormModal,
        EmailLink,
        CustomCardHeader,
        TotalTableItems,
        Pagination,
        StickyTableHeader,
        JetButton,
        SortIcon,
    },
    data() {
        return {
            sortOrder: {},
            fields: [
                {
                    key: 'name',
                    label: this.__('operators.overview.cell.name'),
                },
                {
                    key: 'contact',
                    label: this.__('operators.overview.cell.contact'),
                    _style: 'white-space: nowrap;width:300px;min-width:300px',
                },
                {
                    key: 'assets_count',
                    label: this.__('operators.overview.cell.assets'),
                    _style: 'white-space: nowrap;width:90px;',
                },
                {
                    key: 'locations_count',
                    label: this.__('operators.overview.cell.locations'),
                    _style: 'white-space: nowrap;width:90px;',
                },
                {
                    key: 'actions',
                    label: '',
                    sorter: false,
                    filter: false,
                    _style: 'white-space: nowrap;width:90px',
                },
            ],
            noItemsView: {
                noResults: this.__('operators.overview.filters.empty'),
                noItems: this.__('operators.overview.results.empty'),
            },
        };
    },
    computed: {
        operators() {
            return this.$page.props.operators;
        },
    },
    mounted() {
        let [column, order] = this.$page.props.operators_sort.split('.');
        this.sortOrder = {
            column,
            asc: order === 'asc',
        };
    },
    methods: {
        createOperator() {
            eventBus.$emit(events.openCreateOperatorModal);
        },
        editOperator(item) {
            eventBus.$emit(events.openEditOperatorModal, { operator: item });
        },
        deleteOperator(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'operator',
            });
        },
        updateSorting(event) {
            let sortOrder = event.asc ? 'asc' : 'desc';
            this.$inertia.visit(
                this.route(this.route().current(), {
                    operators_sort: `${event.column}.${sortOrder}`,
                }),
                {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['operators'],
                }
            );
        },
    },
};
</script>
