<template>
    <auth-layout>
        <CContainer class="align-items-center min-vh-100">
            <CRow class="min-vh-100 justify-content-center align-items-center">
                <CCol md="6">
                    <CCard class="p-4 m-0">
                        <CCardBody>
                            <CForm @submit.prevent="submit">
                                <h1>{{ __('auth.verify_email.header') }}</h1>
                                <p class="text-muted">
                                    {{ __('auth.verify_email.description') }}
                                </p>
                                <div
                                    class="text-success"
                                    v-if="verificationLinkSent"
                                >
                                    {{
                                        __(
                                            'auth.verify_email.info.verification_link_sent'
                                        )
                                    }}
                                </div>

                                <div class="d-flex justify-content-end">
                                    <jet-button
                                        type="submit"
                                        color="primary"
                                        class="px-4"
                                        :disabled="form.processing"
                                        >{{
                                            __(
                                                'auth.verify_email.action.submit'
                                            )
                                        }}</jet-button
                                    >
                                    <Link
                                        :href="route('logout')"
                                        method="post"
                                        as="button"
                                        >{{
                                            __(
                                                'auth.verify_email.action.logout'
                                            )
                                        }}</Link
                                    >
                                </div>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
export default {
    components: { AuthLayout, JetButton },
    props: {
        status: String,
    },

    data() {
        return {
            form: this.$inertia.form(),
        };
    },

    methods: {
        submit() {
            this.form.post(this.route('verification.send'));
        },
    },

    computed: {
        verificationLinkSent() {
            return this.status === 'verification-link-sent';
        },
    },
};
</script>
