<template>
    <auth-layout>
        <CContainer class="align-items-center min-vh-100">
            <CRow class="min-vh-100 justify-content-center align-items-center">
                <CCol md="6">
                    <CCard class="p-4 m-0">
                        <CCardBody>
                            <CForm @submit.prevent="submit">
                                <h1>{{ __('auth.register.header') }}</h1>
                                <CInput
                                    id="name"
                                    v-model="form.name"
                                    prependHtml="<i class='cui-user'></i>"
                                    :placeholder="__('auth.register.name')"
                                    autocomplete="name"
                                    required
                                    autofocus
                                >
                                    <template #prepend-content
                                        ><CIcon name="cil-user"
                                    /></template>
                                </CInput>
                                <CInput
                                    id="email"
                                    v-model="form.email"
                                    prependHtml="<i class='cui-user'></i>"
                                    :placeholder="__('auth.general.email')"
                                    autocomplete="username email"
                                    required
                                    autofocus
                                    :isValid="!hasErrors ? null : false"
                                >
                                    <template #prepend-content
                                        ><CIcon name="cil-user"
                                    /></template>
                                </CInput>
                                <CInput
                                    id="password"
                                    v-model="form.password"
                                    prependHtml="<i class='cui-lock-locked'></i>"
                                    :placeholder="__('auth.general.password')"
                                    type="password"
                                    autocomplete="new-password"
                                    required
                                    :isValid="!hasErrors ? null : false"
                                >
                                    <template #prepend-content
                                        ><CIcon name="cil-lock-locked"
                                    /></template>
                                </CInput>
                                <CInput
                                    id="confirm-password"
                                    v-model="form.password_confirmation"
                                    prependHtml="<i class='cui-lock-locked'></i>"
                                    :placeholder="
                                        __('auth.general.password_confirmation')
                                    "
                                    type="password"
                                    autocomplete="new-password"
                                    required
                                    :isValid="!hasErrors ? null : false"
                                >
                                    <template #prepend-content
                                        ><CIcon name="cil-lock-locked"
                                    /></template>
                                </CInput>
                                <CRow class="mb-4">
                                    <CCol>
                                        <jet-validation-errors />
                                    </CCol>
                                </CRow>
                                <div class="d-flex justify-content-end">
                                    <Link
                                        :href="route('login')"
                                        class="btn text-link"
                                    >
                                        {{
                                            __(
                                                'auth.register.action.already_registered'
                                            )
                                        }}
                                    </Link>
                                    <jet-button
                                        type="submit"
                                        color="primary"
                                        class="px-4"
                                        :disabled="form.processing"
                                        >{{
                                            __('auth.register.action.submit')
                                        }}</jet-button
                                    >
                                </div>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';

export default {
    components: {
        JetButton,
        JetValidationErrors,
        AuthLayout,
    },

    data() {
        return {
            form: this.$inertia.form({
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                terms: false,
            }),
        };
    },

    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },

    methods: {
        submit() {
            this.form.post(this.route('register'), {
                onFinish: () =>
                    this.form.reset('password', 'password_confirmation'),
            });
        },
    },
};
</script>
