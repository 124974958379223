<template>
    <CBadge
        :class="background"
        class="badge-lg d-flex align-items-center"
        style="gap: 0.25rem"
    >
        <em :class="operability" class="operability-indicator" />
        <span class="badge-label">
            {{ __('general.assetstatus.' + operability) }}
        </span>
    </CBadge>
</template>
<script>
export default {
    props: {
        usage: {},
        operability: {},
        inline: { type: Boolean, default: false },
    },
    computed: {
        label() {
            let key = this.usage ?? 'onboarded';
            return this.__('assets.status.' + key);
        },
        background() {
            if (this.inline) {
                return 'bg-transparent px-0 badge-inline';
            }

            if (this.usage === 'active') {
                return 'bg-blue-light';
            }

            return 'bg-secondary';
        },
    },
};
</script>
<style scoped>
.badge-inline .badge-label {
    font-weight: normal;
    font-size: 15px;
    text-transform: capitalize;
}

.operability-indicator {
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;
    border-radius: 100%;
    border: 1px solid;
}

.badge-label {
    position: relative;
    top: 1px;
}

.badge-lg {
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 0.75rem;
    padding: 4px 0.4em;
}
</style>
