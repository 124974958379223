<template>
    <div class="min-vh-100" :class="{ 'bg-success': isLoginRoute }">
        <CHeader with-subheader class="justify-content-end">
            <CHeaderNav>
                <language-selector />
                <CHeaderNavItem class="px-2">
                    <CIcon
                        name="logoLarge"
                        class="mx-4"
                        style="width: 80px; height: 28px"
                    />
                </CHeaderNavItem>
            </CHeaderNav>
        </CHeader>
        <slot />
    </div>
</template>
<script>
import LanguageSelector from '@/Containers/LanguageSelector.vue';

export default {
    components: { LanguageSelector },
    computed: {
        isLoginRoute() {
            return this.route().current('login');
        },
    },
};
</script>
