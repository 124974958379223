<template>
    <CBadge
        v-c-tooltip="{
            content: tooltip,
            popperOptions: {
                positionFixed: true,
            },
        }"
        class="info-icon flex-shrink-0"
        color="primary"
        type="pill"
        >i
    </CBadge>
</template>

<script>
export default {
    props: ['tooltip'],
};
</script>
<style scoped>
.info-icon {
    border-radius: 100%;
    height: 14px;
    width: 14px;
    padding: 0;
    line-height: 15px;
    font-size: 12px;
}
</style>
