<template>
    <CSidebar
        :minimize="minimize"
        :show="show"
        unfoldable
        @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    >
        <CSidebarMinimizer
            @click.native="$store.commit('toggle', 'sidebarMinimize')"
        />
        <div
            class="sidebar-brand-wrapper d-flex justify-content-center align-items-center pt-5 pb-4"
        >
            <CSidebarBrand
                class="d-flex justify-content-center align-items-center"
                href="/"
            >
                <img
                    v-if="logoUrl"
                    :src="logoUrl"
                    alt="App logo"
                    class="c-sidebar-brand-full"
                />
                <CIcon
                    v-else
                    name="cil-image-plus"
                    style="width: 25px; height: 25px"
                />
            </CSidebarBrand>
        </div>
        <CSidebarNav>
            <SidebarNavItem
                v-if="
                    hasAnyPermission('asset.update') &&
                    $page.props.assets_stats.onboardingIncomplete > 0
                "
                icon="cil-tags"
                routeName="assets.activation"
                :count="$page.props.assets_stats.onboardingIncomplete"
            />
            <SidebarNavItem
                v-if="
                    hasFeature('asset-work-order-management') &&
                    hasAnyPermission('workorder.read')
                "
                icon="cil-view-column"
                routeName="workorders.index"
            />
            <SidebarNavItem
                v-if="
                    hasFeature('dashboard') &&
                    hasAnyPermission('dashboard.read')
                "
                icon="cil-bar-chart"
                routeName="dashboard.index"
            />
            <SidebarNavItem
                v-if="hasAnyPermission('asset.read')"
                :count="totalAssetsCount"
                icon="cil-layers"
                routeName="assets.index"
            >
                <ul
                    v-if="$page.props.activeNavigation['assets.index']"
                    class="list-unstyled c-sidebar-subnav"
                >
                    <sidebar-sub-nav-item
                        :active="routeContainsUsageCategory('active')"
                        :href="
                            route('assets.index', {
                                usages: ['active'],
                            })
                        "
                        class="d-block"
                    >
                        {{ __('assets.sidebar.active') }} ({{
                            countAssetsByUsageCategory('active')
                        }})
                    </sidebar-sub-nav-item>
                    <sidebar-sub-nav-item
                        :active="routeContainsUsageCategory('spare')"
                        :href="
                            route('assets.index', {
                                usages: ['spare'],
                            })
                        "
                        class="d-block"
                    >
                        {{ __('assets.sidebar.spare') }} ({{
                            countAssetsByUsageCategory('spare')
                        }})
                    </sidebar-sub-nav-item>
                    <sidebar-sub-nav-item
                        :active="routeContainsUsageCategory(['decommissioned'])"
                        :href="
                            route('assets.index', {
                                usages: ['decommissioned'],
                            })
                        "
                        class="d-block"
                    >
                        {{ __('assets.sidebar.decommissioned') }} ({{
                            countAssetsByUsageCategory('decommissioned')
                        }})
                    </sidebar-sub-nav-item>
                </ul>
            </SidebarNavItem>
            <SidebarNavItem
                v-if="
                    hasFeature('reporting-light') &&
                    hasAnyPermission('reporting_light.read')
                "
                icon="cil-description"
                routeName="reporting-light.index"
            />
            <li
                v-if="hasAnyPermission('asset.create')"
                class="c-sidebar-nav-item pt-4 px-3 d-flex justify-content-center"
            >
                <CButton
                    class="btn btn-primary btn-shrink"
                    @click.stop="$inertia.visit(route('assets.create'))"
                >
                    <CIcon name="cil-plus" />
                    <span>{{
                        __('assets.details.create_new_asset')
                    }}</span></CButton
                >
            </li>
            <li
                v-if="hasAnyPermission('asset.read')"
                class="c-sidebar-nav-item d-flex pt-4 px-3 justify-content-center"
            >
                <CButton
                    class="btn btn-primary btn-shrink"
                    @click.stop="openQrCodeScanner()"
                >
                    <CIcon name="cil-qr-code" />
                    <span>{{
                        __('assets.overview.scanner.title')
                    }}</span></CButton
                >
            </li>
            <CSidebarNavTitle
                v-if="
                    hasAnyPermission([
                        'user.index',
                        'physical_location.index',
                        'settings.index',
                    ])
                "
                >{{ __('navigation.admin') }}
            </CSidebarNavTitle>
            <SidebarNavItem
                v-if="hasAnyPermission('user.index')"
                icon="cil-group"
                routeName="users.index"
            />
            <SidebarNavItem
                v-if="hasAnyPermission('physical_location.index')"
                icon="cil-airplane-mode"
                routeName="airports.index"
            />
            <SidebarNavItem
                v-if="
                    hasAnyPermission([
                        'owner.index',
                        'operator.index',
                        'maintainer.index',
                    ])
                "
                icon="cil-sitemap"
                routeName="stakeholders.index"
            />
            <SidebarNavItem
                v-if="hasAnyPermission('settings.index')"
                icon="cil-cog"
                routeName="settings.index"
            />
        </CSidebarNav>
        <div class="version-spacer"></div>
        <div class="d-flex flex-column align-items-center pt-3">
            <optional-link :href="curieTechUrl">
                <CIcon
                    class="logo-curie"
                    name="logoCurie"
                    style="height: 22px; width: auto"
                    title="Curie Technologies"
                />
            </optional-link>
            <div v-if="version" class="px-4 pt-2 pb-3 version text-center">
                <small :title="commit" class="text-muted"
                    ><b>v{{ version }}</b></small
                >
            </div>
        </div>
        <petitec-id-scanner
            ref="petitecIdScanner"
            :pid="null"
            :show="isPetitecIdScannerVisible"
            :submitButtonLabel="__('general.action.go')"
            @close="closePetitecIdScanner"
            @error="onPetitecIdScannerError"
            @success="onPetitecIdScannerSuccess"
        ></petitec-id-scanner>
    </CSidebar>
</template>

<script>
import SidebarNavItem from '@/Components/SidebarNavItem.vue';
import SidebarSubNavItem from '@/Components/SidebarSubNavItem.vue';
import OptionalLink from '@/Components/OptionalLink.vue';
import JetButton from '@/Jetstream/Button.vue';
import PetitecIdScanner from '@/Components/PetitecIdScanner.vue';
import { isEqual } from 'lodash';

export default {
    name: 'TheSidebar',

    components: {
        SidebarNavItem,
        SidebarSubNavItem,
        OptionalLink,
        JetButton,
        PetitecIdScanner,
    },

    data() {
        return {
            isPetitecIdScannerVisible: false,
        };
    },

    computed: {
        show() {
            return this.$store.state.sidebarShow;
        },
        minimize() {
            return this.$store.state.sidebarMinimize;
        },
        userManual() {
            return `user_manual_${this.$page.props.locale}.pdf`;
        },
        logoUrl() {
            return this.$page.props.settings.big_logo_url;
        },
        version() {
            return this.$page.props.version?.semver;
        },
        commit() {
            return this.$page.props.version?.commit_hash;
        },
        totalAssetsCount() {
            return Object.keys(this.$page.props.assets_stats)
                .filter((key) => key !== 'onboardingIncomplete')
                .reduce((a, b) => a + this.$page.props.assets_stats[b], 0);
        },
        curieTechUrl() {
            return this.$page.props.curie_tech_url;
        },
        scanner() {
            return this.$refs.petitecIdScanner;
        },
        isInventoryPage() {
            return [
                'assets.index',
                'assets.show',
                'assets.tests.index',
                'assets.workorders.index',
                'assets.activities.index',
            ].includes(this.route().current());
        },
    },

    methods: {
        countAssetsByUsageCategory(usageCategory) {
            return this.$page.props.assets_stats[usageCategory] || 0;
        },
        routeContainsUsageCategory(usageCategory) {
            if (this.route().current() !== 'assets.index') {
                return false;
            }

            const params = this.route().params;

            // Wrap in array if necessary
            usageCategory = Array.isArray(usageCategory)
                ? usageCategory
                : [usageCategory];

            return isEqual(params.usages, usageCategory);
        },
        openQrCodeScanner() {
            this.isPetitecIdScannerVisible = true;
        },
        closePetitecIdScanner() {
            this.isPetitecIdScannerVisible = false;
        },
        onPetitecIdScannerSuccess(data) {
            const petitecId = data.uuid;
            this.searchAsset(petitecId);
        },
        onPetitecIdScannerError() {},
        searchAsset(petitecId) {
            this.$http
                .get(route('assets.search', { pid: petitecId }))
                .then((resp) => {
                    const asset = resp.data;
                    this.scanner.isValidQrCode = true;
                    setTimeout(() => {
                        this.$inertia.visit(route('assets.show', asset.id));
                    }, 300);
                })
                .catch((err) => {
                    this.scanner.isValidQrCode = false;
                    this.scanner.form.errors.petitec_identifier = this.__(
                        'assets.overview.scanner.error.asset_not_found'
                    );
                });
        },
    },

    mounted() {
        this.$root.$on('toggle-sidebar', () => {
            const sidebarOpened =
                this.show === true || this.show === 'responsive';
            this.show = sidebarOpened ? false : 'responsive';
        });
        this.$root.$on('toggle-sidebar-mobile', () => {
            const sidebarClosed =
                this.show === 'responsive' || this.show === false;
            this.show = sidebarClosed ? true : 'responsive';
        });
    },
};
</script>
<style lang="scss" scoped>
.version {
    display: block;
}

.version-spacer {
    flex-grow: 1;
}

@media (max-width: 991.98px) {
    .version {
        text-align: right;
        width: 100%;
    }
    .version-spacer {
        display: block;
        height: 50px;
    }
}

.btn-shrink {
    width: 100%;

    .c-icon {
        display: none;
    }
}

.c-sidebar-unfoldable {
    .btn-shrink {
        padding: 0 0.25rem;

        .c-icon {
            display: inline-block;
        }

        @media (max-width: 575px) {
            .c-icon {
                display: none;
            }
        }

        @media (min-width: 576px) {
            span {
                display: none;
            }
        }
    }

    &:hover .btn-shrink {
        padding: 0.1rem 0.5rem;

        .c-icon {
            display: none;
        }

        span {
            display: inline-block;
        }
    }

    .version,
    .logo-curie {
        display: none;
    }

    &:hover {
        .version,
        .logo-curie {
            display: block;
        }
    }
}
</style>
