<template>
    <div class="d-flex flex-column">
        <qrcode-stream
            @decode="onDecode"
            :camera="camera"
            :track="paintBoundingBox"
            @init="init"
        >
            <div class="d-flex justify-content-center align-items-center">
                <div
                    class="d-flex justify-content-center align-items-center w-100"
                    style="position: absolute"
                >
                    <div>
                        <CIcon
                            class="logo"
                            name="logoSmall"
                            style="width: 100px; height: 100px"
                            :class="{ translucent: !isLoading }"
                        />
                    </div>
                </div>

                <div style="position: absolute" v-if="isLoading">
                    <CSpinner
                        color="primary"
                        style="width: 1.5rem; height: 1.5rem"
                        class="m-2"
                    />
                </div>

                <div
                    style="position: absolute"
                    class="text-center text-danger"
                    v-if="cameraError"
                >
                    <div v-if="cameraError.name == 'NotAllowedError'">
                        {{ __('general.camera.error.not_allowed') }}
                    </div>
                    <div v-else>
                        {{ __('general.camera.error.generic') }}
                    </div>
                </div>

                <div
                    v-if="isLoading"
                    class="w-100 d-flex justify-content-center align-items-center loading-label"
                >
                    <div>
                        {{ __('qr_code_scanner.loading.label') }}
                    </div>
                </div>
                <div
                    v-if="validationSuccess"
                    class="validation-success d-flex justify-content-center align-items-center"
                >
                    <CIcon
                        name="cil-check-circle"
                        class="text-success"
                        style="width: 5rem; height: 5rem"
                    />
                </div>

                <div
                    v-if="validationFailure"
                    class="validation-failure d-flex justify-content-center align-items-center"
                >
                    <CIcon
                        name="cil-ban"
                        class="text-danger"
                        style="width: 5rem; height: 5rem"
                    />
                </div>
                <div
                    v-if="validationPending"
                    class="validation-success d-flex justify-content-center align-items-center"
                >
                    <CSpinner
                        color="primary"
                        style="width: 1.5rem; height: 1.5rem"
                        class="m-2"
                    />
                </div>
            </div>
        </qrcode-stream>
    </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    props: ['value', 'valid'],

    emits: ['input', 'success', 'failure', 'reset'],

    components: {
        QrcodeStream,
    },

    data() {
        return {
            result: null,
            camera: 'auto',
            isLoading: true,
            cameraError: null,
        };
    },

    computed: {
        validationPending() {
            return this.valid === undefined && this.camera === 'off';
        },

        validationSuccess() {
            return this.valid === true;
        },

        validationFailure() {
            return this.valid === false;
        },
        hasBrowserCompatibility() {
            return !!navigator.mediaDevices;
        },
    },

    methods: {
        init(promise) {
            promise
                .then(() => {
                    this.cameraError = null;
                })
                .catch((error) => {
                    this.cameraError = error;
                })
                .finally(() => {
                    this.isLoading = false;
                    this.resetValidationState();
                });
        },
        resetValidationState() {
            this.$emit('reset');
        },
        turnCameraOn() {
            this.camera = 'auto';
        },
        turnCameraOff() {
            this.camera = 'off';
        },
        onDecode(code) {
            try {
                if (!code) {
                    throw 'qr_code_not_found';
                }

                // Parse JSON
                if (!code.startsWith('"')) {
                    code = '"' + code;
                }

                if (!code.endsWith('"')) {
                    code += '"';
                }

                let codeContainer = JSON.parse(code);

                if (typeof codeContainer === 'string') {
                    codeContainer = JSON.parse(codeContainer);
                }

                if (!codeContainer.uuid) {
                    throw 'qr_code_not_found';
                }

                this.turnCameraOff();
                this.valid = undefined;

                // Close modal
                this.$emit('input', codeContainer);
                this.$emit('success', codeContainer);
            } catch (error) {
                this.valid = false;

                // Display errors
                this.qrCodeError = this.__(
                    'qr_code_scanner.modal.error.message'
                );

                this.$emit('input', null);
                this.$emit('failure', error);
            }
        },

        paintBoundingBox(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const {
                    boundingBox: { x, y, width, height },
                } = detectedCode;

                ctx.lineWidth = 4;
                ctx.strokeStyle = '#17FFC6';
                ctx.strokeRect(x, y, width, height);
            }
        },
    },
};
</script>
<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    flex-flow: column nowrap;
}
.logo {
    transition: opacity 1s ease;
}
.translucent {
    opacity: 0;
}

.loading-label {
    margin-top: 150px;
}
</style>
