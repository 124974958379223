<template>
    <div
        :class="{ 'form-row': horizontal }"
        class="form-group datepicker"
        role="group"
    >
        <label
            v-if="label"
            :class="{ 'col-form-label col-sm-3': horizontal, required }"
            :for="id"
        >{{ label }}</label
        >
        <div v-if="isEditable" :class="{ 'col-sm-9': horizontal }">
            <date-picker
                :locale="currentLocale"
                :max-date="maxDate"
                :min-date="minDate"
                :popover="{
                    visibility: 'focus',
                    positionFixed: false,
                }"
                :value="value"
                @input="dateChanged"
            >
                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                    <div class="d-flex align-items-center selected-date">
                        <button
                            :class="{ 'is-invalid': !isValid }"
                            class="btn btn-text p-0 mw-0"
                            style="line-height: inherit"
                            @click.stop="togglePopover"
                        >
                            {{ value | fullDate(currentLocale) || placeholder }}
                        </button>

                        <div
                            v-if="deletable"
                            v-show="!!value"
                            class="delete-action"
                        >
                            <button
                                class="btn btn-text d-flex align-items-center mx-1"
                                @click="$emit('input', null)"
                            >
                                <CIcon class="text-danger m-0" name="cil-x"/>
                            </button>
                        </div>
                        <div v-if="description" class="text-muted">
                            <small>{{ description }}</small>
                        </div>
                    </div>
                </template>
            </date-picker>
            <div
                v-if="invalidFeedback"
                class="invalid-feedback"
                style="display: block"
            >
                {{ invalidFeedback }}
            </div>
        </div>
        <div v-else-if="value" :class="{ 'col-sm-9': horizontal }">
            {{ value | fullDate(currentLocale) }}
        </div>
        <div v-else :class="{ 'col-sm-9': horizontal }">
            {{ placeholder }}
        </div>
    </div>
</template>

<script>
import {DatePicker} from 'v-calendar';
import {DateTime} from 'luxon';

export default {
    name: 'JetDatepicker',

    components: {
        DatePicker,
    },

    props: {
        label: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        value: Date | null,
        required: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        minDate: {
            type: Object | String,
        },
        maxDate: {
            type: Object | String,
        },
        deletable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            id: null,
        };
    },

    emits: ['update:modelValue'],

    computed: {
        isEditable() {
            return !this.readonly && !this.disabled;
        },
        isValid() {
            return (
                this.invalidFeedback === null ||
                this.invalidFeedback?.trim() === ''
            );
        },
    },

    watch: {
        minDate(newValue) {
            if (typeof newValue === 'string') {
                newValue = DateTime.fromISO(newValue);
            }

            if (newValue > DateTime.fromISO(this.value)) {
                this.$emit('input', null);
            }
        },
    },

    mounted() {
        this.id = this._uid;
    },

    methods: {
        focus() {
            this.$refs.input.focus();
        },
        dateChanged(value) {
            let date = DateTime.fromJSDate(value);
            date = date.set({hour: 0, minute: 0, second: 0, millisecond: 0});
            this.$emit('input', date);
        },
    },
};
</script>
<style scoped>
.form-control[readonly] {
    background: none;
}

>>> .vc-day.is-today .vc-day-content {
    background: #efefef;
}

>>> .vc-day.is-today .vc-highlights + .vc-day-content {
    background: none;
}

>>> .vc-highlight {
    background-color: #3e56c6 !important;
}

.delete-action {
    display: none;
}

.selected-date:hover .delete-action,
.selected-date:focus .delete-action {
    display: block;
}
</style>
