<template>
    <DialogModal :show.sync="show">
        <template #title>
            {{ __('qr_code_scanner.modal.header') }}
        </template>
        <template #content>
            <qr-code-scanner
                :value="value"
                @input="handleUpdate"
                @success="onSuccess"
                @failure="onError"
                v-if="show"
            />
        </template>
        <template #footer>
            <div
                class="w-100 d-flex justify-content-between align-items-center"
            >
                <div>
                    <small v-if="!!qrCodeSuccess" class="text-success">
                        {{ qrCodeSuccess }}
                    </small>
                    <small v-if="!!qrCodeError" class="text-danger">
                        {{ qrCodeError }}
                    </small>
                </div>
                <jet-button
                    color="secondary"
                    type="button"
                    @click="closeModal"
                    >{{ __('general.action.cancel') }}</jet-button
                >
            </div>
        </template>
    </DialogModal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import QrCodeScanner from '@/Components/QrCodeScanner.vue';

export default {
    props: ['value', 'show'],

    data() {
        return {
            qrCodeSuccess: null,
            qrCodeError: null,
        };
    },

    components: {
        DialogModal,
        JetButton,
        QrCodeScanner,
    },

    mounted() {
        this.$watch('show', (el) => {
            this.qrCodeError = null;
            this.qrCodeSuccess = null;
        });
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },
        onSuccess(msg) {
            this.qrCodeError = null;
            this.qrCodeSuccess = this.__(
                'qr_code_scanner.modal.success.message'
            );
        },
        onError(err) {
            this.qrCodeSuccess = null;
            this.qrCodeError = this.__('qr_code_scanner.modal.error.message');
        },
        handleUpdate(data) {
            this.$emit('input', data.uuid || null);

            if (data) {
                setTimeout(() => {
                    this.$emit('close');
                }, 500);
            }
        },
    },
};
</script>
