<template>
    <div>
        <CCard>
            <CustomCardHeader :title="__('activities.overview.header')"/>
            <CCardBody>
                <sticky-table-header>
                    <CDataTable
                        :border="true"
                        :clickableRows="true"
                        :fields="visibleFields"
                        :items="items"
                        :noItemsView="noItemsView"
                        :outlined="true"
                        :striped="true"
                        hover
                        @row-clicked="showDetails"
                    >
                        <template #sorting-icon="{ classes, state }">
                            <sort-icon :class="classes" :state="state"/>
                        </template>
                        <template #date="{ item }">
                            <td class="align-top">
                                <div class="text-muted">
                                    <div class="text-nowrap">
                                        {{
                                            item.date | fullDate(currentLocale)
                                        }}
                                    </div>
                                    <small>
                                        <CIcon name="cil-clock"/>
                                        {{
                                            item.date | time(currentLocale)
                                        }}</small
                                    >
                                </div>
                            </td>
                        </template>

                        <template #causer="{ item }">
                            <td class="align-top">
                                <user-information
                                    v-if="item.causer"
                                    :info="item.causer"
                                />
                                <span v-else>-</span>
                            </td>
                        </template>

                        <template #event="{ item }">
                            <td class="align-top">
                                <Activity :item="item"/>
                            </td>
                        </template>
                    </CDataTable>
                </sticky-table-header>
            </CCardBody>
        </CCard>
    </div>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import UserInformation from '@/Components/UserInformation.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import Activity from '@/Components/ActivityLog/Activity.vue';
import SortIcon from "@/Pages/Assets/SortIcon.vue";

export default {
    props: {
        activities: {
            type: Object,
            required: true,
        },
        context: {
            type: String,
            required: true,
        },
        fields: {
            type: Array,
            default: () => ['date', 'causer', 'event'],
        },
    },

    components: {
        SortIcon,
        Activity,
        StickyTableHeader,
        CustomCardHeader,
        UserInformation,
    },

    data() {
        return {
            activityFields: [
                {
                    key: 'date',
                    label: this.__('activities.overview.cell.date'),
                },
                {
                    key: 'causer',
                    label: this.__('activities.overview.cell.user'),
                },
                {
                    key: 'event',
                    label: this.__('activities.overview.cell.event'),
                },
            ],
            noItemsView: {
                noItems: this.__('activities.overview.results.empty'),
            },
        };
    },

    computed: {
        visibleFields() {
            return this.fields.map((field) => {
                return this.activityFields.find((item) => item.key === field);
            });
        },

        items() {
            return this.activities.data;
        },
    },
    methods: {
        showDetails(activity) {
            if (activity.url) {
                this.$inertia.visit(activity.url);
            }
        },
    },
};
</script>
