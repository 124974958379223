<template>
    <DialogModal
        v-if="
            shouldRender &&
            hasFeature('test-record') &&
            hasAnyPermission('test.create')
        "
        :show.sync="show"
    >
        <template #title>
            {{ __('test_record.modal.header') }}
        </template>
        <template #subtitle>
            {{ __('test_record.modal.description') }}
        </template>
        <template #content>
            <form @submit.prevent="submitForm">
                <CRow class="py-4">
                    <CCol class="d-flex justify-content-center">
                        <div
                            :style="{
                                opacity: form.resolution === 'fail' ? 0.3 : 1,
                            }"
                            class="mr-3"
                        >
                            <button
                                :class="{
                                    'btn--selected':
                                        form.resolution === 'success',
                                }"
                                class="btn btn-icon btn-icon-lg btn-success"
                                @click.prevent="reportSuccess()"
                            >
                                <CIcon name="cil-check" />
                            </button>
                            <div>
                                <h4 class="m-0 text-center">
                                    {{
                                        __(
                                            'test_record.form.success.button.label'
                                        )
                                    }}
                                </h4>
                            </div>
                        </div>
                        <div
                            :style="{
                                opacity:
                                    form.resolution === 'success' ? 0.3 : 1,
                            }"
                        >
                            <button
                                :class="{
                                    'btn--selected': form.resolution === 'fail',
                                }"
                                class="btn btn-icon btn-icon-lg btn-danger"
                                @click.prevent="reportError()"
                            >
                                <CIcon
                                    name="cil-medical-cross"
                                    style="transform: rotate(45deg)"
                                />
                            </button>
                            <div>
                                <h4 class="m-0 text-center">
                                    {{
                                        __('test_record.form.fail.button.label')
                                    }}
                                </h4>
                            </div>
                        </div>
                    </CCol>
                </CRow>
                <CRow v-if="form.errors.resolution">
                    <CCol>
                        <div v-for="(message, key) in form.errors" :key="key">
                            <small class="text-danger">{{ message }}</small>
                        </div>
                    </CCol>
                </CRow>
                <CRow class="mt-3">
                    <CCol>
                        <jet-textarea
                            v-model="form.description"
                            :horizontal="false"
                            :invalidFeedback="form.errors.description"
                            :isValid="!form.errors.description"
                            :label="__('test_record.form.report.label')"
                            :placeholder="
                                __('test_record.form.report.placeholder')
                            "
                            :required="form.resolution === 'fail'"
                            :rows="Number(5)"
                        />
                        <div class="mb-4 w-100 d-flex justify-content-start">
                            <small
                                ><em class="text-danger">*</em>
                                {{ __('general.required_fields') }}</small
                            >
                        </div>
                    </CCol>
                </CRow>
                <CRow
                    v-if="hasAnyPermission(['test.attachment.create'])"
                    class="mt-3"
                >
                    <CCol>
                        <h5>
                            {{ __('test_record.form.fileupload.label') }}
                        </h5>
                        <file-upload
                            :key="componentKey"
                            v-model="form.attachments"
                            @upload-started="uploadInProgress = true"
                            @upload-completed="uploadInProgress = false"
                        />
                    </CCol>
                </CRow>
            </form>
        </template>
        <template #footer>
            <div class="w-100 d-flex justify-content-center align-items-center">
                <jet-button
                    :disabled="form.processing || uploadInProgress"
                    class="mx-2"
                    color="secondary"
                    @click="closeModal()"
                    >{{ __('general.action.cancel') }}
                </jet-button>
                <jet-button
                    :disabled="
                        !isFormSubmittable ||
                        !form.resolution ||
                        uploadInProgress
                    "
                    :is-processing="form.processing"
                    @click.prevent="submitForm()"
                >
                    {{ __('general.action.save') }}
                </jet-button>
            </div>
        </template>
    </DialogModal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetTextarea from '@/Jetstream/Textarea.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';

export default {
    props: ['asset'],

    components: {
        DialogModal,
        JetButton,
        JetTextarea,
        FileUpload,
    },

    data() {
        return {
            show: false,
            shouldRender: false,
            componentKey: 0,
            uploadInProgress: false,
            form: this.$inertia.form({
                resolution: undefined,
                description: '',
                attachments: [],
            }),
        };
    },

    mounted() {
        eventBus.$on([events.openTestRecordsModal], (data) => {
            this.form.reset();
            this.shouldRender = true;
            this.componentKey += 1;
            this.uploadInProgress = false;
            this.$nextTick(() => {
                this.show = true;
            });
        });
    },

    destroyed() {
        eventBus.$off([events.openTestRecordsModal]);
    },

    methods: {
        submitForm() {
            if (!this.isFormSubmittable) {
                return;
            }

            this.form.post(this.route('assets.tests.store', this.asset.id), {
                preserveScroll: true,
                onSuccess: () => {
                    this.closeModal();
                    eventBus.$emit(events.updateNotifications);
                    this.$emit('created');
                },
            });
        },
        onError() {
            this.$emit('error');
        },
        reportSuccess() {
            this.form.resolution = 'success';
        },
        reportError() {
            this.form.resolution = 'fail';
        },
        closeModal() {
            this.show = false;

            setTimeout(() => {
                this.form.reset();
                this.$emit('close');
            }, 300);
        },
    },
};
</script>
<style lang="scss" scoped>
.btn-round {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    box-shadow: none;
    border: 4px solid #2eb85c;
    background: #fff;
    color: #2eb85c;
    outline: none;
    opacity: 0.25;

    .c-icon {
        width: 3rem !important;
        height: 3rem !important;
    }

    &.btn-round--red {
        border-color: #e55353;
        color: #e55353;
    }

    &.btn--selected {
        opacity: 1;
    }
}
</style>
