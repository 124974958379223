<template>
    <div class="form-group">
        <label v-if="label" v-html="label" />
        <CInputRadioGroup
            :checked="value"
            :name="name"
            :options="options"
            @update:checked="valueChanged"
        />
    </div>
</template>

<script>
export default {
    name: 'JetRadioGroup',

    props: {
        label: String,
        name: {
            type: String,
            default: '',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        value: String,
        options: {
            type: Array,
            default: [],
        },
    },

    emits: ['update:modelValue'],

    computed: {
        isValid() {
            return this.invalidFeedback == '' ? null : false;
        },
    },

    methods: {
        valueChanged(value) {
            this.$emit('input', value);
        },
    },
};
</script>
