<template>
    <dropdown-with-action
        :horizontal="false"
        :invalidFeedback="invalidFeedback"
        :label="__('assets.details.field.manufacturer.label')"
        :options="[]"
        :placeholder="__('assets.details.field.manufacturer.placeholder')"
        :required="true"
        :routes="{
            store: route('manufacturers.store'),
            index: route('manufacturers.index'),
        }"
        :showAction="hasAnyPermission('manufacturer.create')"
        :value="value"
        @input="$emit('input', $event)"
    />
</template>
<script>
import DropdownWithAction from '@/Components/DropdownWithAction.vue';

export default {
    name: 'manufacturer-dropdown',
    components: { DropdownWithAction },
    props: {
        invalidFeedback: String,
        value: String,
    },
};
</script>
