<template>
    <dialog-modal :show="show" @close="$emit('cancel')">
        <template #title>{{ title }}</template>

        <template #content>
            <form @submit.prevent="handleSubmit">
                <CRow>
                    <CCol>
                        <div class="d-flex w-100">
                            <jet-input
                                v-model="form.name"
                                :autofocus="show"
                                :disabled="isLoading"
                                :invalidFeedback="form.errors.name"
                                :label="label"
                                :placeholder="label"
                                :required="true"
                                style="flex-grow: 1"
                            >
                            </jet-input>
                        </div>
                    </CCol>
                </CRow>
            </form>
        </template>

        <template #footer>
            <CLink
                class="btn btn-link btn-secondary mx-1"
                @click.stop="$emit('cancel')"
                >{{ __('general.action.cancel') }}
            </CLink>
            <jet-button
                :disabled="!isFormSubmittable || isLoading"
                color="primary"
                @click.stop="handleSubmit"
            >
                {{ __('general.action.create') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    components: { DialogModal, JetButton, JetInput },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        createRoute: String,
        title: String,
        label: String,
    },
    data() {
        return {
            isLoading: false,
            form: this.$inertia.form({
                name: null,
            }),
        };
    },

    watch: {
        show() {
            this.form.reset().clearErrors();
        },
    },

    methods: {
        handleSubmit(event) {
            event.preventDefault();

            this.isLoading = true;

            this.$http
                .post(this.createRoute, this.form.data())
                .then((response) => {
                    this.$emit('success', response.data);
                })
                .catch((error) => {
                    this.form.clearErrors();
                    let errors = {};

                    Object.keys(error.response.data.errors).map((key) => {
                        errors[key] = error.response.data.errors[key][0];
                    });

                    this.form.errors = errors;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
