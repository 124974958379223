<template>
    <CDropdown
        :disabled="disabled"
        :toggler-text="selectedUsageCategory?.label"
        add-menu-classes="p-0 overflow-hidden"
        color="primary"
    >
        <CDropdownItem
            v-for="usageCategory in usageCategories"
            :key="usageCategory.value"
            :class="{
                selected: value === usageCategory.value,
            }"
            class="pl-2"
            @click.stop="updateAssetUsageCategory(usageCategory)"
        >
            <c-icon
                :style="{
                    opacity: value === usageCategory.value ? 1 : 0,
                }"
                class="text-primary mr-2"
                name="cil-check"
            />
            {{ usageCategory.label }}
        </CDropdownItem>
    </CDropdown>
</template>
<script>
export default {
    name: 'usage-category-selection-dropdown',
    props: {
        disabled: { type: Boolean, default: false },
        value: { type: String },
        usageCategories: { type: Array, default: () => [] },
    },
    computed: {
        selectedUsageCategory() {
            return this.usageCategories.find(
                (category) => category.value === this.value
            );
        },
    },
    methods: {
        updateAssetUsageCategory(item) {
            if (item.value === this.value) {
                return;
            }

            this.$emit('input', item.value);
        },
    },
};
</script>
