<template>
    <div class="d-flex align-items-center">
        <div :is="priorityIcon" v-if="priority.value" />
        <div class="px-2">{{ priority.label }}</div>
    </div>
</template>
<script>
import Low from '@/../icons/Low.vue';
import Medium from '@/../icons/Medium.vue';
import High from '@/../icons/High.vue';

export default {
    name: 'work-order-priority',
    components: { Low, High, Medium },
    props: {
        priority: {
            type: Object,
        },
    },
    computed: {
        priorityIcon() {
            const { value } = this.priority;

            if (!value) {
                return null;
            }

            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
};
</script>
