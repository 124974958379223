<template>
    <div class="db-card-sm">
        <header class="d-flex justify-content-between align-items-start">
            <div>
                {{ __('dashboard.asset_management.total.title') }}
            </div>
            <info-icon
                :tooltip="__('dashboard.asset_management.total.tooltip')"
                style="margin-top: 4px"
            />
        </header>
        <div class="d-flex align-items-center mt-3" style="gap: 10px">
            <c-icon name="cil-layers" style="height: 33px; width: 33px" />
            <div style="font-size: 40px">
                {{ total }}
            </div>
        </div>
    </div>
</template>
<script>
import InfoIcon from '@/Components/InfoIcon.vue';

export default {
    name: 'AssetTotal',
    components: { InfoIcon },
    props: {
        total: {
            type: Number,
            default: 0,
        },
    },
};
</script>
