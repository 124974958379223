import * as Sentry from '@sentry/vue';
import Vue from 'vue';

export default function setupErrorReporting() {
    try {
        const props = JSON.parse(
            document.getElementById('app')?.dataset.page
        ).props;

        const userId = props?.auth?.user?.id;
        const dsn = props?.sentry.dsn;
        const release = props?.sentry.release;

        if (!dsn) {
            return;
        }

        if (userId) {
            Sentry.setUser({ id: userId });
        }

        Sentry.init({
            Vue,
            dsn,
            integrations: [Sentry.replayIntegration()],
            tracesSampleRate: 0.1,
            tracePropagationTargets: [],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            release: release,
        });
    } catch {}
}

