<template>
    <DialogModal :show.sync="show">
        <template #title>
            {{ __('qr_code_scanner.modal.header') }}
        </template>
        <template #subtitle>
            {{ __('qr_code_scanner.modal.description') }}
        </template>
        <template #content>
            <qr-code-scanner
                v-model="qrCode"
                @success="onSuccess"
                @failure="onError"
                :valid="isValidQrCode"
                @reset="isValidQrCode = undefined"
                ref="scanner"
                v-if="show"
            />
            <form
                @submit.prevent="onSuccess({ uuid: form.petitec_identifier })"
            >
                <CRow class="mt-3">
                    <CCol>
                        <jet-input
                            :label="
                                __(
                                    'assets.details.field.petitec_identifier.label'
                                )
                            "
                            :horizontal="false"
                            :required="true"
                            v-model="form.petitec_identifier"
                            :invalidFeedback="form.errors.petitec_identifier"
                            class="bg-append-primary"
                            :placeholder="
                                __(
                                    'assets.details.field.petitec_identifier.placeholder'
                                )
                            "
                        >
                            <template #append>
                                <CIcon name="logoSmall" />
                            </template>
                        </jet-input>
                    </CCol>
                </CRow>
            </form>
        </template>
        <template #footer>
            <div class="w-100 d-flex justify-content-center align-items-center">
                <jet-button color="secondary" @click="onClose" class="mx-2">{{
                    __('general.action.cancel')
                }}</jet-button>
                <jet-button
                    v-if="form.errors.petitec_identifier"
                    @click.prevent="resetForm"
                >
                    {{ __('general.action.retry') }}
                </jet-button>
                <jet-button
                    v-else
                    :disabled="!isFormSubmittable"
                    @click.prevent="
                        onSuccess({ uuid: form.petitec_identifier })
                    "
                >
                    {{ submitButtonLabel }}
                </jet-button>
            </div>
        </template>
    </DialogModal>
</template>
<script>
import QrCodeScanner from '@/Components/QrCodeScanner.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    props: ['pid', 'show', 'submitButtonLabel'],

    components: {
        QrCodeScanner,
        DialogModal,
        JetInput,
        JetButton,
    },

    data() {
        return {
            qrCode: null,
            isValidQrCode: undefined,
            form: this.$inertia.form({
                petitec_identifier: null,
            }),
        };
    },

    methods: {
        onSuccess(code) {
            this.form.petitec_identifier = code.uuid;
            this.$refs.scanner.turnCameraOff();
            this.$emit('success', code);
        },
        onError() {
            this.$emit('error');
        },
        onClose() {
            this.$emit('close');
        },
        resetForm() {
            this.form.reset();
            this.form.clearErrors();
            this.isValidQrCode = undefined;

            if (this.$refs.scanner) {
                this.$refs.scanner.turnCameraOn();
            }
        },
    },

    created() {
        this.$watch('show', (show) => {
            this.resetForm();
        });

        this.$watch('pid', (pid) => {
            this.form = this.$inertia.form({
                petitec_identifier: pid,
            });
        });
    },
};
</script>
