<template>
    <div>
        <CCard>
            <CCardHeader
                class="d-flex justify-content-between align-items-center"
                style="min-height: 50px"
            >
                <h5 class="mb-0">{{ __('maintainers.overview.title') }}</h5>
                <Link
                    v-if="hasAnyPermission('maintainer.create')"
                    class="btn btn-primary"
                    @click.prevent="createMaintainer"
                    >{{ __('maintainers.overview.action.add') }}
                </Link>
            </CCardHeader>
            <CCardBody>
                <total-table-items
                    :text="
                        __('maintainers.overview.results.count', {
                            total: maintainers?.meta.total || 0,
                        })
                    "
                />
                <sticky-table-header>
                    <CDataTable
                        :border="true"
                        :clickableRows="false"
                        :fields="fields"
                        :items="maintainers?.data || []"
                        :noItemsView="noItemsView"
                        :outlined="true"
                        :sorter="{ external: true, resetable: false }"
                        :sorter-value="sortOrder"
                        :striped="true"
                        hover
                        @update:sorter-value="updateSorting"
                    >
                        <template #sorting-icon="{ classes, state }">
                            <sort-icon :class="classes" :state="state" />
                        </template>
                        <template #name="{ item }">
                            <td class="align-top">
                                <div class="text-break">{{ item.name }}</div>
                                <div
                                    v-if="
                                        item.sla_expires_soon ||
                                        item.sla_expired
                                    "
                                    class="d-flex align-items-center text-danger text-nowrap"
                                    style="gap: 4px; font-size: 13px"
                                >
                                    <span
                                        class="badge badge-pill badge-danger flex-shrink-0"
                                        >!</span
                                    >
                                    {{ getExpirationInfo(item) }}
                                </div>
                                <jet-button
                                    v-c-tooltip="{
                                        content: 'API connection coming soon',
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    :disabled="true"
                                    class="mt-2"
                                    color="secondary"
                                    style="min-width: 0"
                                >
                                    Integrate
                                </jet-button>
                            </td>
                        </template>
                        <template #contact="{ item }">
                            <td class="align-top">
                                <div v-if="item.email || item.description">
                                    <div v-if="item.email">
                                        <email-link :email="item.email" />
                                    </div>
                                    <div v-if="item.phone">
                                        <phone-link :number="item.phone" />
                                    </div>
                                    <div v-if="item.description">
                                        {{ item.description }}
                                    </div>
                                </div>
                                <div v-else>
                                    {{ __('general.not_available') }}
                                </div>
                            </td>
                        </template>
                        <template #sla="{ item }">
                            <td class="align-middle">
                                <service-level-agreement-info
                                    :show-expiration-warning="
                                        item.sla_expires_soon ||
                                        item.sla_expired
                                    "
                                    :sla="item.sla"
                                />
                            </td>
                        </template>
                        <template #assets_count="{ item }">
                            <td class="align-top">
                                <div>{{ item.assets_count || 0 }}</div>
                            </td>
                        </template>
                        <template #locations_count="{ item }">
                            <td class="align-top">
                                <div>{{ item.locations_count || 0 }}</div>
                            </td>
                        </template>
                        <template #actions="{ item }">
                            <td class="align-top">
                                <div class="d-flex justify-content-end">
                                    <jet-button
                                        v-if="
                                            hasAnyPermission(
                                                'maintainer.update'
                                            )
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'maintainers.overview.tooltip.edit'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="primary"
                                        @click.stop="editMaintainer(item)"
                                    >
                                        <CIcon name="cil-pencil" />
                                    </jet-button>
                                    <jet-button
                                        v-if="
                                            hasAnyPermission(
                                                'maintainer.delete'
                                            )
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'maintainers.overview.tooltip.delete'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="danger"
                                        @click.stop="deleteMaintainer(item)"
                                    >
                                        <CIcon name="cil-trash" />
                                    </jet-button>
                                </div>
                            </td>
                        </template>
                    </CDataTable>
                </sticky-table-header>
                <Pagination
                    :only="['maintainers']"
                    :paginator="maintainers"
                    class="mt-2"
                />
            </CCardBody>
        </CCard>
        <update-or-create-maintainer-form-modal />
    </div>
</template>
<script>
import SortIcon from '@/Pages/Assets/SortIcon.vue';
import JetButton from '@/Jetstream/Button.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import Pagination from '@/Components/Pagination.vue';
import TotalTableItems from '@/Components/TotalTableItems.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import EmailLink from '@/Components/EmailLink.vue';
import UpdateOrCreateMaintainerFormModal from '@/Pages/Stakeholders/Maintainers/UpdateOrCreateMaintainerFormModal.vue';
import { eventBus, events } from '@/eventBus.js';
import PhoneLink from '@/Components/PhoneLink.vue';
import ServiceLevelAgreementForm from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementForm.vue';
import ServiceLevelAgreementInfo from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementInfo.vue';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';
import { DateTime } from 'luxon';

export default {
    name: 'MaintainersOverview',
    components: {
        DeleteStakeholderModal,
        ServiceLevelAgreementInfo,
        ServiceLevelAgreementForm,
        PhoneLink,
        UpdateOrCreateMaintainerFormModal,
        EmailLink,
        CustomCardHeader,
        TotalTableItems,
        Pagination,
        StickyTableHeader,
        JetButton,
        SortIcon,
    },
    data() {
        return {
            sortOrder: {},
            fields: [
                {
                    key: 'name',
                    label: this.__('maintainers.overview.cell.name'),
                },
                {
                    key: 'sla',
                    label: this.__('maintainers.overview.cell.sla'),
                    _style: 'white-space: nowrap;width:90px',
                },
                {
                    key: 'contact',
                    label: this.__('maintainers.overview.cell.contact'),
                    _style: 'white-space: nowrap;width:300px;min-width:300px',
                },
                {
                    key: 'assets_count',
                    label: this.__('maintainers.overview.cell.assets'),
                    _style: 'white-space: nowrap;width:90px;',
                },
                {
                    key: 'locations_count',
                    label: this.__('maintainers.overview.cell.locations'),
                    _style: 'white-space: nowrap;width:90px;',
                },
                {
                    key: 'actions',
                    label: '',
                    sorter: false,
                    filter: false,
                    _style: 'white-space: nowrap;width:90px',
                },
            ],
            noItemsView: {
                noResults: this.__('maintainers.overview.filters.empty'),
                noItems: this.__('maintainers.overview.results.empty'),
            },
        };
    },
    computed: {
        maintainers() {
            return this.$page.props.maintainers;
        },
    },
    mounted() {
        let [column, order] = this.$page.props.maintainers_sort.split('.');
        this.sortOrder = {
            column,
            asc: order === 'asc',
        };
    },
    methods: {
        createMaintainer() {
            eventBus.$emit(events.openCreateMaintainerModal);
        },
        editMaintainer(item) {
            eventBus.$emit(events.openEditMaintainerModal, {
                maintainer: item,
            });
        },
        deleteMaintainer(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'maintainer',
            });
        },
        updateSorting(event) {
            let sortOrder = event.asc ? 'asc' : 'desc';
            this.$inertia.visit(
                this.route(this.route().current(), {
                    maintainers_sort: `${event.column}.${sortOrder}`,
                }),
                {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['maintainers'],
                }
            );
        },
        getExpirationInfo(maintainer) {
            if (!maintainer.sla || !maintainer.sla.agreement) {
                return null;
            }
            let date = DateTime.fromFormat(
                maintainer.sla.agreement.endDate,
                'y-MM-dd'
            );

            return date.diffNow('days').days < 0
                ? this.__('maintainers.overview.sla_expired')
                : this.__('maintainers.overview.sla_expires_soon');
        },
    },
};
</script>
