<template>
    <CBadge :color="color">{{ role.localizedName.toUpperCase() }}</CBadge>
</template>
<script>
export default {
    props: ['role'],

    computed: {
        color() {
            switch (this.role.name) {
                case 'super-admin':
                    return 'warning';
                case 'admin':
                    return 'primary';
                default:
                    return 'secondary';
            }
        },
    },
};
</script>
