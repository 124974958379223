<template>
    <CInput
        ref="input"
        :add-label-classes="{ required: required, 'color-text': true }"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :description="description"
        :horizontal="horizontal"
        :invalidFeedback="invalidFeedback"
        :isValid="isValid"
        :label="label"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
        :type="type"
        :value="value"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
        @focus="$emit('focus', $event)"
        @input="valueChanged"
        @keyup="$emit('keyup', $event)"
    >
        <template #label>
            <span v-if="info" class="d-flex align-items-center">
                <label :class="{ required: required }">{{ label }} </label>
                <info-icon :tooltip="info" class="mx-2 mb-2" />
            </span>
            <slot v-else name="label" />
        </template>

        <template #prepend-content>
            <slot name="prepend" />
        </template>

        <template #append-content>
            <slot name="append" />
        </template>
    </CInput>
</template>

<script>
import InfoIcon from '@/Components/InfoIcon.vue';

export default {
    name: 'JetInput',
    components: { InfoIcon },

    props: {
        label: String,
        info: String,
        type: {
            type: String,
            default: 'text',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        autocomplete: String,
        inputmode: String,
        value: String,
        horizontal: {
            default: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:modelValue'],

    computed: {
        isValid() {
            return this.invalidFeedback === '' ? null : false;
        },
    },

    mounted() {},

    watch: {
        autofocus: function (value) {
            if (value) {
                this.$refs.input.$el.getElementsByTagName('input')[0].focus();
            }
        },
    },

    methods: {
        focus() {
            this.$refs.input.$el.focus();
        },
        valueChanged(value) {
            this.$emit('input', value);
        },
    },
};
</script>
