<template>
    <CFooter
        :fixed="false"
        class="d-flex justify-content-end align-items-center small"
    >
        <div v-if="termsOfUseLink" class="px-4">
            <CLink :href="termsOfUseLink">{{
                __('general.terms_of_use')
            }}</CLink>
        </div>
        <span v-if="termsOfUseLink" class="text-muted">|</span>
        <div class="px-4">
            &copy; {{ new Date().getFullYear() }}
            Curie Technologies
        </div>
    </CFooter>
</template>

<script>
export default {
    name: 'TheFooter',
    computed: {
        termsOfUseLink() {
            return this.$page.props.termsOfUseLink;
        },
    },
};
</script>
