<template>
    <svg class="sort-order mx-2 flex-shrink-0 flex-grow-0"
         :class="`sort-order-${state}`"
         width="14px"
         height="8px"
         viewBox="0 0 14 8"
         xmlns="http://www.w3.org/2000/svg"
         v-c-tooltip="{
            content: tooltip,
            popperOptions: {
                positionFixed: true
            }
         }"
    >
        <g transform="translate(-620, -292)" fill="#ccc"
           stroke="#ccc">
            <g transform="translate(281, 191)">
                <path d="M346,101.199609 L351.040312,107.5 L340.959688,107.5 L346,101.199609 Z"
                      transform="translate(346, 104.5) scale(1, -1) translate(-346, -104.5)"/>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'sort-icon',
    props: ['state'],
    computed: {
        tooltip() {
            let key = this.state === 0 ? 'none' : this.state;
            return this.__(`general.sortorder.${key}`);
        }
    }
}
</script>
