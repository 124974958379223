<template>
    <app-layout>
        <CRow>
            <CCol md="4">
                <CCard>
                    <CCardBody>
                        <div class="d-flex justify-content-center">
                            <CIcon
                                class="profile-placeholder mt-3 mx-5 mb-4"
                                name="cil-user"
                            />
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <h4 class="mb-0">{{ user.name }}</h4>
                            <div>
                                <RoleBadge
                                    v-for="role in user.roles"
                                    :key="role.id"
                                    :role="role"
                                />
                            </div>
                        </div>
                        <a :href="getMailLink(user.email)" target="_blank">
                            <small class="text-muted">
                                {{ user.email }}
                            </small>
                        </a>
                        <div class="mt-5">
                            <h5>{{ __('users.show.airports.header') }}</h5>
                            <ul v-if="airports" class="list-unstyled">
                                <li
                                    v-for="airport in airports"
                                    :key="airport.id"
                                    class="d-flex justify-content-start align-items-start mb-2"
                                >
                                    <div>
                                        <CIcon
                                            v-if="airport.country_code_alpha2"
                                            v-c-tooltip="{
                                                content:
                                                    airport.country_code_alpha2,
                                                popperOptions: {
                                                    positionFixed: true,
                                                },
                                            }"
                                            :name="
                                                'cif-' +
                                                airport.country_code_alpha2.toLowerCase()
                                            "
                                            size="xl"
                                        />
                                    </div>
                                    <div class="mx-2">{{ airport.name }}</div>
                                    <div class="text-muted">
                                        ({{ airport.icao_code }})
                                    </div>
                                </li>
                            </ul>
                            <div v-else>
                                {{ __('users.show.airports.empty') }}
                            </div>
                        </div>
                    </CCardBody>
                    <CCardFooter
                        v-if="hasAnyPermission(['user.index', 'user.edit'])"
                        class="d-flex justify-content-center"
                    >
                        <CLink
                            v-if="hasAnyPermission(['user.index'])"
                            :href="route('users.index')"
                            class="btn btn-link text-muted"
                            >{{ __('general.action.cancel') }}
                        </CLink>
                        <CLink
                            v-if="hasAnyPermission(['user.edit'])"
                            :href="route('users.edit', user.id)"
                            class="btn btn-primary"
                            >{{ __('general.action.edit') }}
                        </CLink>
                    </CCardFooter>
                </CCard>
            </CCol>
            <CCol md="8">
                <ActivityLog
                    v-if="hasAnyPermission(['user.activity.read'])"
                    :activities="activities"
                    :fields="['date', 'event']"
                    context="user"
                />
            </CCol>
        </CRow>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import RoleBadge from '@/Components/RoleBadge.vue';
import Pagination from '@/Components/Pagination.vue';
import ActivityLog from '@/Components/ActivityLog/ActivityLog.vue';

export default {
    components: { AppLayout, ActivityLog, Pagination, RoleBadge },

    data() {
        return {
            changes: null,
        };
    },

    computed: {
        user() {
            return this.$page.props.user;
        },
        activities() {
            return this.$page.props.activities;
        },
        airports() {
            return this.user.airports;
        },
    },

    methods: {
        getMailLink(email) {
            return 'mailto:' + email;
        },
    },
};
</script>
<style scoped>
.profile-placeholder {
    display: block;
    width: 50% !important;
    height: auto !important;
}
</style>
