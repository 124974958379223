<template>
    <auth-layout>
        <CContainer fluid>
            <CRow>
                <c-col>
                    <FlashMessage class="position-absolute" style="left: 15px; right: 15px; top: 80px;"/>
                </c-col>
            </CRow>
        </CContainer>
        <CContainer
            class="align-items-center min-vh-100"
            style="overflow: hidden"
        >
            <CRow class="min-vh-100 justify-content-center align-items-center">
                <CCol md="6">
                    <CCard class="p-4 m-0">
                        <CCardBody>
                            <h1>
                                {{ __('auth.reset_password.expired.header') }}
                            </h1>
                            <p class="text-muted">
                                {{
                                    __(
                                        'auth.reset_password.expired.description'
                                    )
                                }}
                            </p>
                            <div class="d-flex justify-content-end">
                                <jet-button
                                    @click.stop="resendLink"
                                    type="submit"
                                    color="primary"
                                    :is-processing="form.processing"
                                    class="px-4"
                                >{{
                                        __(
                                            'auth.invitation.expired.action.title'
                                        )
                                    }}
                                </jet-button>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import FlashMessage from '@/Components/FlashMessage.vue';

export default {
    components: {
        FlashMessage,
        JetButton,
        AuthLayout,
    },
    data() {
        return {
            form: this.$inertia.form({
                token: this.$page.props.token
            }),
        };
    },
    methods: {
        resendLink() {
            this.form.get(this.route('invitation.request-link', {token: this.form.token}));
        },
    },
};
</script>
