<template>
    <div>
        <CRow class="mb-3">
            <CCol>
                <div class="d-flex align-items-center">
                    <h2 class="mb-0">
                        {{ asset.name }}
                    </h2>
                </div>
            </CCol>
        </CRow>
        <CRow>
            <CCol class="d-md-flex">
                <TooltipWrapper
                    v-if="
                        hasFeature('asset-status-check') &&
                        hasAnyPermission('asset.status_check.create')
                    "
                    :enabled="isOperationInProgress || !isAssetOnboarded"
                    :tooltip="
                        __('asset.action_disabled_due_to_incomplete_onboarding')
                    "
                >
                    <jet-button
                        :disabled="isOperationInProgress || !isAssetOnboarded"
                        class="text-nowrap sm-full-width mb-3 mr-2"
                        color="primary"
                        @click="openCheckAssetStatus"
                        >{{ __('assets.details.action.check_asset_status') }}
                    </jet-button>
                </TooltipWrapper>
                <TooltipWrapper
                    v-if="
                        hasFeature('test-record') &&
                        hasAnyPermission('test.create')
                    "
                    :enabled="isOperationInProgress || !isAssetOnboarded"
                    :tooltip="
                        __('asset.action_disabled_due_to_incomplete_onboarding')
                    "
                >
                    <jet-button
                        :disabled="isOperationInProgress || !isAssetOnboarded"
                        class="text-nowrap sm-full-width mb-3 mr-2"
                        color="primary"
                        @click="openTestRecordModal"
                        >{{ __('assets.details.action.calibrate_asset') }}
                    </jet-button>
                </TooltipWrapper>
                <TooltipWrapper
                    v-if="
                        hasFeature('asset-work-order-management') &&
                        hasAnyPermission('workorder.create')
                    "
                    :enabled="isOperationInProgress || !isAssetOnboarded"
                    :tooltip="
                        __('asset.action_disabled_due_to_incomplete_onboarding')
                    "
                >
                    <jet-button
                        :disabled="isOperationInProgress || !isAssetOnboarded"
                        class="text-nowrap sm-full-width mb-3 mr-2"
                        type="button"
                        @click="openWorkOrderModal"
                    >
                        {{ __('workorder.overview.action.create') }}
                    </jet-button>
                </TooltipWrapper>
                <TooltipWrapper
                    v-if="
                        hasFeature('performance-data') &&
                        hasAnyPermission('performance_data.create')
                    "
                    :enabled="!isAssetOnboarded"
                    :tooltip="
                        __('asset.action_disabled_due_to_incomplete_onboarding')
                    "
                >
                    <jet-button
                        :disabled="isOperationInProgress || !isAssetOnboarded"
                        class="text-nowrap sm-full-width mb-3 mr-2"
                        type="button"
                        @click="openPerformanceDataModal"
                    >
                        Capture Performance
                    </jet-button>
                </TooltipWrapper>
            </CCol>
        </CRow>
        <ul class="nav nav-tabs">
            <li v-for="tab in tabs" :key="tab.route" class="nav-item">
                <a
                    :class="{
                        active: isActiveRoute(tab.route),
                    }"
                    :href="route(tab.route, asset)"
                    class="nav-link"
                    target="_self"
                    @click.prevent="openTab(tab.route)"
                >
                    <div class="d-flex align-items-center">
                        <div
                            :is="tab.component"
                            :name="tab.icon"
                            class="d-none d-md-block mx-1"
                        />
                        <span class="text-truncate d-block">{{
                            tab.label
                        }}</span>
                        <span
                            v-if="tab.badge"
                            class="badge badge-pill badge-danger mx-1"
                            >!</span
                        >
                    </div>
                </a>
            </li>
        </ul>
        <create-test-record-modal
            :asset="asset"
            @created="onTestRecordCreated"
        />
        <check-asset-status-form
            :asset-id="asset.id"
            @performed="$emit('status-check-performed')"
        />
        <create-work-order-modal
            v-if="
                hasFeature('asset-work-order-management') &&
                hasAnyPermission('workorder.create')
            "
            :asset-id="asset.id"
            :forced-work-order-type="forcedWorkOrderType"
            :show="showCreateWorkOrderModal"
            @close="onCreateWorkOrderModalClose"
            @created="$emit('workorder-created')"
        />
        <create-corrective-maintenance-modal
            :cancel-button-text="
                __(
                    'asset.workorders.modals.confirm_workorder_creation.action.cancel'
                )
            "
            :primary-button-text="
                __(
                    'asset.workorders.modals.confirm_workorder_creation.action.confirm'
                )
            "
            :show="showCreateWorkOrderConfirmationModal"
            :title="
                __('asset.workorders.modals.confirm_workorder_creation.title')
            "
            @close="showCreateWorkOrderConfirmationModal = false"
            @confirm="createCorrectiveMaintenanceWorkOrder"
        >
            {{
                __('asset.workorders.modals.confirm_workorder_creation.content')
            }}
        </create-corrective-maintenance-modal>
        <create-performance-data-modal />
    </div>
</template>
<script>
import CalibrationRecordForm from '@/Pages/Assets/Modals/CreateTestRecordModal.vue';
import CreateTestRecordModal from '@/Pages/Assets/Modals/CreateTestRecordModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import CheckAssetStatusForm from '@/Components/AssetStatusCheckModal.vue';
import TooltipWrapper from '@/Pages/Assets/Details/TooltipWrapper.vue';
import CreateWorkOrderModal from '@/Pages/WorkOrders/Modals/CreateWorkOrderModal.vue';
import Wrench from '../../../../icons/Wrench.vue';
import CreateCorrectiveMaintenanceModal from '@/Pages/Assets/Modals/ConfirmationStepModal.vue';
import { eventBus, events } from '@/eventBus';
import CreatePerformanceDataModal from '@/Pages/Assets/Details/PerformanceData/CreatePerformanceDataModal.vue';

export default {
    props: ['asset', 'activeTab'],

    emits: ['status-check-performed'],

    components: {
        CreateTestRecordModal,
        CreatePerformanceDataModal,
        CreateCorrectiveMaintenanceModal,
        CreateWorkOrderModal,
        TooltipWrapper,
        CalibrationRecordForm,
        JetButton,
        CheckAssetStatusForm,
        Wrench,
    },

    data() {
        return {
            showCreateWorkOrderModal: false,
            showCreateWorkOrderConfirmationModal: false,
            forcedWorkOrderType: null,
            isOperationInProgress: false,
            updatingStatus: null,
            allTabs: [
                {
                    route: 'assets.show',
                    icon: 'cil-info',
                    component: 'c-icon',
                    label: this.__('assets.details.tab.details'),
                    permissions: ['asset.read'],
                },
                {
                    route: 'assets.tests.index',
                    icon: 'cil-clipboard',
                    component: 'c-icon',
                    label: this.__('assets.details.tab.test_records'),
                    features: ['testRecord'],
                    permissions: ['test.index'],
                    badge: false,
                },
                {
                    route: 'assets.workorders.index',
                    icon: 'cil-view-column',
                    component: 'wrench',
                    label: this.__('assets.details.tab.workorders'),
                    permissions: ['workorder.index'],
                    features: ['assetWorkOrderManagement'],
                    badge: false,
                },
                {
                    route: 'assets.activities.index',
                    icon: 'cil-check',
                    component: 'c-icon',
                    label: this.__('assets.details.tab.activity_log'),
                    permissions: ['asset.activities.index'],
                },
                {
                    route: 'assets.performance.index',
                    icon: 'cil-chart-line',
                    component: 'c-icon',
                    label: 'Performance',
                    features: ['performanceData'],
                    permissions: ['asset.performance-data.index'],
                },
            ],
        };
    },

    computed: {
        tabs() {
            return this.allTabs.filter((tab) => {
                let hasFeature = tab.features
                    ? this.hasFeature(tab.features)
                    : true;
                return this.hasAnyPermission(tab.permissions) && hasFeature;
            });
        },
        hasTestIssues() {
            return this.asset.tests.issues.length > 0;
        },
        hasMaintenanceIssues() {
            return this.asset.maintenances.issues.length > 0;
        },
        isAssetOnboarded() {
            return !!this.asset.petitec_identifier;
        },
    },

    methods: {
        isActiveRoute(route) {
            if (this.activeTab) {
                return this.activeTab === route;
            }

            const currentRoute = this.route().current();

            if (route.endsWith('.index') && currentRoute.endsWith('.show')) {
                return route === currentRoute.replace('.show', '.index');
            }

            return currentRoute === route;
        },
        openTab(route) {
            this.$inertia.visit(this.route(route, this.asset));
        },
        openCheckAssetStatus() {
            eventBus.$emit(events.openCreateStatusCheckModal);
        },
        openTestRecordModal() {
            eventBus.$emit(events.openTestRecordsModal);
        },
        openWorkOrderModal() {
            this.showCreateWorkOrderModal = true;
        },
        openPerformanceDataModal() {
            eventBus.$emit(events.openCreatePerformanceDataModal);
        },
        onTestRecordCreated() {
            this.updatedBadges();
        },
        onCreateWorkOrderModalClose() {
            this.showCreateWorkOrderModal = false;
            this.forcedWorkOrderType = null;
        },
        updatedBadges() {
            // Test records badge
            this.allTabs[1].badge = this.hasTestIssues;

            // Maintenance Schedule badge
            this.allTabs[2].badge = this.hasMaintenanceIssues;
        },
        createCorrectiveMaintenanceWorkOrder() {
            this.showCreateWorkOrderConfirmationModal = false;
            this.forcedWorkOrderType = 'corrective_maintenance';

            setTimeout(() => {
                this.openWorkOrderModal();
            }, 150);
        },
    },

    mounted() {
        this.updatedBadges();

        if (this.route().params['check-status'] === '1') {
            this.openCheckAssetStatus();
        }

        eventBus.$on(events.assetOperabilityUpdated, (data) => {
            setTimeout(() => {
                this.showCreateWorkOrderConfirmationModal =
                    data.operability === 'inoperable' &&
                    data.reason === 'corrective_maintenance_required';
            }, 300);
        });
    },

    beforeDestroy() {
        eventBus.$off(events.assetOperabilityUpdated);
    },
};
</script>
sty
