<template>
    <div>
        <sticky-table-header>
            <CDataTable
                :border="true"
                :clickableRows="false"
                :fields="fields"
                :items="performanceData?.data"
                :noItemsView="noItemsView"
                :outlined="true"
                :striped="true"
                hover
            >
                <template #sorting-icon="{ classes, state }">
                    <sort-icon :class="classes" :state="state"/>
                </template>
                <template #date="{ item }">
                    <td class="align-middle">
                        <div class="text-muted">
                            <div class="text-nowrap">
                                {{ item.createdAt | fullDate(currentLocale) }}
                            </div>
                            <small>
                                <CIcon name="cil-clock"/>
                                {{
                                    item.createdAt | time(currentLocale)
                                }}</small
                            >
                        </div>
                    </td>
                </template>

                <template #user="{ item }">
                    <td class="align-middle">
                        <user-information v-if="item.user" :info="item.user"/>
                        <span v-else>-</span>
                    </td>
                </template>

                <template #type="{ item }">
                    <td class="align-middle">
                        {{ item.type.label }}
                    </td>
                </template>

                <template #value="{ item }">
                    <td class="align-middle">
                        {{ item.value }}
                    </td>
                </template>

                <template #attachments="{ item }">
                    <td class="align-middle">
                        <FileList
                            :attachments="item.attachments"
                            :can-read="
                                hasAnyPermission(['test.attachment.index'])
                            "
                            :inline="true"
                            :readonly="true"
                        />
                    </td>
                </template>

                <template #actions="{ item }">
                    <td class="align-middle">
                        <div
                            class="d-flex justify-content-end"
                            style="gap: 5px"
                        >
                            <jet-button
                                v-if="hasAnyPermission('performance_data.edit')"
                                @click.stop="editPerformanceData(item)"
                                class="btn-icon"
                                v-c-tooltip="{
                                    content: __('general.action.edit'),
                                    popperOptions: {
                                        positionFixed: true,
                                    },
                                }"
                            >
                                <span
                                ><c-icon name="cil-pencil" size="sm"
                                /></span>
                            </jet-button>
                            <jet-button
                                v-if="
                                    hasAnyPermission('performance_data.delete')
                                "
                                @click.stop="recordToDelete = item"
                                color="danger"
                                class="btn-icon"
                                v-c-tooltip="{
                                    content: __('general.action.delete'),
                                    popperOptions: {
                                        positionFixed: true,
                                    },
                                }"
                            >
                                <span
                                ><c-icon name="cil-trash" size="sm"
                                /></span>
                            </jet-button>
                        </div>
                    </td>
                </template>
            </CDataTable>
        </sticky-table-header>
        <jet-confirmation-modal
            :show="!!recordToDelete"
            @close="recordToDelete = null"
            type="danger"
        >
            <template #title> Delete Performance Data Record</template>

            <template #content>
                Do you really want to delete this record?
            </template>

            <template #footer>
                <slot name="footer">
                    <jet-button
                        color="danger"
                        @click.stop="deletePerformanceData(recordToDelete)"
                        :isProcessing="isDeleting"
                    >
                        {{ __('general.action.delete') }}
                    </jet-button>
                </slot>
            </template>
        </jet-confirmation-modal>
    </div>
</template>
<script>
import UserInformation from '@/Components/UserInformation.vue';
import JetButton from '@/Jetstream/Button.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import FileList from '@/Components/FileList.vue';
import Pagination from '@/Components/Pagination.vue';
import {eventBus} from '@/eventBus.js';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import SortIcon from "@/Pages/Assets/SortIcon.vue";

export default {
    name: 'PerformanceDataTable',
    components: {
        SortIcon,
        JetConfirmationModal,
        Pagination,
        FileList,
        StickyTableHeader,
        JetButton,
        UserInformation,
    },
    data() {
        return {
            recordToDelete: null,
            recordToEdit: null,
            isDeleting: false,
            fields: [
                {
                    key: 'date',
                    label: 'Date',
                },
                {
                    key: 'user',
                    label: 'User',
                },
                {
                    key: 'type',
                    label: 'Performance Data',
                },
                {
                    key: 'value',
                    label: 'Value',
                },
                {
                    key: 'attachments',
                    label: 'Attachments',
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            noItemsView: {
                noItems: 'No data available',
            },
        };
    },
    computed: {
        eventBus() {
            return eventBus;
        },
        performanceData() {
            return this.$page.props.performanceData;
        },
        asset() {
            return this.$page.props.asset;
        },
    },
    methods: {
        editPerformanceData(performanceData) {
            eventBus.$emit('edit-performance-data-item', performanceData);
        },
        deletePerformanceData(performanceData) {
            if (this.isDeleting) {
                return;
            }

            this.isDeleting = true;

            this.$inertia.delete(
                route('assets.performance.destroy', {
                    performance: performanceData.id,
                    asset: this.asset.id,
                }),
                {
                    onFinish: () => {
                        this.isDeleting = false;
                        this.recordToDelete = null;
                    },
                }
            );
        },
    },
};
</script>
