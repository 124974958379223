<template>
    <CModal
        :closeOnBackdrop="closeable"
        :scrollable="scrollable"
        :show="show"
        :size="maxWidth"
        @update:show="close"
        @keydown.esc="close"
    >
        <template #header-wrapper>
            <slot name="header-wrapper">
                <header
                    class="d-flex align-items-center justify-content-between modal-header bg-blue-light"
                >
                    <div>
                        <h5 class="modal-title">
                            <slot name="title"></slot>
                        </h5>

                        <div>
                            <slot name="subtitle"></slot>
                        </div>
                    </div>
                    <div v-if="showHeaderCloseButton && show">
                        <CLink @click.stop="close">
                            <CIcon name="cil-x" size="lg" />
                        </CLink>
                    </div>
                </header>
            </slot>
        </template>
        <template>
            <slot name="content"></slot>
        </template>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </CModal>
</template>

<script>
import Modal from '@/Jetstream/Modal.vue';

export default {
    name: 'JetDialogModal',

    emits: ['close'],

    components: {
        Modal,
    },

    props: {
        show: {
            default: false,
        },
        maxWidth: {
            default: '',
        },
        closeable: {
            default: true,
        },
        showHeaderCloseButton: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
<style scoped>
>>> .modal-content {
    overflow: visible;
}
</style>
