<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasFeature('performance-data') &&
            hasAnyPermission('performance_data.create')
        "
        :closeable="false"
        :show="show"
        @close="close"
    >
        <template #title>Capture Performance Data</template>

        <template #content>
            <form @submit.prevent="submitForm">
                <jet-select
                    label="Performance Data"
                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7' }"
                    v-model="form.type"
                    :options="types"
                    :invalidFeedback="form.errors.type"
                    :required="true"
                    :readonly="!!performanceDataId"
                    :placeholder="__('general.form.select.placeholder')"
                />
                <jet-input
                    label-classes="col-sm-5 required"
                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7' }"
                    field-classes="col-sm-7"
                    class="mb-5"
                    label="Value"
                    v-model="form.value"
                    :invalidFeedback="form.errors.value"
                    :required="true"
                />

                <h5>Attachment</h5>
                <p>
                    Upload files by dragging them onto the area below or click
                    the "Browse" button to select them.
                </p>
                <file-upload
                    v-model="form.attachments"
                    @upload-started="uploadInProgress = true"
                    @upload-completed="uploadInProgress = false"
                />
            </form>
        </template>

        <template #footer>
            <jet-button
                color="secondary"
                @click.stop="close"
                :disabled="form.processing || uploadInProgress"
            >
                {{ __('general.action.cancel') }}
            </jet-button>
            <jet-button
                color="primary"
                @click.stop="submitForm"
                :disabled="!form.isDirty"
                :is-processing="form.processing || uploadInProgress"
            >
                {{ __('general.action.save') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';

export default {
    name: 'CreatePerformanceDataModal',
    components: {
        FileUpload,
        DialogModal,
        JetButton,
        JetInput,
        JetSelect,
    },
    data() {
        return {
            shouldRender: false,
            show: false,
            performanceDataId: null,
            uploadInProgress: false,
            form: this.$inertia.form({
                type: null,
                value: null,
                attachments: [],
            }),
            types: [],
        };
    },
    mounted() {
        eventBus.$on(
            [
                events.openCreatePerformanceDataModal,
                events.editPerformanceDataItem,
            ],
            (data) => {
                this.form.reset();
                this.shouldRender = true;
                this.uploadInProgress = false;
                this.loadData(data);
                this.$nextTick(() => {
                    this.show = true;
                });
            }
        );
    },
    destroyed() {
        eventBus.$off([
            events.openCreatePerformanceDataModal,
            events.editPerformanceDataItem,
        ]);
    },
    computed: {
        asset() {
            return this.$page.props.asset;
        },
    },
    methods: {
        loadData(data) {
            this.isLoading = true;
            this.performanceDataId = data?.id || null;

            let route = data?.id
                ? this.route('assets.performance.edit', {
                      asset: this.asset,
                      performance: data.id,
                  })
                : this.route('assets.performance.create', {
                      asset: this.asset,
                  });

            this.$http
                .get(route)
                .then((response) => {
                    this.types = response.data.types;
                    this.form.type =
                        response.data.performanceData?.type.value || null;
                    this.form.value =
                        response.data.performanceData?.value.toString() || null;
                    this.form.attachments = [];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        submitForm() {
            let route = this.performanceDataId
                ? this.route('assets.performance.update', {
                      asset: this.asset,
                      performance: this.performanceDataId,
                  })
                : this.route('assets.performance.store', {
                      asset: this.asset,
                  });

            this.form
                .transform((data) => {
                    if (this.performanceDataId) {
                        data._method = 'PUT';
                    }
                    return data;
                })
                .post(route, {
                    onSuccess: () => {
                        this.close();
                    },
                });
        },
        close() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
    },
};
</script>
