<template>
    <dialog-modal :show="show">
        <template #title
            >{{ __('locations.modal.add_specific_location.title') }}
        </template>

        <template #content>
            <form @submit.prevent="handleSubmit">
                <CRow>
                    <CCol>
                        <div class="d-flex w-100">
                            <jet-input
                                v-model="form.name"
                                :invalidFeedback="form.errors.name"
                                :label="
                                    __(
                                        'locations.field.specific_location.label'
                                    )
                                "
                                :placeholder="
                                    __(
                                        'locations.field.specific_location.label'
                                    )
                                "
                                :required="true"
                                style="flex-grow: 1"
                            >
                            </jet-input>
                        </div>
                    </CCol>
                </CRow>
            </form>
        </template>

        <template #footer>
            <CLink
                class="btn btn-link btn-secondary mx-1"
                @click.stop="$emit('cancel')"
                >{{ __('general.action.cancel') }}
            </CLink>
            <jet-button
                :disabled="!isFormSubmittable"
                color="primary"
                @click.stop="handleSubmit"
            >
                {{ __('general.action.create') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';

export default {
    components: { DialogModal, JetButton, JetInput, JetSelect },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        physical_location_id: {
            type: String,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                name: null,
            }),
        };
    },

    watch: {
        show() {
            this.form.clearErrors();
            this.form.name = null;
        },
    },

    methods: {
        handleSubmit() {
            this.$http
                .post(
                    route(
                        'airports.specific-locations.store',
                        this.physical_location_id
                    ),
                    Object.assign({}, this.form.data())
                )
                .then((response) => {
                    this.$emit('success', response.data);
                })
                .catch((error) => {
                    this.form.clearErrors();
                    let errors = {};
                    Object.keys(error.response.data.errors).map((key) => {
                        errors[key] = error.response.data.errors[key][0];
                    });

                    this.form.errors = errors;
                });
        },
    },
};
</script>
