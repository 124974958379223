import Echo from 'laravel-echo';

import Vue from 'vue';

import Pusher from 'pusher-js';

window.Pusher = Pusher;

export function setupEcho(config) {
    if (!config) {
        throw new Error('Missing websocket config');
    }

    // Exit early if setup already done
    if (!!Vue.prototype.$echo) {
        return;
    }

    Vue.prototype.$echo = new Echo({
        broadcaster: 'reverb',
        key: config.key,
        wsHost: config.host,
        wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
        wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
        forceTLS: (config.scheme ?? 'https') === 'https',
        enabledTransports: ['ws', 'wss'],
        auth: {
            headers: {
                Accept: 'application/json',
            },
        },
        authorizer: (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    Vue.prototype.$http
                        .post('/api/broadcasting/auth', {
                            socket_id: socketId,
                            channel_name: channel.name,
                        })
                        .then((response) => {
                            callback(false, response.data);
                        })
                        .catch((error) => {
                            callback(true, error.response);
                        });
                },
            };
        },
    });
}

export function subscribe(channel, callback, config) {
    if (!channel || !callback) {
        return;
    }

    // Ensure Echo has been configured
    if (!Vue.prototype.$echo) {
        setupEcho(config);
    }

    // Store subscription to re-subscribe when connection has been closed
    Vue.prototype.$subsriptions = Vue.prototype.$subsriptions || {};

    if (!Vue.prototype.$subsriptions[channel]) {
        Vue.prototype.$subsriptions[channel] = callback
    }

    Vue.prototype.$echo
        .private(channel)
        .notification((notification) => {
            callback(notification);
        });
}

// Reconnect when window regains focus
window.addEventListener('focus', function() {
    Vue.prototype.$echo?.connect()

    if (Vue.prototype.$subsriptions) {
        Object.keys(Vue.prototype.$subsriptions).forEach((channel) => {
            subscribe(channel, Vue.prototype.$subsriptions[channel])
        })
    }
})

// Close connection when window loses focus
window.addEventListener('blur', function() {
    Vue.prototype.$echo?.disconnect()
});
