<template>
    <DialogModal
        v-if="
            shouldRender &&
            hasFeature('asset-status-check') &&
            hasAnyPermission('asset.status_check.create')
        "
        :scrollable="showReasonAndAdditionalInformationSection"
        :show="show"
    >
        <template #title>
            {{ __('check_asset_status.modal.header') }}
        </template>
        <template #content>
            <div v-if="isLoading" class="d-flex justify-content-center mt-4">
                <CSpinner color="primary" size="md" />
            </div>
            <div v-else>
                <form @submit.prevent="submitForm">
                    <CRow>
                        <CCol>
                            <div class="form-group form-row" role="form-group">
                                <label class="col-md-6 font-weight-bold">{{
                                    __(
                                        'check_asset_status.modal.operability.label'
                                    )
                                }}</label>
                                <div class="col-md-6">
                                    <AssetOperabilitySwitch
                                        v-model="form.operability"
                                    />
                                </div>
                            </div>
                            <div class="form-group form-row">
                                <label class="col-md-6 font-weight-bold">{{
                                    __(
                                        'check_asset_status.modal.usage_category.label'
                                    )
                                }}</label>
                                <div class="col-md-6">
                                    <usage-category-selection-dropdown
                                        :disabled="isOperationInProgress"
                                        :usage-categories="usageCategories"
                                        :value="form.usageCategory"
                                        @input="form.usageCategory = $event"
                                    />
                                </div>
                            </div>

                            <div
                                v-if="showReasonAndAdditionalInformationSection"
                            >
                                <div class="form-group">
                                    <label class="font-weight-bold">{{
                                        __(
                                            'check_asset_status.modal.reason_for_status_change.label'
                                        )
                                    }}</label>
                                    <CInputRadioGroup
                                        :checked.sync="form.reason"
                                        :invalidFeedback="form.errors.reason"
                                        :options="reasons"
                                    />
                                </div>
                                <jet-text-area
                                    v-model="form.additionalInformation"
                                    :horizontal="false"
                                    :invalidFeedback="
                                        form.errors.additionalInformation
                                    "
                                    :label="
                                        __(
                                            'check_asset_status.modal.additional_information.label'
                                        )
                                    "
                                    :labelClasses="{ 'font-weight-bold': true }"
                                    :maxlength="80"
                                    :placeholder="
                                        __(
                                            'check_asset_status.modal.additional_information.placeholder'
                                        )
                                    "
                                    :rows="4"
                                    :scrollIntoView="true"
                                    labelC
                                />
                            </div>
                            <div v-if="form.isDirty">
                                <CAlert
                                    class="badge-warning mb-0"
                                    color="warning"
                                >
                                    <div
                                        v-html="
                                            __(
                                                'check_asset_status.modal.content.confirm_change',
                                                {
                                                    status: statusName,
                                                }
                                            )
                                        "
                                    />
                                </CAlert>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow v-if="form.errors">
                        <CCol>
                            <div
                                v-for="(message, key) in form.errors"
                                :key="key"
                            >
                                <small class="text-danger">{{ message }}</small>
                            </div>
                        </CCol>
                    </CRow>
                </form>
            </div>
        </template>
        <template #footer>
            <div class="w-100 d-flex justify-content-center align-items-center">
                <jet-button
                    :disabled="form.processing"
                    class="mx-2"
                    color="secondary"
                    @click="close"
                    >{{ __('general.action.cancel') }}
                </jet-button>
                <jet-button
                    :is-processing="form.processing"
                    @click.prevent="submitForm()"
                >
                    {{ __('general.action.confirm') }}
                </jet-button>
            </div>
        </template>
    </DialogModal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import { eventBus, events } from '@/eventBus';
import AssetOperabilitySwitch from '@/Pages/Assets/Details/AssetOperabilitySwitch.vue';
import JetTextArea from '@/Jetstream/Textarea.vue';
import UsageCategorySelectionDropdown from '@/Pages/Assets/Details/UsageCategorySelectionDropdown.vue';

export default {
    props: {
        assetId: {},
    },

    emits: ['performed'],

    components: {
        UsageCategorySelectionDropdown,
        JetTextArea,
        AssetOperabilitySwitch,
        DialogModal,
        JetButton,
    },

    data() {
        return {
            shouldRender: false,
            show: false,
            isOperationInProgress: false,
            isLoading: false,
            initialOperabilityValue: true,
            formData: null,
            form: this.$inertia.form({
                operability: undefined,
                usageCategory: undefined,
                reason: undefined,
                additionalInformation: undefined,
            }),
        };
    },

    mounted() {
        eventBus.$on([events.openCreateStatusCheckModal], (data) => {
            this.form.reset();
            this.shouldRender = true;
            this.loadFormData();
            this.$nextTick(() => {
                this.show = true;
            });
        });
    },

    destroyed() {
        eventBus.$off([events.openCreateStatusCheckModal]);
    },

    computed: {
        usageCategories() {
            return this.formData?.usageCategories || [];
        },
        reasons() {
            return this.formData?.reasons || [];
        },
        currentAssetUsageCategory() {
            return this.usageCategories.find(
                (item) => item.value === this.form.usageCategory
            );
        },
        currentOperability() {
            return this.form.operability ? 'operable' : 'inoperable';
        },
        hasOperabilityChanged() {
            // Needed to determine whether to show the optional section "reason for change"
            return this.initialOperabilityValue !== this.form.operability;
        },
        statusName() {
            let operability = this.__(
                `general.assetstatus.${this.currentOperability}`
            );
            return `${operability}/${this.currentAssetUsageCategory?.label}`;
        },
        showReasonAndAdditionalInformationSection() {
            return (
                this.form.operability === false && this.hasOperabilityChanged
            );
        },
    },

    methods: {
        loadFormData() {
            if (!this.assetId || this.isLoading) {
                return;
            }

            this.isLoading = true;

            this.$http
                .get(
                    this.route('assets.status-checks.create', {
                        asset: this.assetId,
                    })
                )
                .then((resp) => {
                    this.formData = resp.data;

                    const { operability, usageCategory } =
                        resp.data.asset.status;

                    this.initialOperabilityValue = operability === 'operable';

                    this.form = this.$inertia.form({
                        operability: this.initialOperabilityValue,
                        reason: 'corrective_maintenance_required',
                        usageCategory: usageCategory,
                        additionalInformation: undefined,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        submitForm() {
            if (this.form.processing) {
                return;
            }

            this.form
                .transform(this.transformData)
                .post(this.route('assets.status-checks.store', this.assetId), {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.close();

                        eventBus.$emit(events.updateNotifications);

                        if (this.hasOperabilityChanged) {
                            eventBus.$emit(events.assetOperabilityUpdated, {
                                asset: this.assetId,
                                ...this.transformData(this.form.data()),
                            });
                        }

                        this.$emit('performed');
                    },
                    onFinish: () => {
                        this.isOperationInProgress = false;
                    },
                });
        },
        transformData(data) {
            let operability = data.operability ? 'operable' : 'inoperable';

            let dataToSend = {
                usageCategory: data.usageCategory,
                operability,
            };

            if (data.operability) {
                return dataToSend;
            }

            if (!this.hasOperabilityChanged) {
                return dataToSend;
            }

            return {
                usageCategory: data.usageCategory,
                operability,
                reason: data.reason,
                additionalInformation: data.additionalInformation || null,
            };
        },
        close() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
    },
};
</script>
