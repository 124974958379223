<template>
    <c-card ref="card">
        <custom-card-header
            :has-permission="true"
            :href="route('assets.stakeholders.edit', asset)"
            :title="__('stakeholders.overview.title')"
            action-icon="cil-pencil"
        />
        <c-card-body>
            <div>
                <c-row>
                    <c-col :md="columnWidthMd">
                        <dt class="text-uppercase">
                            {{ __('asset.stakeholders.owners.label') }}
                        </dt>
                    </c-col>
                    <c-col>
                        <dd v-if="asset.owners?.length">
                            <stakeholder-base-information
                                v-for="owner in asset.owners"
                                :key="owner.id"
                                :collapsable="true"
                                :stakeholder="owner"
                            />
                        </dd>
                        <dd v-else>{{ __('general.not_available') }}</dd>
                    </c-col>
                </c-row>
                <c-row>
                    <c-col :md="columnWidthMd">
                        <dt class="text-uppercase">
                            {{ __('asset.stakeholders.operators.label') }}
                        </dt>
                    </c-col>
                    <c-col>
                        <dd v-if="asset.operators?.length">
                            <stakeholder-base-information
                                v-for="operator in asset.operators"
                                :key="operator.id"
                                :collapsable="true"
                                :stakeholder="operator"
                            />
                        </dd>
                        <dd v-else>{{ __('general.not_available') }}</dd>
                    </c-col>
                </c-row>
                <c-row>
                    <c-col :md="columnWidthMd">
                        <dt class="text-uppercase">
                            {{ __('asset.stakeholders.maintainers.label') }}
                        </dt>
                    </c-col>
                    <c-col>
                        <dd v-if="asset.maintainers?.length">
                            <stakeholder-base-information
                                v-for="maintainer in asset.maintainers"
                                :key="maintainer.id"
                                :collapsable="true"
                                :show-expiration-warning="
                                    shouldShowExpirationWarning(maintainer)
                                "
                                :stakeholder="maintainer"
                            >
                                <service-level-agreement-info
                                    :horizontal="columnWidthMd === '4'"
                                    :show-expiration-warning="
                                        shouldShowExpirationWarning(maintainer)
                                    "
                                    :sla="maintainer.sla"
                                />
                            </stakeholder-base-information>
                        </dd>
                        <dd v-else>{{ __('general.not_available') }}</dd>
                    </c-col>
                </c-row>
            </div>
        </c-card-body>
    </c-card>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import StakeholderBaseInformation from '@/Pages/Assets/Details/StakeholderBaseInformation.vue';
import ServiceLevelAgreementInfo from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementInfo.vue';

export default {
    name: 'AssetStakeholdersCard',
    components: {
        ServiceLevelAgreementInfo,
        StakeholderBaseInformation,
        CustomCardHeader,
    },
    data() {
        return { columnWidthMd: '4' };
    },
    computed: {
        asset() {
            return this.$page.props.asset;
        },
    },
    mounted() {
        const ro = new ResizeObserver(this.onResize);
        ro.observe(this.$refs.card);
    },
    methods: {
        onResize(event) {
            let width = event[0].contentRect.width;
            this.columnWidthMd = width > 575 ? '4' : '12';
        },
        shouldShowExpirationWarning(maintainer) {
            return (
                this.hasAnyPermission('maintainer.index') &&
                (maintainer.sla_expired || maintainer.sla_expires_soon)
            );
        },
    },
};
</script>
