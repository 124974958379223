<template>
    <div class="d-flex overflow-hidden">
        <div
            class="d-flex justify-content-center align-items-center flex-shrink-0"
            style="width: 40px"
        >
            <CIcon :class="iconClass" :name="icon" />
        </div>
        <div class="overflow-hidden">
            <div>
                <b class="text-truncate d-block">{{ title }}</b>
            </div>
            <small class="text-truncate d-block text-muted">{{
                description
            }}</small>
        </div>
    </div>
</template>
<script>
export default {
    name: 'asset.status.changed',
    props: {
        notification: {},
    },
    computed: {
        title() {
            return this.notification.payload.asset;
        },
        asset() {
            return this.notification.payload.asset;
        },
        description() {
            let { type, payload } = this.notification;

            // Translate asset status
            if (payload.status) {
                payload.status = this.__(
                    `notification.${type}.status.${payload.status}`
                );
            }

            return this.__(`notification.${type}`, payload);
        },
        icon() {
            switch (this.notification.payload.status) {
                case 'active':
                    return 'cil-check-alt';
                case 'repair_required':
                    return 'cil-warning';
                case 'not_in_use':
                    return 'cil-ban';
                case 'decommissioned':
                    return 'cil-trash';
                default:
                    return '';
            }
        },
        iconClass(notification) {
            switch (this.notification.payload.status) {
                case 'active':
                    return 'text-success';
                case 'repair_required':
                    return 'text-danger';
                case 'not_in_use':
                case 'decommissioned':
                    return 'text-muted';
                default:
                    return 'text-muted';
            }
        },
    },
};
</script>
