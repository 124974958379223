<template>
    <dialog-modal v-bind="$props">
        <template #title>
            <div>
                {{ __('assets.modal.operability_change.title') }}
            </div>
        </template>

        <template #content>
            <div
                v-html="
                    __('assets.modal.operability_change.description', {
                        operability: operabilityLabel,
                    })
                "
            ></div>
            <form
                v-if="operability === 'inoperable'"
                @submit.prevent="handleSubmit"
            >
                <CRow v-if="reasons && reasons.length" class="mt-3">
                    <CCol>
                        <b>{{
                            __('assets.modal.operability_change.reason.label')
                        }}</b>
                        <CInputRadioGroup
                            :checked.sync="form.reason"
                            :invalidFeedback="form.errors.reason"
                            :options="reasons"
                        />
                    </CCol>
                </CRow>

                <CRow class="mt-3">
                    <CCol>
                        <jet-text-area
                            v-model="form.additionalInformation"
                            :horizontal="false"
                            :invalidFeedback="form.errors.additionalInformation"
                            :label="
                                __(
                                    'assets.modal.operability_change.additional_information.label'
                                )
                            "
                            :label-classes="{ 'font-weight-bold': true }"
                            :maxlength="80"
                            :placeholder="
                                __(
                                    'assets.modal.operability_change.additional_information.placeholder'
                                )
                            "
                            :rows="4"
                        />
                    </CCol>
                </CRow>
            </form>
        </template>

        <template #footer>
            <CLink
                :disabled="form.processing"
                class="btn btn-link btn-secondary mx-1"
                @click.stop="cancel"
                >{{ __('general.action.cancel') }}
            </CLink>
            <jet-button
                :is-processing="form.processing"
                color="primary"
                @click.stop="handleSubmit"
            >
                {{ __('general.action.confirm') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetTextArea from '@/Jetstream/Textarea.vue';
import { eventBus, events } from '@/eventBus';

export default {
    name: 'ConfirmAssetStatusChangeModal',
    components: { DialogModal, JetButton, JetTextArea },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        operability: {
            value: String,
            label: String,
        },
        petitecId: String,
        assetId: String,
        reasons: Array,
    },
    data() {
        return {
            form: this.$inertia.form({
                operability: null,
                additionalInformation: '',
                reason: 'corrective_maintenance_required',
            }),
        };
    },
    computed: {
        operabilityLabel() {
            const text = this.__(
                `assets.modal.operability_change.label.${this.operability}`
            );
            const cssClass =
                this.form.operability === 'inoperable'
                    ? 'bg-danger'
                    : this.operability === 'operable'
                    ? 'bg-success'
                    : 'bg-secondary';

            return `<span><em
            class="operability-indicator ${cssClass} ${this.form.operability} mr-1"></em><b>${text}</b></span>`;
        },
    },
    watch: {
        show(newValue) {
            if (newValue) {
                this.form.reset();
                this.form.additionalInformation = '';
                this.form.reason = 'corrective_maintenance_required';
            }
        },
        operability(newValue, oldValue) {
            this.form.operability = newValue;
        },
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        handleSubmit() {
            this.form
                .transform(this.transformData)
                .post(this.route('assets.update-operability', this.assetId), {
                    preserveState: true,
                    onSuccess: () => {
                        this.$emit('close');

                        eventBus.$emit(events.updateNotifications);

                        eventBus.$emit(events.assetOperabilityUpdated, {
                            asset: this.assetId,
                            ...this.form.data(),
                        });
                    },
                });
        },
        transformData(data) {
            if (data.operability === 'operable') {
                // POST simple object containing only operability
                return { operability: 'operable' };
            }
            return data;
        },
    },
};
</script>
<style scoped>
>>> textarea {
    resize: none;
}
</style>
