<template>
    <app-layout>
        <div>
            <CRow class="my-3">
                <CCol>
                    <jet-button
                        class="text-nowrap sm-full-width mb-3"
                        color="primary"
                        @click="openQrCodeScanner()"
                        >{{ __('assets.overview.scanner.title') }}
                    </jet-button>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <div class="d-flex align-items-start my-3">
                        <div>
                            <h4 class="mb-0">
                                {{ __('assets.overview.headline') }}
                            </h4>
                        </div>
                    </div>
                </CCol>
            </CRow>
            <filter-bar
                :is-processing="isProcessing"
                class="my-2"
                @change="updateFilter"
            />
            <div class="d-flex justify-content-end">
                <small class="text-muted"
                    >{{
                        __('assets.overview.results.count', {
                            assets_count: `${transformedAssets.length}/${totalAssets}`,
                        })
                    }}
                </small>
            </div>
            <sticky-table-header>
                <CDataTable
                    v-if="assets"
                    :border="true"
                    :clickableRows="true"
                    :fields="visibleFields"
                    :items="transformedAssets"
                    :noItemsView="noItemsView"
                    :outlined="true"
                    :sorter="{ external: true, resetable: false }"
                    :sorter-value="activeQueryParams.sort"
                    :striped="true"
                    hover
                    @row-clicked="showDetails"
                    @update:sorter-value="updateSorting($event)"
                >
                    <template #sorting-icon="{ classes, state }">
                        <sort-icon :class="classes" :state="state" />
                    </template>
                    <template #selection-header>
                        <div
                            style="
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                padding: 12px;
                                cursor: pointer;
                            "
                            @click.stop="toggleAllSelection"
                        >
                            <input
                                ref="selectAll"
                                v-c-tooltip="{
                                    content:
                                        'Select all assets for current active filters',
                                    popperOptions: {
                                        positionFixed: true,
                                    },
                                }"
                                :checked="areAllItemsForActiveFiltersSelected"
                                :indeterminate.prop="
                                    selectedItems.length > 0 &&
                                    selectedItems.length < assets.meta.total
                                "
                                type="checkbox"
                            />
                        </div>
                    </template>
                    <template #selection="{ item }">
                        <td @click.stop="toggleItemSelection(item)">
                            <CInputCheckbox
                                :checked="isSelectedItem(item)"
                                type="checkbox"
                            />
                        </td>
                    </template>
                    <template #manufacturer="{ item }">
                        <td>
                            {{ item.manufacturer }}
                        </td>
                    </template>

                    <template #designation="{ item }">
                        <td>
                            <div>
                                {{ item.designation }}
                            </div>
                            <small class="text-nowrap text-dark"
                                >{{ __('assets.overview.cell.serial_number') }}:
                                {{ item.serialNumber }}</small
                            >
                        </td>
                    </template>

                    <template #airport="{ item }">
                        <td>
                            <span
                                v-c-tooltip="{
                                    content: item.airport_name,
                                    popperOptions: {
                                        positionFixed: true,
                                    },
                                }"
                            >
                                {{ item.airport }}
                            </span>
                        </td>
                    </template>

                    <template #location="{ item }">
                        <td>
                            {{ item.location }}
                        </td>
                    </template>
                    <template #status="{ item }">
                        <td>
                            <div>
                                <div
                                    class="d-flex justify-content-between align-items-top"
                                >
                                    <status-badge
                                        :operability="item.status.operability"
                                        :usage="item.status.usageCategory"
                                    />
                                    <CLink
                                        v-if="
                                            item.status.workOrdersInProgress > 0
                                        "
                                        @click.stop="
                                            openWorkOrderOverview(item)
                                        "
                                    >
                                        <work-order-badge
                                            v-c-tooltip="{
                                                content: __(
                                                    'assets.overview.tooltip.work_orders_in_progress',
                                                    {
                                                        '%d': item.status
                                                            .workOrdersInProgress,
                                                    }
                                                ),
                                            }"
                                            :count="
                                                item.status.workOrdersInProgress
                                            "
                                        />
                                    </CLink>
                                </div>
                                <small
                                    v-if="
                                        item.status.hasOwnProperty(
                                            'operabilityLastCheckedAt'
                                        ) &&
                                        hasAnyPermission(
                                            'asset.status_check.read'
                                        )
                                    "
                                    class="text-nowrap text-dark"
                                >
                                    <status-check-date :status="item.status" />
                                </small>
                            </div>
                        </td>
                    </template>

                    <template #issues="{ item }">
                        <td>
                            <div class="align-middle">
                                <div v-for="issue in item.issues">
                                    <onboarded-asset-issue
                                        v-if="
                                            issue.type ===
                                            'onboarding.incomplete'
                                        "
                                        @open-scanner="openQrCodeScanner(item)"
                                    />
                                    <issue-badge v-else :issue="issue" />
                                </div>
                                <div v-if="item.scores">
                                    <div
                                        v-for="(score, label) in item.scores
                                            .data"
                                        :key="label"
                                        class="d-flex text-muted text-sm text-uppercase justify-content-between"
                                    >
                                        <div class="mr-2">
                                            <small>{{ label }}:</small>
                                        </div>
                                        <div>
                                            <small>{{ score }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </template>
                </CDataTable>
            </sticky-table-header>
        </div>
        <Pagination :paginator="assets" :query="activeQueryParams" />
        <petitec-id-scanner
            ref="petitecIdScanner"
            :pid="selectedAsset ? selectedAsset.petitec_identifier : null"
            :show="isPetitecIdScannerVisible"
            :submitButtonLabel="
                !!selectedAsset
                    ? __('general.action.save')
                    : __('general.action.go')
            "
            @close="closePetitecIdScanner"
            @error="onPetitecIdScannerError"
            @success="onPetitecIdScannerSuccess"
        ></petitec-id-scanner>
        <BatchJobs
            v-if="selectedItems && hasFeature('asset-batch-operations')"
            :selected-items="selectedItems"
            @reset="resetSelection"
        />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import PetitecIdScanner from '@/Components/PetitecIdScanner.vue';
import FilterBar from '@/Components/FilterBar.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import BatchJobs from '@/Pages/Assets/BatchJobs/BatchJobs.vue';
import { debounce } from 'lodash';
import { removeEmptyProperties } from '@/utils';
import OnboardedAssetIssue from '@/Pages/Assets/OnboardedAssetIssue.vue';
import WorkOrderBadge from '@/Components/WorkorderBadge.vue';
import SortIcon from '@/Pages/Assets/SortIcon.vue';

export default {
    components: {
        SortIcon,
        OnboardedAssetIssue,
        WorkOrderBadge,
        BatchJobs,
        StickyTableHeader,
        AppLayout,
        JetButton,
        Pagination,
        PetitecIdScanner,
        IssueBadge,
        FilterBar,
        StatusBadge,
        StatusCheckDate,
    },

    data() {
        return {
            activeQueryParams: { column: 'status', asc: false },
            isProcessing: false,
            allFields: [
                {
                    key: 'selection',
                    label: '',
                    _style: 'width: 20px',
                },
                {
                    key: 'airport',
                    label: this.__('assets.overview.cell.airport'),
                    _style: 'width: 90px',
                },
                {
                    key: 'location',
                    label: this.__('assets.overview.cell.location'),
                    _style: 'width: 15%; white-space: nowrap;',
                },
                {
                    key: 'type',
                    label: this.__('assets.overview.cell.type'),
                    _style: 'width: 90px',
                },
                {
                    key: 'designation',
                    label: this.__('assets.overview.cell.designation'),
                    _style: 'width: 20%',
                },
                {
                    key: 'manufacturer',
                    label: this.__('assets.overview.cell.manufacturer'),
                },
                {
                    key: 'status',
                    label: this.__('assets.overview.cell.status'),
                    _style: 'width: 15%',
                },
                {
                    key: 'issues',
                    label: this.__('assets.overview.cell.issues'),
                    _style: 'width: 15%',
                },
            ],
            visibleFields: [],
            noItemsView: {
                noResults: this.__('assets.overview.filters.empty'),
                noItems: this.__('assets.overview.results.empty'),
            },
            isSearching: false,
            selectedAsset: null,
            isPetitecIdScannerVisible: false,
            selectedItems: [],
            areAllItemsForActiveFiltersSelected: false,
        };
    },

    computed: {
        assets() {
            return this.$page.props.assets;
        },
        totalAssets() {
            return this.assets.meta.total;
        },
        hasCameraSupport() {
            return !!navigator.mediaDevices;
        },
        placement() {
            return document.dir === 'rtl' ? 'top-start' : 'top-end';
        },
        transformedAssets() {
            const notAvailable = this.__('general.not_available');

            return this.assets.data.map((asset) => ({
                id: asset.id,
                type: asset.type.value || notAvailable,
                designation: asset.designation
                    ? asset.designation.label
                    : notAvailable,
                manufacturer: asset.manufacturer
                    ? asset.manufacturer.name
                    : notAvailable,
                petitec_identifier: asset.petitec_identifier,
                serialNumber: asset.serialNumber || notAvailable,
                airport:
                    asset.location.physical_location.iata_code || notAvailable,

                airport_name:
                    asset.location.physical_location.name || notAvailable,
                location: this.getLocationName(asset.location),
                issues: asset.issues,
                status: asset.status,
                scores: asset.scores,
            }));
        },
        scanner() {
            return this.$refs.petitecIdScanner;
        },
        defaultSorting() {
            return { column: 'status', asc: false };
        },
        activeQueryParamsWithoutSorting() {
            const params = Object.assign({}, this.activeQueryParams);
            delete params.sort;
            return params;
        },
    },

    methods: {
        getLocationName(location) {
            if (!location) {
                return this.__('general.not_available');
            }

            return [location.name]
                .reduce(
                    (unique, item) =>
                        unique.includes(item) ? unique : [...unique, item],
                    []
                )
                .filter((item) => item)
                .join(' - ');
        },
        showDetails(asset) {
            this.$inertia.visit(this.route('assets.show', asset.id));
        },
        openQrCodeScanner(item) {
            this.selectedAsset = item || null;
            this.isPetitecIdScannerVisible = true;
        },
        closePetitecIdScanner() {
            this.isPetitecIdScannerVisible = false;

            window.location.hash = '';

            setTimeout(() => {
                this.selectedAsset = null;
            }, 500);
        },
        onPetitecIdScannerSuccess(data) {
            const petitecId = data.uuid;

            if (this.selectedAsset) {
                this.assignPetitecId(petitecId);
            } else {
                this.searchAsset(petitecId);
            }
        },
        onPetitecIdScannerError() {},
        assignPetitecId(pid) {
            if (!this.selectedAsset) {
                return;
            }

            this.scanner.form.put(
                this.route('assets.assign_pid', this.selectedAsset.id),
                {
                    onSuccess: () => {
                        this.scanner.isValidQrCode = true;
                        setTimeout(() => {
                            this.closePetitecIdScanner();
                        }, 300);
                    },
                    onError: () => {
                        this.scanner.isValidQrCode = false;
                    },
                }
            );
        },
        searchAsset(petitecId) {
            this.$http
                .get(this.route('assets.search', { pid: petitecId }))
                .then((resp) => {
                    const asset = resp?.data;

                    if (asset?.id) {
                        this.scanner.isValidQrCode = true;
                        setTimeout(() => {
                            this.$inertia.visit(
                                this.route('assets.show', asset.id)
                            );
                        }, 300);
                    } else {
                        this.reportScannerError();
                    }
                })
                .catch((err) => {
                    this.reportScannerError();
                });
        },
        reportScannerError() {
            this.scanner.isValidQrCode = false;
            this.scanner.form.errors.petitec_identifier = this.__(
                'assets.overview.scanner.error.asset_not_found'
            );
        },
        loadAssets() {
            const { sort } = this.activeQueryParams;

            let queryParams = Object.assign(
                {},
                removeEmptyProperties(this.activeQueryParams),
                {
                    sort: this.transformSortToQuery(sort),
                }
            );

            this.isProcessing = true;

            this.$inertia.get(
                this.route('assets.index', queryParams),
                {},
                {
                    preserveState: true,
                    onFinish: () => {
                        this.updateTable();
                        this.isProcessing = false;
                    },
                }
            );
        },
        updateTable() {
            this.visibleFields = this.allFields.filter((field) => {
                return !(
                    field.key === 'selection' &&
                    !this.hasFeature('asset-batch-operations')
                );
            });
        },
        updateFilter: debounce(function (filters) {
            this.activeQueryParams = { ...filters, sort: this.defaultSorting };
            this.loadAssets();
        }, 300),
        updateSorting(event) {
            this.activeQueryParams.sort = event ?? undefined;
            this.loadAssets();
        },
        transformSortToQuery(sort) {
            if (!sort) {
                return undefined;
            }

            let sortDirection = sort.asc ? 'asc' : 'desc';
            return `${sort.column}.${sortDirection}`;
        },
        transformQueryToSort(sort) {
            if (sort) {
                const parts = sort.split('.');
                if (parts.length === 2) {
                    return {
                        column: parts[0],
                        asc: parts[1] === 'asc',
                    };
                }
            }

            return this.defaultSorting;
        },
        startPetitecScannerIfNecessary() {
            const parts = window.location.hash.split('#assign-petitec-id=');

            if (parts.length === 2) {
                this.openQrCodeScanner({ id: parts[1] });
            }
        },
        isSelectedItem(item) {
            return this.selectedItems.includes(item.id);
        },
        toggleItemSelection(item) {
            const routeName = this.selectedItems.includes(item.id)
                ? 'assets.batch.selection.remove'
                : 'assets.batch.selection.add';

            this.$http
                .post(
                    this.route(routeName, this.activeQueryParamsWithoutSorting),
                    {
                        ids: [item.id],
                    }
                )
                .then((resp) => this.updateSelection(resp.data));
        },
        toggleAllSelection: debounce(function () {
            this.areAllItemsForActiveFiltersSelected =
                !this.areAllItemsForActiveFiltersSelected;
            const routeName = this.areAllItemsForActiveFiltersSelected
                ? 'assets.batch.selection.add'
                : 'assets.batch.selection.remove';

            this.$http
                .post(
                    this.route(routeName, this.activeQueryParamsWithoutSorting),
                    {
                        ids: ['*'],
                    }
                )
                .then((resp) => this.updateSelection(resp.data));
        }, 150),
        resetSelection() {
            this.$http
                .post(this.route('assets.batch.selection.reset'))
                .then((resp) => this.updateSelection(resp.data));
        },
        updateSelection(data) {
            this.selectedItems = data.ids;
            this.areAllItemsForActiveFiltersSelected =
                this.selectedItems.length > 0 &&
                this.selectedItems.length === this.assets.meta.total;
        },
        openWorkOrderOverview(item) {
            this.$inertia.visit(this.route('assets.workorders.index', item.id));
        },
    },
    mounted() {
        const { search, airports, usages, operabilities, types, sort } =
            this.route().params;

        this.activeQueryParams = {
            search,
            airports,
            usages,
            operabilities,
            types,
            sort: this.transformQueryToSort(sort),
        };

        this.updateTable();

        window.addEventListener(
            'hashchange',
            this.startPetitecScannerIfNecessary
        );

        this.$nextTick(function () {
            this.updateSelection(this.$page.props.selectedAssets);
        });
    },
    beforeDestroy() {
        window.removeEventListener(
            'hashchange',
            this.startPetitecScannerIfNecessary
        );
    },
};
</script>
