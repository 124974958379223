<template>
    <dialog-modal :scrollable="false" :show="show" @close="$emit('cancel')">
        <template #title>{{ __('models.modal.add_model.title') }}</template>

        <template #content>
            <form @submit.prevent="handleSubmit">
                <CRow>
                    <CCol>
                        <div class="d-flex w-100">
                            <jet-input
                                v-model="form.designation"
                                :autofocus="show"
                                :invalidFeedback="form.errors.designation"
                                :label="__('models.field.designation.label')"
                                :placeholder="
                                    __('models.field.designation.placeholder')
                                "
                                :required="true"
                                style="flex-grow: 1"
                            >
                            </jet-input>
                        </div>
                    </CCol>
                </CRow>
                <CRow class="mt-4">
                    <CCol>
                        <jet-select
                            v-model="form.type"
                            :invalidFeedback="form.errors.type"
                            :label="__('models.field.type.label')"
                            :options="types"
                            :placeholder="__('models.field.type.placeholder')"
                            :required="true"
                            style="flex-grow: 1"
                        />
                    </CCol>
                </CRow>
            </form>
        </template>

        <template #footer>
            <CLink
                class="btn btn-link btn-secondary mx-1"
                @click.stop="$emit('cancel')"
                >{{ __('general.action.cancel') }}
            </CLink>
            <jet-button
                :disabled="!isFormSubmittable"
                color="primary"
                @click.stop="handleSubmit"
            >
                {{ __('general.action.create') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';

export default {
    components: { DialogModal, JetButton, JetInput, JetSelect },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        types: {
            type: Array,
        },
        manufacturer_id: {
            type: String,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                type: null,
                designation: null,
            }),
        };
    },

    watch: {
        show(newShow) {
            this.form.clearErrors().reset();
        },
    },

    methods: {
        handleSubmit(event) {
            event.preventDefault();

            let data = Object.assign({}, this.form.data(), {
                manufacturer_id: this.manufacturer_id,
            });

            this.$http
                .post(route('models.store'), data)
                .then((response) => {
                    this.$emit('success', response.data);
                })
                .catch((error) => {
                    this.form.clearErrors();
                    let errors = {};

                    Object.keys(error.response.data.errors).map((key) => {
                        errors[key] = error.response.data.errors[key][0];
                    });

                    this.form.errors = errors;
                });
        },
    },
};
</script>
