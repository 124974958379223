<template>
    <div>
        <CCard>
            <CCardHeader
                class="d-flex justify-content-between align-items-center"
                style="min-height: 50px"
            >
                <h5 class="mb-0">{{ __('owners.overview.title') }}</h5>
                <Link
                    v-if="hasAnyPermission('owner.create')"
                    class="btn btn-primary"
                    @click.prevent="createOwner"
                    >{{ __('owners.overview.action.add') }}
                </Link>
            </CCardHeader>
            <CCardBody>
                <total-table-items
                    :text="
                        __('owners.overview.results.count', {
                            total: owners?.meta.total || 0,
                        })
                    "
                />
                <sticky-table-header>
                    <CDataTable
                        :border="true"
                        :clickableRows="false"
                        :fields="fields"
                        :items="owners?.data || []"
                        :noItemsView="noItemsView"
                        :outlined="true"
                        :sorter="{ external: true, resetable: false }"
                        :sorter-value="sortOrder"
                        :striped="true"
                        hover
                        @update:sorter-value="updateSorting"
                    >
                        <template #sorting-icon="{ classes, state }">
                            <sort-icon :class="classes" :state="state" />
                        </template>
                        <template #contact="{ item }">
                            <td class="align-middle">
                                <div v-if="item.email || item.description">
                                    <div v-if="item.email">
                                        <email-link :email="item.email" />
                                    </div>
                                    <div v-if="item.description">
                                        {{ item.description }}
                                    </div>
                                </div>
                                <div v-else>
                                    {{ __('general.not_available') }}
                                </div>
                            </td>
                        </template>
                        <template #assets_count="{ item }">
                            <td class="align-middle">
                                <div>{{ item.assets_count || 0 }}</div>
                            </td>
                        </template>
                        <template #locations_count="{ item }">
                            <td class="align-middle">
                                <div>{{ item.locations_count || 0 }}</div>
                            </td>
                        </template>
                        <template #actions="{ item }">
                            <td class="align-middle">
                                <div class="d-flex justify-content-end">
                                    <jet-button
                                        v-if="hasAnyPermission('owner.update')"
                                        v-c-tooltip="{
                                            content: __(
                                                'owners.overview.tooltip.edit'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="primary"
                                        @click.stop="editOwner(item)"
                                    >
                                        <CIcon name="cil-pencil" />
                                    </jet-button>
                                    <jet-button
                                        v-if="hasAnyPermission('owner.delete')"
                                        v-c-tooltip="{
                                            content: __(
                                                'owners.overview.tooltip.delete'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="danger"
                                        @click.stop="deleteOwner(item)"
                                    >
                                        <CIcon name="cil-trash" />
                                    </jet-button>
                                </div>
                            </td>
                        </template>
                    </CDataTable>
                </sticky-table-header>
                <Pagination
                    :only="['owners']"
                    :paginator="owners"
                    class="mt-2"
                />
            </CCardBody>
        </CCard>
        <update-or-create-owner-form-modal />
    </div>
</template>
<script>
import SortIcon from '@/Pages/Assets/SortIcon.vue';
import JetButton from '@/Jetstream/Button.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import Pagination from '@/Components/Pagination.vue';
import TotalTableItems from '@/Components/TotalTableItems.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import EmailLink from '@/Components/EmailLink.vue';
import UpdateOrCreateOwnerFormModal from '@/Pages/Stakeholders/Owners/UpdateOrCreateOwnerFormModal.vue';
import { eventBus, events } from '@/eventBus.js';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';

export default {
    name: 'OwnersOverview',
    components: {
        DeleteStakeholderModal,
        UpdateOrCreateOwnerFormModal,
        EmailLink,
        CustomCardHeader,
        TotalTableItems,
        Pagination,
        StickyTableHeader,
        JetButton,
        SortIcon,
    },
    data() {
        return {
            sortOrder: {},
            fields: [
                {
                    key: 'name',
                    label: this.__('owners.overview.cell.name'),
                },
                {
                    key: 'contact',
                    label: this.__('owners.overview.cell.contact'),
                    _style: 'white-space: nowrap;width:300px;min-width:300px',
                },
                {
                    key: 'assets_count',
                    label: this.__('owners.overview.cell.assets'),
                    _style: 'white-space: nowrap;width:90px;',
                },
                {
                    key: 'locations_count',
                    label: this.__('owners.overview.cell.locations'),
                    _style: 'white-space: nowrap;width:90px;',
                },
                {
                    key: 'actions',
                    label: '',
                    sorter: false,
                    filter: false,
                    _style: 'white-space: nowrap;width:90px',
                },
            ],
            noItemsView: {
                noResults: this.__('owners.overview.filters.empty'),
                noItems: this.__('owners.overview.results.empty'),
            },
        };
    },
    computed: {
        owners() {
            return this.$page.props.owners;
        },
    },
    mounted() {
        let [column, order] = this.$page.props.owners_sort.split('.');
        this.sortOrder = {
            column,
            asc: order === 'asc',
        };
    },
    methods: {
        createOwner() {
            eventBus.$emit(events.openCreateOwnerModal);
        },
        editOwner(item) {
            eventBus.$emit(events.openEditOwnerModal, { owner: item });
        },
        deleteOwner(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'owner',
            });
        },
        updateSorting(event) {
            let sortOrder = event.asc ? 'asc' : 'desc';
            this.$inertia.visit(
                this.route(this.route().current(), {
                    owners_sort: `${event.column}.${sortOrder}`,
                }),
                {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['owners'],
                }
            );
        },
    },
};
</script>
