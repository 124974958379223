<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasAnyPermission(['operator.create', 'operator.update'])
        "
        :closeable="false"
        :show="show"
        @close="close"
    >
        <template #title>{{ title }}</template>

        <template #content>
            <form @submit.prevent="submitForm">
                <jet-input
                    v-model="form.name"
                    :autofocus="true"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                    :invalidFeedback="form.errors.name"
                    :label="__('operators.form.name.label')"
                    :placeholder="__('operators.form.name.placeholder')"
                    :required="true"
                />
                <div class="form-row">
                    <c-col sm="4"
                        >{{ __('operators.form.contact_details.label') }}
                    </c-col>
                    <c-col sm="8">
                        <jet-input
                            v-model="form.email"
                            :horizontal="false"
                            :invalidFeedback="form.errors.email"
                            :label="__('stakeholders.form.email.label')"
                            :placeholder="
                                __('stakeholders.form.email.placeholder')
                            "
                            :required="true"
                        />
                        <jet-input
                            v-model="form.description"
                            :horizontal="false"
                            :invalidFeedback="form.errors.description"
                            :label="__('stakeholders.form.description.label')"
                            :placeholder="
                                __('stakeholders.form.description.placeholder')
                            "
                            class="mb-0"
                        />
                    </c-col>
                </div>
            </form>
        </template>

        <template #footer>
            <div class="w-100">
                <div class="mb-3 d-flex justify-content-start">
                    <small
                        ><em class="text-danger">*</em>
                        {{ __('general.required_fields') }}</small
                    >
                </div>
                <div class="d-flex justify-content-center" style="gap: 10px">
                    <jet-button
                        :disabled="form.processing"
                        color="secondary"
                        @click.stop="close"
                    >
                        {{ __('general.action.cancel') }}
                    </jet-button>
                    <jet-button
                        :disabled="!form.isDirty"
                        :is-processing="form.processing"
                        color="primary"
                        @click.stop="submitForm"
                    >
                        <span v-if="!operator">{{
                            __('general.action.add')
                        }}</span>
                        <span v-else>{{ __('general.action.save') }}</span>
                    </jet-button>
                </div>
            </div>
        </template>
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';

export default {
    name: 'UpdateOrCreateOperatorFormModal',
    emits: ['created', 'updated'],
    components: {
        FileUpload,
        DialogModal,
        JetButton,
        JetInput,
        JetSelect,
    },
    data() {
        return {
            shouldRender: false,
            show: false,
            operator: null,
            form: this.$inertia.form({
                name: '',
                email: '',
                description: '',
            }),
        };
    },
    mounted() {
        eventBus.$on(
            [events.openCreateOperatorModal, events.openEditOperatorModal],
            (data) => {
                this.operator = data?.operator || null;
                this.reset();

                if (this.operator) {
                    this.loadOperatorInformation();
                }

                this.shouldRender = true;
                this.$nextTick(() => {
                    this.show = true;
                });
            }
        );
    },
    destroyed() {
        eventBus.$off([
            events.openCreateOperatorModal,
            events.openEditOperatorModal,
        ]);
    },
    computed: {
        title() {
            return !!this.operator
                ? this.__('operators.edit.title')
                : this.__('operators.create.title');
        },
    },
    methods: {
        submitForm() {
            let route = this.operator
                ? this.route('operators.update', {
                      operator: this.operator,
                  })
                : this.route('operators.store');

            this.form
                .transform((data) => {
                    if (this.operator) {
                        data._method = 'PUT';
                    }
                    return data;
                })
                .post(route, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.close();

                        this.$emit(
                            this.operator ? 'updated' : 'created',
                            this.operator
                        );
                    },
                });
        },
        close() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
        reset() {
            this.form = this.$inertia.form({
                name: '',
                email: '',
                description: '',
            });
        },
        loadOperatorInformation() {
            this.form.processing = true;

            this.$http
                .get(this.route('operators.edit', this.operator))
                .then((response) => {
                    this.operator = response.data.operator;
                    this.form = this.$inertia.form({
                        name: this.operator?.name || '',
                        email: this.operator?.email || '',
                        description: this.operator?.description || '',
                    });
                });
        },
    },
};
</script>
