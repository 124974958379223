<template>
    <app-layout>
        <asset-details-header :asset="asset" />
        <div class="py-3">
            <CCard>
                <c-card-header>Performance</c-card-header>
                <CCardBody>
                    <PerformanceDataTable />
                </CCardBody>
            </CCard>
            <Pagination :paginator="performanceData" />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import PerformanceDataTable from '@/Pages/Assets/Details/PerformanceData/PerformanceDataTable.vue';
import Pagination from '@/Components/Pagination.vue';

export default {
    components: {
        Pagination,
        PerformanceDataTable,
        AppLayout,
        AssetDetailsHeader,
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        performanceData() {
            return this.$page.props.performanceData;
        },
    },
};
</script>
