<template>
    <div
        :class="{ 'form-row': horizontal }"
        class="form-group datepicker"
        role="group"
    >
        <label
            v-if="label"
            :class="{ 'col-form-label col-sm-3': horizontal, required }"
            :for="id"
            >{{ label }}</label
        >
        <div v-if="!disabled" :class="{ 'col-sm-9': horizontal }">
            <date-picker
                :locale="currentLocale"
                :max-date="maxDate"
                :min-date="minDate"
                :popover="{ visibility: 'focus' }"
                :value="value"
                @input="dateChanged"
            >
                <template v-slot:default="{ inputValue, inputEvents }">
                    <slot :inputEvents="inputEvents" :inputValue="inputValue">
                        <div class="input-group">
                            <div
                                class="input-group-prepend"
                                @click.stop="focus"
                            >
                                <span
                                    class="input-group-text bg-primary text-white"
                                >
                                    <CIcon name="cil-calendar" />
                                </span>
                            </div>
                            <input
                                :id="id"
                                ref="input"
                                :class="{ 'is-invalid': !isValid }"
                                :placeholder="placeholder"
                                :required="required"
                                :value="value | fullDate(currentLocale)"
                                class="form-control"
                                readonly
                                v-on="inputEvents"
                            />
                            <div
                                v-if="deletable && !!value"
                                class="input-group-append"
                            >
                                <button
                                    class="input-group-text text-danger"
                                    type="button"
                                    @click="$emit('input', null)"
                                >
                                    <CIcon name="cil-x" />
                                </button>
                            </div>
                        </div>
                    </slot>
                    <div v-if="description" class="text-muted">
                        <small>{{ description }}</small>
                    </div>
                </template>
            </date-picker>
            <div
                v-if="invalidFeedback"
                class="invalid-feedback"
                style="display: block"
            >
                {{ invalidFeedback }}
            </div>
        </div>
        <div v-else :class="{ 'col-sm-9': horizontal }">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <CIcon name="cil-calendar" />
                    </span>
                </div>
                <input class="form-control" disabled />
            </div>
        </div>
    </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import { DateTime } from 'luxon';

export default {
    name: 'JetDatepicker',

    components: {
        DatePicker,
    },

    props: {
        label: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        value: Date | null,
        required: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        minDate: {
            type: Object | String,
        },
        maxDate: {
            type: Object | String,
        },
        deletable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            id: null,
        };
    },

    emits: ['update:modelValue'],

    computed: {
        isValid() {
            return (
                this.invalidFeedback === null ||
                this.invalidFeedback?.trim() === ''
            );
        },
    },

    mounted() {
        this.id = this._uid;
    },

    methods: {
        focus() {
            this.$refs.input.focus();
        },
        dateChanged(value) {
            let date = DateTime.fromJSDate(value);
            date = date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            this.$emit('input', date);
        },
    },
};
</script>
<style scoped>
.form-control[readonly] {
    background: none;
}

>>> .vc-day.is-today .vc-day-content {
    background: #efefef;
}

>>> .vc-day.is-today .vc-highlights + .vc-day-content {
    background: none;
}

>>> .vc-highlight {
    background-color: #3e56c6 !important;
}
</style>
