<template>
    <CTextarea
        ref="textarea"
        :add-label-classes="{ required, ...labelClasses }"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :description="description"
        :horizontal="horizontal"
        :invalidFeedback="invalidFeedback"
        :isValid="isValid"
        :label="label"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :required="required"
        :rows="rows"
        :type="type"
        :value="value"
        @input="valueChanged"
        @keyup="$emit('keyup', $event)"
    >
        <template #description>
            <div class="d-flex justify-content-between">
                <div v-if="description" class="form-text text-muted">
                    <small>{{ description }}</small>
                </div>
                <div v-if="!!maxlength" class="form-text text-muted">
                    <small class="">
                        {{
                            __('general.remaining_characters', {
                                limit: `${counter}/${maxlength}`,
                            })
                        }}</small
                    >
                </div>
            </div>
        </template>
    </CTextarea>
</template>

<script>
export default {
    name: 'JetTextarea',

    props: {
        label: String,
        labelClasses: Object,
        type: {
            type: String,
            default: 'text',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        autocomplete: String,
        value: String,
        horizontal: {
            default: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        rows: {
            type: Number,
            default: 5,
        },
        maxlength: {
            type: Number,
        },
        minHeight: {
            type: Number,
            default: 34,
        },
        scrollIntoView: {
            type: Boolean,
            default: false,
        },
        resizable: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            counter: 0,
        };
    },

    computed: {
        isValid() {
            return this.invalidFeedback === '' ? null : false;
        },
        element() {
            return this.$refs.textarea.$el.getElementsByTagName('textarea')[0];
        },
    },

    mounted() {
        this.counter = this.value?.length ?? 0;

        if (this.resizable) {
            this.resize();
        }

        if (this.scrollIntoView) {
            this.$refs.textarea.$el
                .getElementsByTagName('textarea')[0]
                .addEventListener('focus', this.handleOnFocus);
        }
    },

    beforeDestroy() {
        if (this.scrollIntoView) {
            this.$refs.textarea.$el
                .getElementsByTagName('textarea')[0]
                .removeEventListener('focus', this.handleOnFocus);
        }
    },

    watch: {
        value(newValue) {
            this.$nextTick(() => {
                this.counter = newValue.length ?? 0;
                this.resize();
            });
        }
    },

    methods: {
        focus() {
            this.element.focus();
        },
        valueChanged(value) {
            this.$emit('input', value);
        },
        handleOnFocus(event) {
            event.target.scrollIntoView({ behavior: 'smooth' });
        },
        resize() {
            if (!this.resizable) {
                return;
            }

            let element = this.element;
            let minHeight = this.minHeight * this.rows;
            element.style.height = `${minHeight}px`;

            let height =
                element.scrollHeight > minHeight
                    ? element.scrollHeight
                    : minHeight;

            element.style.height = `${height}px`;
        },
    },
};
</script>
<style scoped>
>>> textarea {
    resize: none;
    overflow: hidden;
    cursor: text;
    padding: 8px;
}
</style>
