<template>
    <li class="c-sidebar-nav-item">
        <Link
            :class="{
                'c-active': isActive,
            }"
            :href="url"
            class="c-sidebar-nav-link"
        >
            <CIcon :name="icon" class="c-sidebar-nav-icon" />
            {{ text }}
            <span v-if="count" class="mx-1">({{ count }})</span>
        </Link>
        <slot />
    </li>
</template>
<script>
export default {
    props: {
        routeName: String,
        icon: String,
        href: String,
        title: String,
        count: Number,
    },
    computed: {
        url() {
            return this.href || this.route(this.routeName);
        },
        text() {
            return this.title || this.__('navigation.' + this.routeName);
        },
        isActive() {
            return this.$page.props.activeNavigation[this.routeName] || false;
        },
    },
};
</script>
