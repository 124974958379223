<template>
    <app-layout>
        <c-row>
            <c-col>
                <header class="sticky-header">
                    <div
                        class="d-flex justify-content-between align-items-center"
                        style="gap: 5px"
                    >
                        <h4 class="m-0">Reporting</h4>
                        <jet-button
                            :disabled="true"
                            color="secondary"
                            class="d-sm-none"
                            >Export CSV
                        </jet-button>
                    </div>
                </header>
            </c-col>
        </c-row>
        <c-row class="mt-4">
            <c-col>
                <div
                    class="d-sm-flex justify-content-between align-items-center"
                >
                    <reporting-filter-bar
                        key="filter-bar-1"
                        @change="handleFilterChange"
                        :is-processing="isProcessing"
                    />
                    <jet-button
                        :disabled="true"
                        color="secondary"
                        class="d-none d-sm-block"
                        >Export CSV
                    </jet-button>
                </div>
            </c-col>
        </c-row>
        <c-row class="mt-3">
            <c-col>
                <c-card>
                    <c-card-header
                        ><b>Inoperable Equipment Report</b></c-card-header
                    >
                    <c-card-body>
                        <c-alert color="info" v-if="missingLocations.length">
                            <div>
                                The report(s) for the following location(s) are
                                not available yet:
                            </div>
                            <ul class="list-unstyled">
                                <li v-for="location in missingLocations">
                                    <div
                                        class="d-flex align-items-center"
                                        style="gap: 10px"
                                    >
                                        <c-icon name="cil-clock" />
                                        {{ location }}
                                    </div>
                                </li>
                            </ul>
                        </c-alert>
                        <div
                            class="d-flex justify-content-between align-items-start pb-2"
                        >
                            <report-meta-info
                                :report="report"
                                :total-assets-count="totalAssetsCount"
                            />
                            <div
                                class="d-none d-sm-block"
                                v-if="report.type === 'daily'"
                            >
                                <small class="text-muted"
                                    >{{
                                        __('assets.overview.results.count', {
                                            assets_count: `${report.inoperableAssets.data.length}/${report.inoperableAssets.meta.total}`,
                                        })
                                    }}
                                </small>
                            </div>
                        </div>
                        <daily-light-report-table
                            v-if="report?.type === 'daily'"
                            @sort="handleSort"
                        />
                        <weekly-light-report-table v-else />
                    </c-card-body>
                </c-card>
            </c-col>
        </c-row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import ReportingFilterBar from '@/Pages/ReportingLight/ReportingFilterBar.vue';
import DailyLightReportTable from '@/Pages/ReportingLight/DailyLightReportTable.vue';
import WeeklyLightReportTable from '@/Pages/ReportingLight/WeeklyLightReportTable.vue';
import ReportMetaInfo from '@/Pages/ReportingLight/ReportMetaInfo.vue';

export default {
    name: 'ReportingLight',
    components: {
        ReportMetaInfo,
        WeeklyLightReportTable,
        DailyLightReportTable,
        ReportingFilterBar,
        JetButton,
        AppLayout,
    },
    data() {
        return {
            activeFilters: { type: 'daily', locations: [] },
            isProcessing: false,
        };
    },
    computed: {
        totalAssetsCount() {
            return this.$page.props.totalAssetsCount || 0;
        },
        report() {
            return this.$page.props.report;
        },
        missingLocations() {
            return [];
        },
        availableLocationIds() {
            return this.$page.props.reportAvailableForLocationIds;
        },
    },
    methods: {
        handleFilterChange(filters) {
            this.activeFilters = filters;
            this.loadData();
        },
        handleSort(sort) {
            this.activeFilters.sort = sort;
            this.loadData();
        },
        transformSortToQuery(sort) {
            if (!sort) {
                return undefined;
            }

            if (this.activeFilters.type === 'weekly') {
                return undefined;
            }

            let sortDirection = sort.asc ? 'asc' : 'desc';
            return `${sort.column}.${sortDirection}`;
        },
        loadData() {
            this.isProcessing = true;

            let params = Object.assign({}, this.activeFilters, {
                sort: this.transformSortToQuery(this.activeFilters.sort),
            });

            this.$inertia.get(this.route('reporting-light.index'), params, {
                only: ['totalAssetsCount', 'availableReportDates', 'report'],
                preserveState: true,
                onFinish: () => {
                    this.isProcessing = false;
                },
            });
        },
    },
};
</script>
