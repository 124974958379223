<template>
    <div>
        <h3 v-if="title">
            {{ title }}
        </h3>
        <CRow class="mt-3">
            <CCol>
                <jet-select
                    v-model="cadence.unit"
                    :horizontal="true"
                    :invalid-feedback="errors?.unit"
                    :label="__('cadence.unit.label')"
                    :options="repeatsOptions"
                    :required="true"
                    @input="updateCadenceUnit($event)"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <jet-select
                    v-model="cadence.value"
                    :disabled="!isConfigurable"
                    :horizontal="true"
                    :invalid-feedback="errors?.value"
                    :label="__('cadence.value.label')"
                    :options="everyOptions"
                    @input="emitChanges"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <jet-datepicker
                    v-model="cadence.startDate"
                    :deletable="false"
                    :disabled="!isConfigurable"
                    :horizontal="true"
                    :invalid-feedback="errors?.startDate"
                    :label="__('cadence.start_date.label')"
                    @input="emitChanges"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <div class="d-flex justify-center align-items-center" />
            </CCol>
        </CRow>
        <CRow
            v-if="
                nextCadenceDateMessageTranslationKeySpecified &&
                nextDueDateRoute
            "
        >
            <CCol>
                <div v-if="isLoading">
                    <CSpinner color="primary" size="sm" />
                </div>
                <div v-else>
                    <div
                        v-if="nextCadenceDateRaw"
                        v-html="
                            __(
                                this
                                    .nextCadenceDateMessageTranslationKeySpecified,
                                { date: formattedNextCadenceDate }
                            )
                        "
                    />
                    <div v-else v-html="errorMessage" />
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import JetSelect from '@/Jetstream/Select.vue';
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import { DateTime } from 'luxon';

export default {
    name: 'CadenceConfigForm',
    props: {
        title: String,
        value: Object | null,
        nextDueDateRoute: String | null,
        nextCadenceDateMessageTranslationKeySpecified: String | null,
        nextCadenceDateMessageTranslationKeyNever: String | null,
        errors: Object | null,
        updateDueDate: Boolean,
    },
    components: {
        JetDatepicker,
        JetSelect,
    },
    data() {
        return {
            isLoading: false,
            cadenceInformation: null,
            cadence: {
                unit: 'never',
                value: null,
                startDate: null,
            },
        };
    },
    computed: {
        options() {
            return this.$page.props.cadenceOptions;
        },
        repeatsOptions() {
            return this.options.map((option) => ({
                label: this.__(`cadence.unit.${option.unit}.option`),
                value: option.unit,
            }));
        },
        everyOptions() {
            let { unit } = this.cadence;
            let range = this.options.find(
                (option) => option.unit === unit
            )?.range;

            return range
                ? range.map((val) => ({
                      label: this.__(`cadence.unit.${unit}.value`, {
                          '%d': val,
                      }),
                      value: val.toString(),
                  }))
                : [];
        },
        isConfigurable() {
            return this.cadence.unit !== 'never';
        },
        defaultValue() {
            return { unit: 'never', value: null, startDate: null };
        },
        formattedNextCadenceDate() {
            if (this.nextCadenceDateRaw) {
                const date = DateTime.fromISO(this.nextCadenceDateRaw);
                return this.$options.filters.dateTimeMedium(
                    this.nextCadenceDateRaw,
                    this.currentLocale
                );
            }

            return null;
        },
        transformedCadence() {
            let { value, unit, startDate } = this.cadence;
            return {
                unit,
                value: value ? parseInt(value) : null,
                startDate,
            };
        },
        nextCadenceDateRaw() {
            return this.cadenceInformation?.date;
        },
        errorMessage() {
            return (
                this.cadenceInformation?.reason ??
                this.__(this.nextCadenceDateMessageTranslationKeyNever)
            );
        },
    },
    watch: {
        value: {
            handler: function (newValue, oldValue) {
                newValue = newValue ?? this.defaultValue;
                this.cadence = {
                    ...newValue,
                    value: newValue?.value?.toString(),
                };
            },
            deep: true,
            immediate: true,
        },
        updateDueDate(newValue) {
            if (!!newValue) {
                this.computeNextCadenceDate(this.transformedCadence);
            }
        },
    },
    mounted() {
        if (this.cadence.unit !== 'never') {
            this.computeNextCadenceDate(this.transformedCadence);
        }
    },
    methods: {
        updateCadenceUnit(unit) {
            if (unit === 'never') {
                this.cadence.value = null;
                this.cadence.startDate = null;
            } else {
                this.cadence.value = this.everyOptions.first()?.value ?? null;
                this.cadence.startDate =
                    this.cadence.startDate ?? DateTime.now();
            }

            this.emitChanges();
        },
        emitChanges() {
            this.computeNextCadenceDate(this.transformedCadence);
            this.$emit('input', this.transformedCadence);
        },
        computeNextCadenceDate(cadence) {
            if (!this.nextDueDateRoute || this.isLoading) {
                return;
            }

            let startDate = cadence.startDate;
            if (!!startDate && typeof startDate === 'string') {
                startDate = DateTime.fromISO(startDate);
            }

            this.isLoading = true;
            const params = {
                ...cadence,
                startDate: startDate?.toFormat('yyyy-MM-dd'),
            };

            this.$http
                .get(this.nextDueDateRoute, { params })
                .then((resp) => {
                    this.cadenceInformation = resp.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
