<template>
    <tr v-if="stakeholders">
        <td v-text="title" />
        <td style="width: 15px; min-width: 5px" />
        <td>
            <div v-if="stakeholders.before?.length">
                <div
                    v-for="stakeholder in stakeholdersBeforeChanges"
                    :key="stakeholder.id"
                >
                    <div class="text-truncate" v-html="stakeholder.name" />
                </div>
            </div>
            <div v-else>{{ __('general.not_available') }}</div>
        </td>
        <td
            style="
                min-width: 30px;
                width: 50px;
                text-align: center;
                vertical-align: top;
            "
        >
            <c-icon class="mx-3" name="cil-arrow-right" size="sm" />
        </td>
        <td>
            <div v-if="stakeholders.after?.length">
                <div
                    v-for="stakeholder in stakeholders.after"
                    :key="stakeholder.id"
                >
                    <div class="text-truncate" v-html="stakeholder.name" />
                </div>
            </div>
            <div v-else>{{ __('general.not_available') }}</div>
        </td>
    </tr>
</template>
<script>
import { uniqBy } from 'lodash';

export default {
    name: 'stakeholder-changes-row',
    props: {
        title: {},
        stakeholders: {},
    },
    computed: {
        stakeholdersBeforeChanges() {
            return this.stakeholders.before;
        },
        allInvolvedStakeholders() {
            return uniqBy(
                (this.stakeholders.before || []).concat(
                    this.stakeholders.after || []
                ),
                'id'
            );
        },
    },
    methods: {
        sort(stakeholders) {
            return stakeholders.sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            );
        },
        hasBeenRemoved(id) {
            return !this.stakeholders.after.find((el) => el.id === id);
        },
    },
};
</script>
