<template>
    <auth-layout>
        <CContainer class="align-items-center min-vh-100">
            <CRow class="min-vh-100 justify-content-center align-items-center">
                <CCol md="6">
                    <CCard class="p-4 m-0 mt-5">
                        <CCardBody>
                            <CForm @submit.prevent="submit">
                                <h2>
                                    {{
                                        __(
                                            `auth.two_factor_challenge.${type}.heading`
                                        )
                                    }}
                                </h2>
                                <p class="text-muted">
                                    <template>
                                        {{
                                            __(
                                                `auth.two_factor_challenge.${type}.prompt`
                                            )
                                        }}
                                    </template>
                                </p>
                                <div v-if="!recovery">
                                    <jet-input
                                        ref="code"
                                        v-model="form.code"
                                        :placeholder="
                                            __(
                                                'auth.two_factor_challenge.placeholder.challenge'
                                            )
                                        "
                                        required
                                        :horizontal="false"
                                        autofocus
                                        inputmode="numeric"
                                        autocomplete="one-time-code"
                                        type="text"
                                        :invalid-feedback="form.errors.code"
                                    >
                                        <template #prepend>
                                            <c-icon name="cil-user" />
                                        </template>
                                    </jet-input>
                                </div>

                                <div v-else>
                                    <jet-input
                                        ref="recovery_code"
                                        v-model="form.recovery_code"
                                        :placeholder="
                                            __(
                                                'auth.two_factor_challenge.placeholder.recovery_code'
                                            )
                                        "
                                        required
                                        autofocus
                                        inputmode="numeric"
                                        autocomplete="one-time-code"
                                        type="text"
                                        :horizontal="false"
                                        :invalid-feedback="
                                            form.errors.recovery_code
                                        "
                                    >
                                        <template #prepend>
                                            <CIcon name="cil-user" />
                                        </template>
                                    </jet-input>
                                </div>
                                <div
                                    class="d-flex justify-content-between flex-wrap"
                                >
                                    <jet-button
                                        color="secondary"
                                        @click.prevent="toggleRecovery"
                                        class="mb-2"
                                    >
                                        <template>
                                            {{
                                                __(
                                                    `auth.two_factor_challenge.action.${type}`
                                                )
                                            }}
                                        </template>
                                    </jet-button>
                                    <jet-button
                                        type="submit"
                                        color="primary"
                                        class="px-4 mb-2"
                                        :disabled="form.processing"
                                        >{{
                                            __(
                                                'auth.two_factor_challenge.action.submit'
                                            )
                                        }}
                                    </jet-button>
                                </div>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import JetInput from '@/Jetstream/Input.vue';

export default {
    components: {
        JetInput,
        JetButton,
        AuthLayout,
    },

    data() {
        return {
            recovery: false,
            form: this.$inertia.form({
                code: '',
                recovery_code: '',
            }),
        };
    },

    computed: {
        type() {
            return this.recovery ? 'recovery_code' : 'challenge';
        },
    },

    methods: {
        toggleRecovery() {
            this.recovery ^= true;

            this.$nextTick(() => {
                let container = this.$refs.code;

                if (this.recovery) {
                    container = this.$refs.recovery_code;
                    this.$refs.recovery_code.$el
                        .getElementsByTagName('input')[0]
                        .focus();

                    this.form.code = '';
                } else {
                    this.$refs.code.$el
                        .getElementsByTagName('input')[0]
                        .focus();

                    this.form.recovery_code = '';
                }

                container.$el.getElementsByTagName('input')[0].focus();
            });
        },

        submit() {
            this.form.post(this.route('two-factor.login'));
        },
    },
};
</script>
