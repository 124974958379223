<template>
    <div class="overflow-auto">
        <table class="table table-sm table-unstyled">
            <tbody>
                <tr>
                    <th>Report:</th>
                    <td class="px-5 text-nowrap">
                        <span v-if="report.type === 'daily'">Daily</span>
                        <span v-if="report.type === 'weekly'">Weekly</span>
                    </td>
                </tr>
                <tr v-if="report.type === 'daily'">
                    <th class="text-nowrap">Report Date:</th>
                    <td class="px-5 text-nowrap">
                        {{
                            (report.reportedAt || report.endDate)
                                | fullDate(currentLocale)
                        }}
                    </td>
                </tr>
                <tr v-if="report.type === 'daily'">
                    <th class="text-nowrap">Report Time:</th>
                    <td class="px-5 text-nowrap">
                        <a
                            href="javascript:void(0)"
                            class="popover-no-header"
                            v-c-popover="{
                                placement: 'bottom',
                                header: '',
                                content:
                                    'Point in time the data was captured at each location.',
                            }"
                            ><span v-if="report.reportedAt">{{
                                report.reportedAt | time(currentLocale)
                            }}</span
                            ><span v-else>{{
                                __('general.not_available')
                            }}</span></a
                        >
                    </td>
                </tr>
                <tr v-if="report.type === 'daily'">
                    <th class="text-nowrap"># of Inoperable Assets:</th>
                    <td class="px-5 text-nowrap">
                        <span v-if="report.inoperableAssets?.data.length > 0">
                            {{ report.inoperableAssets.meta.total || 0 }}
                            of {{ totalAssetsCount }}
                        </span>
                        <span v-else>{{ __('general.not_available') }}</span>
                    </td>
                </tr>
                <tr v-if="report.type === 'weekly'">
                    <th class="text-nowrap">Timeframe:</th>
                    <td class="px-5 text-nowrap">
                        {{ report.startDate | fullDate(currentLocale) }}
                        -
                        {{ report.endDate | fullDate(currentLocale) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: 'report-meta-info',
    props: {
        report: {},
        totalAssetsCount: {},
    },
};
</script>
