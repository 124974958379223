<template>
    <app-layout>
        <asset-details-header
            v-if="asset"
            :asset="asset"
            @workorder-created="forceRerender"
        />
        <div class="py-3">
            <work-order-board
                :key="`wb-${componentKey}`"
                :selected-work-order="selectedWorkOrder?.id"
                :swimlanes="swimlanes"
                class="py-3"
                @close="selectedWorkOrder = null"
                @selected="selectedWorkOrder = $event"
                @load-details="loadWorkOrderDetails"
                @status-transitioned="handleWorkOrderStatusTransition"
            />

            <h3 class="px-2 py-2 h5">
                {{ __('assets.workorders.records.title') }}
                <info-icon
                    :tooltip="__('assets.workorders.records.info')"
                    style="position: relative; top: -1px"
                />
            </h3>

            <work-order-records
                :key="`wr-${componentKey}`"
                :work-orders="archivedWorkOrders"
                @click="loadWorkOrderDetails"
            />

            <work-order-details-modal
                :editable="isSelectedWorkOrderEditable"
                :show="activeModal === 'WorkOrderDetailsModal'"
                :work-order-id="selectedWorkOrder?.id"
                @close="closeModal"
            />

            <asset-status-change-prompt-modal
                :show="activeModal === 'AssetStatusChangePromptModal'"
                :asset-id="assetStatusChange.assetId"
                :operability="assetStatusChange.operability"
                :reason="assetStatusChange.reason"
                @close="closeModal"
                @cancel="closeModal"
            />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import WorkOrderDetailsModal from '@/Pages/WorkOrders/Modals/WorkOrderDetailsModal.vue';
import ConfirmWorkOrderDeletionModal from '@/Pages/WorkOrders/Modals/ConfirmWorkOrderDeletionModal.vue';
import WorkOrderRecords from '@/Pages/WorkOrders/WorkOrderRecords.vue';
import WorkOrderBoard from '@/Pages/WorkOrders/WorkOrderBoard.vue';
import InfoIcon from '@/Components/InfoIcon.vue';
import AssetStatusChangePromptModal from '@/Pages/WorkOrders/Modals/AssetStatusChangePromptModal.vue';

export default {
    components: {
        AssetStatusChangePromptModal,
        InfoIcon,
        WorkOrderBoard,
        WorkOrderRecords,
        ConfirmWorkOrderDeletionModal,
        WorkOrderDetailsModal,
        AssetDetailsHeader,
        AppLayout,
    },
    data() {
        return {
            componentKey: 0,
            activeModal: null,
            selectedWorkOrder: null,
            assetStatusChange: {
                assetId: null,
                operability: 'inoperable',
                reason: 'corrective_maintenance_required',
            },
        };
    },
    computed: {
        asset() {
            return this.$page.props.asset;
        },
        workOrders() {
            return this.$page.props.workOrders?.data || [];
        },
        archivedWorkOrders() {
            return this.$page.props.archivedWorkOrders;
        },
        swimlanes() {
            return this.$page.props.swimlanes;
        },
        isSelectedWorkOrderEditable() {
            return (
                (this.selectedWorkOrder?.isEditable &&
                    this.hasAnyPermission('workorder.update')) ||
                false
            );
        },
    },
    mounted() {
        this.loadWorkOrderDetails(this.$page.props.selectedWorkOrder);
    },
    watch: {
        selectedWorkOrder(workOrder) {
            const currentRoute = this.route().current();
            const urlToSet = !workOrder
                ? this.route(currentRoute, { asset: this.asset.id })
                : this.route(currentRoute, {
                      asset: this.asset.id,
                      workorder: workOrder.id,
                  });

            window.history.replaceState({}, '', urlToSet);
        },
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        },
        closeModal() {
            this.activeModal = null;
            this.forceRerender();

            setTimeout(() => {
                this.selectedWorkOrder = null;
            }, 300);
        },
        loadWorkOrderDetails(workOrder) {
            if (!!this.activeModal || !workOrder) {
                return;
            }

            this.selectedWorkOrder = workOrder;
            this.activeModal = 'WorkOrderDetailsModal';
        },
        handleWorkOrderStatusTransition({ workOrder, status }) {
            this.forceRerender();

            this.$http
                .get(this.route('workorders.show', workOrder.id))
                .then((response) => {
                    let asset = response.data.workOrder.asset;
                    let operability =
                        response.data.workOrder.asset.status.operability;

                    if (status === 'to_do') {
                        return;
                    }

                    switch (`${status}:${operability}`) {
                        case 'in_progress:operable':
                        case 'resolved:inoperable':
                        case 'closed:inoperable':
                            this.assetStatusChange.operability =
                                operability === 'operable'
                                    ? {
                                          value: 'inoperable',
                                          label: this.__(
                                              'assets.modal.operability_change.label.inoperable'
                                          ),
                                      }
                                    : {
                                          value: 'operable',
                                          label: this.__(
                                              'assets.modal.operability_change.label.operable'
                                          ),
                                      };
                            this.assetStatusChange.assetId = asset.id;
                            this.assetStatusChange.reason = workOrder.type;
                            this.activeModal = 'AssetStatusChangePromptModal';
                            break;
                    }
                });
        },
    },
};
</script>
