<template>
    <c-dropdown
        :key="componentKey"
        :class="{ 'opacity-50': disabled }"
        :disabled="disabled"
        add-menu-classes="p-0"
    >
        <template #toggler>
            <div
                class="d-flex align-items-center justify-content-between border-secondary px-2 py-1 rounded"
                style="cursor: pointer; gap: 10px"
            >
                <div class="text-nowrap">
                    {{ selectedOption?.label || '-' }}
                </div>
                <chevron-down />
            </div>
        </template>
        <div style="max-height: 200px; overflow: auto">
            <c-dropdown-item
                v-if="resettable"
                :class="labelClasses"
                @click.stop="$emit('input', null)"
                >-
            </c-dropdown-item>
            <c-dropdown-item
                v-for="option in options"
                :key="option.value"
                :class="labelClasses"
                :disabled="isDisabled(option)"
                @click.stop="$emit('input', option.value)"
            >
                {{ option.label }}
            </c-dropdown-item>
        </div>
    </c-dropdown>
</template>
<script>
import ChevronDown from '@/../icons/ChevronDown.vue';

export default {
    name: 'SimpleSelect',
    components: { ChevronDown },
    props: {
        value: {},
        options: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        resettable: {
            type: Boolean,
            default: false,
        },
        labelClasses: {
            type: String | Array,
        },
        min: {
            type: Number,
        },
    },
    data() {
        return {
            componentKey: 0,
        };
    },
    computed: {
        selectedOption() {
            return this.options.find((option) => option.value === this.value);
        },
    },
    watch: {
        options: {
            handler: function () {
                this.componentKey += 1;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        isDisabled(option) {
            if (!this.min) {
                return false;
            }

            return (option.numericValue ?? 0) < this.min;
        },
    },
};
</script>
<style scoped>
>>> .dropdown-menu {
    min-width: 0;
}
</style>
