<template>
    <modal :show="show" :max-width="maxWidth" :type="type">
        <template #title>
            <slot name="title"></slot>
        </template>
        <template #content><slot name="content"></slot></template>
        <template #footer>
            <jet-button @click="$emit('close')" color="secondary">
                {{ __('general.action.cancel') }}
            </jet-button>
            <slot name="footer"></slot>
        </template>
    </modal>
</template>

<script>
import Modal from '@/Jetstream/Modal.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    name: 'JetConfirmationModal',

    emits: ['close'],

    components: {
        Modal,
        JetButton,
    },

    props: {
        type: {
            default: '',
        },
        show: {
            default: false,
        },
        maxWidth: {
            default: '',
        },
        closeable: {
            default: true,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
