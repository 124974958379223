<template>
    <auth-layout>
        <CContainer class="align-items-center min-vh-100">
            <CRow class="min-vh-100 justify-content-center align-items-center">
                <CCol md="6">
                    <CCard class="p-4 m-0">
                        <CCardBody>
                            <CForm @submit.prevent="submit">
                                <h1>{{ __('auth.forgot_password.header') }}</h1>
                                <p class="text-muted">
                                    {{
                                        __('auth.confirm_password.description')
                                    }}
                                </p>
                                <jet-input
                                    id="password"
                                    v-model="form.password"
                                    :placeholder="__('auth.general.password')"
                                    type="password"
                                    autocomplete="current-password"
                                    required
                                    :invalidFeedback="form.errors.password"
                                />
                                <div class="d-flex justify-content-end">
                                    <jet-button
                                        type="submit"
                                        color="primary"
                                        class="px-4"
                                        :disabled="form.processing"
                                        >{{
                                            __(
                                                'auth.confirm_password.action.submit'
                                            )
                                        }}</jet-button
                                    >
                                </div>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </auth-layout>
</template>

<script>
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';

export default {
    components: {
        JetButton,
        JetInput,
        JetValidationErrors,
        AuthLayout,
    },

    data() {
        return {
            form: this.$inertia.form({
                password: '',
            }),
        };
    },

    methods: {
        submit() {
            this.form.post(this.route('password.confirm'), {
                onFinish: () => this.form.reset(),
            });
        },
    },
};
</script>
