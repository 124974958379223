<template>
    <div>
        <c-row :class="{ 'mb-2': !horizontal }">
            <div :class="labelClasses">
                {{ __('maintainers.sla.time_to_respond.title') }}:
            </div>
            <div :class="inputClasses">
                <div class="d-flex align-items-center" style="gap: 8px">
                    <high
                        v-c-tooltip="{
                            content: 'High',
                            popperOptions: { positionFixed: true },
                        }"
                    />
                    <span
                        v-if="sla?.timeToRespond.high.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla?.timeToRespond.high)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        __('general.not_available')
                    }}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 8px">
                    <medium
                        v-c-tooltip="{
                            content: 'Medium',
                            popperOptions: { positionFixed: true },
                        }"
                    />
                    <span
                        v-if="sla?.timeToRespond.medium.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla?.timeToRespond.medium)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        __('general.not_available')
                    }}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 8px">
                    <low
                        v-c-tooltip="{
                            content: 'Low',
                            popperOptions: { positionFixed: true },
                        }"
                    />
                    <span
                        v-if="sla?.timeToRespond.low.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla?.timeToRespond.low)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        __('general.not_available')
                    }}</span>
                </div>
            </div>
        </c-row>
        <c-row :class="{ 'mb-2': !horizontal }">
            <div :class="labelClasses">
                {{ __('maintainers.sla.time_to_resolve.title') }}:
            </div>
            <div :class="inputClasses">
                <div class="d-flex align-items-center" style="gap: 8px">
                    <high v-c-tooltip="{ content: 'High' }" />
                    <span
                        v-if="sla?.timeToResolve.high.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla?.timeToResolve.high)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        __('general.not_available')
                    }}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 8px">
                    <medium
                        v-c-tooltip="{
                            content: 'Medium',
                            popperOptions: { positionFixed: true },
                        }"
                    />
                    <span
                        v-if="sla?.timeToResolve.medium.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla?.timeToResolve.medium)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        __('general.not_available')
                    }}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 8px">
                    <low
                        v-c-tooltip="{
                            content: 'Low',
                            popperOptions: { positionFixed: true },
                        }"
                    />
                    <span
                        v-if="sla?.timeToResolve.low.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla?.timeToResolve.low)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        __('general.not_available')
                    }}</span>
                </div>
            </div>
        </c-row>
        <c-row :class="{ 'mb-2': !horizontal }">
            <div :class="labelClasses">
                {{ __('maintainers.sla.working_hours.title') }}:
            </div>
            <div :class="inputClasses">
                <div v-if="sla?.workingHours?.length" class="text-gray">
                    <div v-if="typeof sla?.workingHours === 'string'">
                        {{ sla?.workingHours }}
                    </div>
                    <div v-else>
                        <div
                            v-for="weekday in sla?.workingHours"
                            :key="weekday.day"
                        >
                            {{ getWorkingHourLabel(weekday) }}
                        </div>
                    </div>
                </div>
                <div v-else class="text-gray">
                    {{ __('general.not_available') }}
                </div>
            </div>
        </c-row>
        <c-row :class="{ 'mb-2': !horizontal }">
            <div :class="labelClasses">
                {{ __('maintainers.sla.agreement_term.title') }}:
            </div>
            <div :class="inputClasses">
                <table class="table table-unstyled">
                    <tr>
                        <td class="text-gray" style="width: 50px">
                            {{
                                __('maintainers.sla.agreement_term.from.title')
                            }}
                        </td>
                        <td class="text-gray">
                            <span v-if="sla?.agreement.startDate">
                                {{
                                    sla?.agreement.startDate
                                        | fullDate(currentLocale)
                                }}
                            </span>
                            <span v-else>{{
                                __('general.not_available')
                            }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-gray">
                            {{ __('maintainers.sla.agreement_term.to.title') }}
                        </td>
                        <td
                            :class="{
                                'text-danger': showExpirationWarning,
                                'text-gray': !showExpirationWarning,
                            }"
                        >
                            <span v-if="sla?.agreement.endDate">
                                {{
                                    sla?.agreement.endDate
                                        | fullDate(currentLocale)
                                }}
                            </span>
                            <span v-else>{{
                                __('general.not_available')
                            }}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </c-row>
    </div>
</template>
<script>
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';
import Low from '@/../icons/Low.vue';
import High from '@/../icons/High.vue';
import Medium from '@/../icons/Medium.vue';
import { getWeekday } from '@/Pages/Stakeholders/Maintainers/weekdays.js';

export default {
    name: 'ServiceLevelAgreementInfo',
    components: { Medium, High, Low, WorkOrderPriority },
    props: {
        sla: {
            type: Object,
            default: () => {},
        },
        showExpirationWarning: {
            type: Boolean,
            default: false,
        },
        horizontal: { type: Boolean | Object, default: false }, // {sla', '
    },
    computed: {
        labelClasses() {
            if (!this.horizontal) {
                return ['col-md-12'];
            }
            return [this.horizontal.label || 'col-md-6'];
        },
        inputClasses() {
            if (!this.horizontal) {
                return 'col-md-12';
            }
            return this.horizontal.input || 'col-md-6';
        },
    },
    methods: {
        getWorkingHourLabel(weekday) {
            let dayOfWeek = getWeekday(weekday.day, this.currentLocale)?.label;
            return `${dayOfWeek}, ${weekday.from}-${weekday.to}`;
        },
        getTimeDurationLabel(duration) {
            let durationLabel = this.__(`general.${duration.unit}`);
            return `${duration.value} ${durationLabel}`;
        },
    },
};
</script>
