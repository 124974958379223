<template>
    <c-dropdown :show.sync="show" add-menu-classes="p-0">
        <template #toggler>
            <jet-input
                v-model="form.search"
                :horizontal="horizontal"
                :invalidFeedback="error"
                :label="label"
                :placeholder="placeholder"
                :required="required"
                @blur="closeModel"
                @focus="handleFocus"
                @keyup="search($event)"
                @click.native="handleClick"
            >
                <template #append>
                    <c-spinner
                        v-if="form.processing"
                        color="primary"
                        size="sm"
                    />
                    <Search v-else />
                </template>
            </jet-input>
        </template>
        <div
            v-if="assets.data?.length > 0"
            class="overflow-auto"
            style="max-height: 153px"
        >
            <CDropdownItem
                v-for="asset in assets.data"
                :key="asset.id"
                class="py-1"
                @click.stop="select(asset)"
                >{{ getLabel(asset) }}
            </CDropdownItem>
        </div>
        <CDropdownItem v-else :disabled="true" class="py-1"
            >No assets found
        </CDropdownItem>
        <div
            v-if="assets.data?.length > 0"
            :class="{ 'add-shadow': assets.data?.length > 5 }"
            class="d-flex justify-content-end px-3"
        >
            <small class="text-muted"
                >{{ assets.meta?.total }}/{{ assets.meta?.all }}</small
            >
        </div>
    </c-dropdown>
</template>
<script>
import JetInput from '@/Jetstream/Input.vue';
import Search from '../../../../icons/Search.vue';
import { debounce } from 'lodash';

export default {
    name: 'asset-selection-field',
    components: { Search, JetInput },
    emits: ['selected'],
    props: {
        label: String,
        error: String,
        horizontal: Boolean,
        placeholder: String,
        required: {
            type: Boolean,
            default: false,
        },
        value: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        searchUrl: String,
    },
    data() {
        return {
            show: false,
            assets: {},
            form: this.$inertia.form({ search: null }),
            abortController: null,
        };
    },
    methods: {
        search: debounce(function (event) {
            this.abortController?.abort();
            this.abortController = new AbortController();

            this.form.processing = true;

            this.$http
                .get(this.searchUrl, {
                    signal: this.abortController.signal,
                    params: { search: this.form.search },
                    headers: {
                        'X-Inertia-Partial-Data': 'searchResults',
                        'X-Inertia-Partial-Component': 'WorkOrders/Overview',
                        'X-Inertia': true,
                        'X-Inertia-Version': this.$inertia.page.version,
                    },
                })
                .then((resp) => {
                    const { searchResults } = resp.data.props;

                    if (searchResults.meta.current_page === 1) {
                        this.assets = Object.assign(
                            {},
                            this.assets,
                            searchResults
                        );
                    } else {
                        this.assets.links = searchResults.links;
                        this.assets.meta = searchResults.meta;
                        this.assets.data.push(...searchResults.data);
                    }
                })
                .catch((e) => {})
                .finally(() => {
                    this.form.processing = false;
                    this.show = true;
                });
        }, 300),
        getLabel(asset) {
            return [
                asset.type.value,
                asset.serialNumber,
                asset.location.airport,
                asset.location.name,
                asset.manufacturer.name,
            ]
                .filter((el) => !!el)
                .join(' / ');
        },
        select(asset) {
            this.form.search = this.getLabel(asset);
            this.show = false;
            this.$emit('selected', asset);
        },
        handleClick(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        },
        handleFocus(event) {
            this.show = true;
            this.form.reset();
            this.form.clearErrors();
        },
        closeModel: debounce(function () {
            this.show = false;
        }, 150),
    },
};
</script>
<style scoped>
>>> .dropdown-menu {
    width: calc(100% - 34px);
}
</style>
