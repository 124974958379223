<template>
    <div>
        <div
            :class="{ editable, active, bordered }"
            class="inline-editable-field"
        >
            <textarea
                v-if="active && editable"
                ref="textarea"
                :class="{
                    'is-invalid': invalidFeedback,
                    'not-resizable': !resizable,
                }"
                :placeholder="placeholder"
                :readonly="!editable"
                :rows="rows"
                :style="{
                    minHeight: `${minHeight}px`,
                    padding: rows === 1 ? 0 : '8px',
                }"
                :value="value"
                class="w-100 form-control"
                @blur="blur"
                @input="update($refs.textarea.value)"
                @keydown="handleKeyDown($event)"
            />
            <div
                v-else
                ref="textarea"
                :class="{
                    'not-resizable': !resizable,
                    'is-invalid': invalidFeedback,
                    'form-control': editable,
                    padded: rows > 1,
                }"
                :style="{ minHeight: `${height}px` }"
                v-html="htmlContent"
                @click.stop="activate($event)"
            />
            <small
                v-if="invalidFeedback"
                class="invalid-feedback"
                style="display: block; font-size: 12px"
                v-text="invalidFeedback"
            />
        </div>
    </div>
</template>
<script>
import { pipe } from '@/utils';

export default {
    name: 'inline-editable-field',
    props: {
        value: {},
        editable: {
            type: Boolean,
            default: true,
        },
        rows: {
            type: Number,
            default: 1,
        },
        placeholder: {},
        lineHeight: {
            type: Number,
            default: 34,
        },
        invalidFeedback: {},
        resizable: {
            type: Boolean,
            default: true,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            active: false,
            height: 0,
        };
    },
    computed: {
        minHeight() {
            return this.rows * this.lineHeight;
        },
        htmlContent() {
            if (!this.value && !!this.placeholder) {
                return `<span class="text-muted">${this.placeholder}</span>`;
            }
            return pipe(this.value)
                .to(this.$options.filters.linkify)
                .to(this.$options.filters.nl2br).value;
        },
    },
    mounted() {
        this.resize();
    },
    methods: {
        activate(event) {
            if (!this.editable) {
                return;
            }

            event.stopImmediatePropagation();

            if (event.target.nodeName === 'A') {
                return;
            }

            this.active = true;

            this.$nextTick(() => {
                this.$refs.textarea.focus();
                this.resize();
            });
        },
        blur() {
            this.update(this.value?.trim());

            this.$nextTick(() => {
                this.active = false;
                this.resize();
            });
        },
        resize() {
            let element = this.$refs.textarea;

            if (!this.resizable || !element) {
                return;
            }

            let minHeight = this.minHeight;
            element.style.height = `${minHeight}px`;

            this.height =
                element.scrollHeight > minHeight
                    ? element.scrollHeight
                    : minHeight;

            element.style.height = `${this.height}px`;
        },
        update(value) {
            this.$emit('input', value);
            this.resize();
        },
        handleKeyDown(event) {
            if (event.keyCode === 13 && !this.resizable) {
                event.preventDefault();
                this.$refs.textarea.blur();
            }
        },
    },
};
</script>
<style scoped>
.bordered {
    border-radius: 5px;
    border: 1px solid #e4e7ea;
}

.bordered .form-control {
    padding: 8px !important;
}

.inline-editable-field .form-control {
    padding: 0;
}

.inline-editable-field .padded {
    padding: 8px;
}

.inline-editable-field.editable .form-control {
    resize: none;
    overflow: hidden;
    cursor: text;
    color: #333;
    font-size: inherit;
    border: none !important;
}

.inline-editable-field.editable .form-control:hover,
.inline-editable-field.editable .form-control:focus {
    border: none;
}

.inline-editable-field.editable .not-resizable {
    white-space: pre;
    overflow-wrap: normal;
    height: auto;
}

.inline-editable-field:not(.active) .form-control {
    border-color: transparent;
}

.inline-editable-field.editable:not(.active) .form-control:hover {
    background-color: #091e420f;
}

.inline-editable-field.editable .form-control.is-invalid {
    border-color: #ff174d;
}
</style>
