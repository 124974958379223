<template>
    <div class="overflow-x-hidden d-flex" :class="classes" style="gap: 3px">
        <div
            class="d-flex justify-content-between align-items-center"
            style="min-height: 44px"
        >
            <slot name="visible-filter" />
            <c-spinner
                v-if="isProcessing && !isOpen"
                size="sm"
                color="primary"
                class="mx-2 d-sm-none"
            />
            <c-link
                v-else
                class="d-flex align-items-center d-sm-none"
                @click.stop="isOpen = !isOpen"
                style="gap: 5px"
            >
                <c-icon
                    class="flex-shrink-0"
                    name="cil-list-filter"
                    style="position: relative; top: -1px"
                />
                <span>{{ __('general.table.filter.label') }}</span>
            </c-link>
        </div>
        <CCollapse :show="isOpen" class="d-sm-flex">
            <div class="d-flex" :class="classes" style="gap: 3px">
                <slot />
                <div class="d-flex justify-content-between align-items-center">
                    <slot name="filter-end" />
                </div>
            </div>
        </CCollapse>
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';

export default {
    name: 'CollapsableFilterBar',
    components: { JetButton },
    props: {
        isProcessing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            width: document.body.clientWidth,
        };
    },
    computed: {
        classes() {
            if (this.width < 576) {
                return 'flex-column';
            }

            return ['flex-row', 'align-items-center'];
        },
    },
    created() {
        window.addEventListener('resize', this.updateSize);
    },
    destroyed() {
        window.removeEventListener('resize', this.updateSize);
    },
    methods: {
        updateSize() {
            this.width = document.body.clientWidth;

            if (this.width > 575) {
                this.isOpen = true;
            }
        },
    },
};
</script>
