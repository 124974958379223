<template>
    <Link v-if="hasAnyPermission(['user.read'])"
          :href="route('users.show', info.id)"
          @click.stop
    >
        <div class="text-nowrap">{{ info.name }}</div>
        <small v-if="info.email" class="d-block text-muted">{{ info.email }}</small>
    </Link>
    <div v-else>
        <div class="text-nowrap">{{ info.name }}</div>
        <small v-if="info.email" class="d-block text-muted">{{ info.email }}</small>
    </div>
</template>
<script>
export default {
    props: ['info'],
};
</script>
