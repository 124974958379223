<template>
    <li class="d-flex align-items-start" style="gap: 5px">
        <span style="width: 20px; height: 16px; flex-shrink: 0">
            <c-icon
                v-if="isValid === true"
                name="cil-check-alt"
                style="height: 22px"
            />
            <c-icon
                v-if="isValid === false"
                name="cil-x"
                class="text-danger"
                style="height: 22px"
            />
            <b v-if="isValid === undefined">&#x2022;</b>
        </span>
        <span :class="{ 'text-danger': isValid === false }">{{ text }}</span>
    </li>
</template>
<script>
export default {
    name: 'password-rule-validation-line',
    props: {
        isValid: {
            type: Boolean,
            default: undefined,
        },
        text: {
            type: String,
            default: '',
        },
    },
};
</script>
