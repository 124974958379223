<template>
    <auth-layout>
        <CContainer fluid>
            <CRow>
                <c-col>
                    <CAlert
                        color="success"
                        v-if="status"
                        v-html="status"
                        style="position: relative; top: 100px"
                    />
                </c-col>
            </CRow>
        </CContainer>
        <CContainer class="align-items-center min-vh-100">
            <CRow class="min-vh-100 justify-content-center align-items-center">
                <CCol md="10" lg="8">
                    <CCard class="p-4 m-0">
                        <CCardBody>
                            <CForm @submit.prevent="submit">
                                <h1>{{ __('auth.forgot_password.header') }}</h1>
                                <p class="text-muted">
                                    {{ __('auth.forgot_password.description') }}
                                </p>
                                <CInput
                                    id="email"
                                    v-model="form.email"
                                    prependHtml="<i class='cui-user'></i>"
                                    :placeholder="__('auth.general.email')"
                                    autocomplete="username email"
                                    required
                                    autofocus
                                    :isValid="!hasErrors ? null : false"
                                    class="mb-0"
                                >
                                    <template #prepend-content>
                                        <CIcon name="cil-user"/>
                                    </template>
                                </CInput>
                                <CRow class="mb-4">
                                    <CCol>
                                        <jet-validation-errors/>
                                    </CCol>
                                </CRow>
                                <div
                                    class="d-sm-flex justify-content-end align-items-center text-center"
                                    style="flex-wrap: wrap-reverse"
                                >
                                    <jet-button
                                        type="submit"
                                        color="primary"
                                        class="px-4"
                                        :is-processing="form.processing"
                                        :disabled="!form.email"
                                        style="order: 2"
                                    >{{
                                            __(
                                                'auth.forgot_password.action.submit'
                                            )
                                        }}
                                    </jet-button>
                                    <Link
                                        :href="route('login')"
                                        class="text-muted px-3 d-block text-center btn btn-link"
                                        style="order: 1; min-height: 28px"
                                    >
                                        {{ __('general.action.cancel') }}
                                    </Link>
                                </div>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';

export default {
    components: {
        JetButton,
        JetValidationErrors,
        AuthLayout,
    },

    data() {
        return {
            form: this.$inertia.form({
                email: '',
            }),
        };
    },

    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },

        status() {
            return this.$page.props.status;
        },
    },

    methods: {
        submit() {
            this.form.post(this.route('password.email'));
        },
    },
};
</script>
