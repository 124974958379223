<template>
    <div v-if="hasAnyPermission('asset.pid')">
        <div>
            <small class="text-muted text-truncate">{{
                __('assets.overview.issue.onboarding_incomplete')
            }}</small>
        </div>
        <a class="clickable-badge" @click.stop="$emit('open-scanner')">
            <CBadge color="primary"
                >{{ __('assets.overview.scanner.title') | uppercase }}
            </CBadge>
        </a>
    </div>
</template>
<script>
export default {
    name: 'onboarded-asset-issue',
    emits: 'open-scanner',
};
</script>
