<template>
    <div :style="calculatedStyle" class="bulk-action-overlay">
        <div
            :class="{ 'max-w-vw': jobName }"
            class="d-flex justify-content-between align-items-center max-w"
        >
            <div class="d-flex align-items-center" style="min-width: 0">
                <jet-select
                    v-model="jobName"
                    :horizontal="false"
                    :options="availableBatchJobs"
                    class="mb-0"
                    placeholder="Select an action"
                    style="min-width: 0"
                />
                <div class="mx-2 text-nowrap">
                    for
                    {{ allSelected ? 'all' : selectedItems.length }} asset(s)
                </div>
            </div>
            <div>
                <CButton
                    v-c-tooltip="{
                        content: 'Cancel',
                        popperOptions: {
                            positionFixed: true,
                        },
                    }"
                    :class="{ 'd-none': jobName && selectedItems.length }"
                    class="text-white d-md-block btn-icon"
                    @click.stop="resetForm"
                >
                    <CIcon name="cil-x" size="sm" />
                </CButton>
                <CButton
                    v-if="jobName"
                    :class="{
                        'd-sm-block': jobName && selectedItems.length,
                        'd-none': !selectedItems.length,
                    }"
                    class="text-white d-md-none btn-icon"
                    @click.stop="toggleOverlay"
                >
                    <CIcon
                        v-if="!isCollapsed"
                        name="cil-chevron-bottom"
                        size="sm"
                    />
                    <CIcon v-else name="cil-chevron-top" size="sm" />
                </CButton>
            </div>
        </div>
        <div
            v-if="jobName && selectedItems.length && selectedJob"
            :class="{ 'd-none': isCollapsed }"
            class="d-md-flex mt-3 mt-md-2 justify-content-between align-items-center d-md-block"
        >
            <AssetCadenceConfiguration
                v-if="selectedJob.customForm === 'asset-cadence-configuration'"
                :config="selectedJob.config"
                @update="updatePayload($event)"
            />
            <CustomForm
                v-else
                :config="selectedJob.config"
                @update="updatePayload"
            />
            <div
                class="d-flex align-items-center justify-content-between ml-md-3 mt-3 mt-md-0"
            >
                <jet-button
                    :disabled="!submittable"
                    :is-processing="isRequestInProgress"
                    color="secondary"
                    @click="triggerBatchJob"
                >
                    <span>{{ selectedJob.actionButtonTitle }}</span>
                </jet-button>
                <Link class="text-white d-md-none" @click.stop="resetForm"
                    >{{ __('general.action.cancel') }}
                </Link>
            </div>
        </div>
    </div>
</template>
<script>
import JetSelect from '@/Jetstream/Select.vue';
import AssetCadenceConfiguration from './AssetCadenceConfiguration.vue';
import CustomForm from '@/Pages/Assets/BatchJobs/CustomForm.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    name: 'BatchJobs',
    components: { JetButton, CustomForm, AssetCadenceConfiguration, JetSelect },
    props: {
        selectedItems: {},
    },
    data() {
        return {
            isCollapsed: false,
            jobName: null,
            payload: null,
            isRequestInProgress: false,
        };
    },
    computed: {
        calculatedStyle() {
            return this.selectedItems && this.selectedItems.length
                ? 'bottom: 10px'
                : 'bottom: -100px';
        },
        submittable() {
            return (
                this.selectedItems &&
                this.selectedItems.length &&
                this.jobName &&
                this.payload &&
                !this.isRequestInProgress
            );
        },
        bulkActions() {
            return this.$page.props.bulkActions;
        },
        availableBatchJobs() {
            return this.bulkActions.map((action) => ({
                label: action.title,
                value: action.name,
            }));
        },
        selectedJob() {
            return this.bulkActions.find((job) => this.jobName === job.name);
        },
        allSelected() {
            return (
                this.selectedItems.length === 1 && this.selectedItems[0] === '*'
            );
        },
    },
    methods: {
        triggerBatchJob() {
            if (this.isRequestInProgress) {
                return;
            }

            this.isRequestInProgress = true;

            this.$inertia.post(
                this.route('batch.dispatch', this.jobName),
                {
                    ids: this.selectedItems,
                    payload: this.payload,
                },
                {
                    preserveState: true,
                    onFinish: () => {
                        this.isRequestInProgress = false;
                        this.resetForm();
                    },
                }
            );
        },
        updatePayload(value) {
            this.payload = value;
        },
        toggleOverlay() {
            this.isCollapsed = !this.isCollapsed;
        },
        resetForm() {
            this.payload = null;
            this.jobName = null;
            this.$emit('reset');
        },
    },
};
</script>
<style scoped>
.bulk-action-overlay {
    background: #3e56c6;
    color: #fff;
    position: fixed;
    bottom: -100px;
    padding: 10px;
    border-radius: 5px;
    z-index: 9999;
    transition: 0.3s bottom ease-in-out;
    max-width: calc(100vw - 30px);
}

>>> .option-reset {
    display: none;
}

.max-w-vw {
    max-width: calc(100vw - 30px) !important;
}

.max-w {
    max-width: 100%;
}

>>> .dropdown-toggle {
    padding-left: 10px;
}

>>> .dropdown-menu {
    max-width: none;
}
</style>
