<template>
    <app-layout>
        <CCard>
            <CustomCardHeader
                :hasPermission="hasAnyPermission('physical_location.create')"
                :href="route('airports.create')"
                :label="__('airports.overview.action.add_airport')"
                :title="__('airports.overview.title')"
            />
            <CCardBody>
                <div class="d-flex justify-content-end mb-1">
                    <small class="text-muted"
                    >{{
                            __('airports.overview.results.count', {
                                number_of_airports: `${transformedAirports.length}`,
                            })
                        }}
                    </small>
                </div>
                <sticky-table-header>
                    <CDataTable
                        v-if="airports"
                        :border="true"
                        :clickableRows="true"
                        :fields="visibleFields"
                        :items="transformedAirports"
                        :noItemsView="noItemsView"
                        :outlined="true"
                        :sorter="{ external: true, resetable: false }"
                        :sorter-value="sortOrder"
                        :striped="true"
                        hover
                        @update:sorter-value="updateSorting"
                        @row-clicked="edit"
                    >
                        <template #sorting-icon="{ classes, state }">
                            <sort-icon :class="classes" :state="state"/>
                        </template>
                        <template #icao_code="{ item }">
                            <td>
                                {{ item.icao_code }}
                            </td>
                        </template>

                        <template #iata_code="{ item }">
                            <td>
                                {{ item.iata_code }}
                            </td>
                        </template>

                        <template #name="{ item }">
                            <td>
                                <div>
                                    {{ item.name }}
                                </div>
                                <div class="d-flex align-items-center">
                                    <current-time :timezone="item.timezone"/>
                                </div>
                            </td>
                        </template>

                        <template #country="{ item }">
                            <td>
                                <div
                                    class="d-flex align-items-center text-center"
                                >
                                    <CIcon
                                        v-if="item.country_code_alpha2"
                                        :name="
                                            'cif-' + item.country_code_alpha2
                                        "
                                        class="mx-1"
                                    />
                                    {{ item.country }}
                                </div>
                            </td>
                        </template>

                        <template #users="{ item }">
                            <td>
                                <div
                                    class="d-flex align-items-center text-center"
                                >
                                    <span
                                        v-c-tooltip="{
                                            content: __(
                                                'airports.overview.cell.users.tooltip.active'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                    >{{
                                            getActiveUsers(item.users).length
                                        }}</span
                                    >&nbsp;/&nbsp;
                                    <span
                                        v-c-tooltip="{
                                            content: __(
                                                'airports.overview.cell.users.tooltip.total'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                    >{{ item.users.length }}</span
                                    >
                                </div>
                            </td>
                        </template>

                        <template #assets="{ item }">
                            <td>
                                <div
                                    class="d-flex align-items-center text-center"
                                >
                                    {{ item.assets_count }}
                                </div>
                            </td>
                        </template>

                        <template #actions="{ item }">
                            <td class="align-middle">
                                <div class="d-flex justify-content-end">
                                    <jet-button
                                        v-if="
                                            hasAnyPermission(
                                                'physical_location.update'
                                            )
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'airports.overview.tooltip.edit_airport'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="primary"
                                    >
                                        <CIcon name="cil-pencil"/>
                                    </jet-button>
                                    <jet-button
                                        v-if="
                                            hasAnyPermission(
                                                'physical_location.delete'
                                            )
                                        "
                                        v-c-tooltip="{
                                            content: __(
                                                'airports.overview.tooltip.delete_airport'
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        class="mx-1 btn-icon"
                                        color="danger"
                                        @click.stop="deleteAirport(item)"
                                    >
                                        <CIcon name="cil-trash"/>
                                    </jet-button>
                                </div>
                            </td>
                        </template>
                    </CDataTable>
                </sticky-table-header>
            </CCardBody>
            <CCardFooter>
                <Pagination :paginator="airports"/>
            </CCardFooter>
        </CCard>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import CurrentTime from '@/Components/CurrentTime.vue';
import SortIcon from "@/Pages/Assets/SortIcon.vue";

export default {
    components: {
        SortIcon,
        CurrentTime,
        StickyTableHeader,
        AppLayout,
        CustomCardHeader,
        JetButton,
        Pagination,
    },

    data() {
        return {
            sortOrder: {},
            allFields: [
                {
                    key: 'icao_code',
                    label: this.__('airports.overview.cell.icao'),
                },
                {
                    key: 'iata_code',
                    label: this.__('airports.overview.cell.iata'),
                },
                {
                    key: 'name',
                    label: this.__('airports.overview.cell.name'),
                },
                {
                    key: 'country',
                    label: this.__('airports.overview.cell.country'),
                    sorter: false,
                },
                {
                    key: 'users',
                    label: this.__('airports.overview.cell.users'),
                    sorter: false,
                },
                {
                    key: 'assets',
                    label: this.__('airports.overview.cell.assets'),
                    sorter: false,
                },
                {
                    key: 'actions',
                    label: '',
                    sorter: false,
                    filter: false,
                },
            ],
            noItemsView: {
                noResults: this.__('airports.overview.filters.empty'),
                noItems: this.__('airports.overview.results.empty'),
            },
        };
    },

    computed: {
        airports() {
            return this.$page.props.airports;
        },
        visibleFields() {
            return this.allFields;
        },
        transformedAirports() {
            const notAvailable = this.__('general.not_available');

            return this.airports.data.map((airport) => ({
                id: airport.id,
                icao_code: airport.icao_code || notAvailable,
                iata_code: airport.iata_code || notAvailable,
                name: airport.name || notAvailable,
                country: airport.country_code_alpha2 || notAvailable,
                country_code_alpha2: airport.country_code_alpha2?.toLowerCase(),
                users: airport.users,
                assets_count: airport.assets_count,
                timezone: airport.timezone.identifier,
            }));
        },
    },

    mounted() {
        const primarySortOption = this.$page.props.sort_order[0];
        this.sortOrder = {
            column: primarySortOption.column,
            asc: primarySortOption.direction === 'asc',
        };

        this.preventParallelRequests();
    },

    methods: {
        edit(airport) {
            this.$inertia.visit(this.route('airports.edit', airport.id));
        },
        deleteAirport(airport) {
            this.$inertia.delete(this.route('airports.destroy', airport.id));
        },
        getActiveUsers(users) {
            return users.filter((user) => user.status.value === 'active');
        },
        updateSorting(event) {
            let sortOrder = event.asc ? 'asc' : 'desc';
            this.$inertia.visit(
                this.route(this.route().current(), {
                    sort: `${event.column}.${sortOrder}`,
                }),
                {
                    preserveState: true,
                }
            );
        },
    },
};
</script>
