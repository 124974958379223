<template>
    <small class="text-muted" style="line-height: 14px">
        <CIcon name="cil-clock" size="sm"/>
        <span v-if="timezoneObject">
            {{ timezoneObject.getLocalTime(this.currentLocale) }}
            ({{ timezoneObject.shortName }})
        </span>
        <span v-else> {{ __('general.not_available') }}</span>
    </small>
</template>
<script>
import {Timezone} from "@/Utils/timezone";

export default {
    name: 'current-time',
    props: {
        timezone: String
    },
    computed: {
        timezoneObject() {
            return this.timezone ? new Timezone(this.timezone) : null
        }
    }
}
</script>
