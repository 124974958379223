<template>
    <CButton
        :color="color"
        :disabled="isProcessing || disabled"
        :shape="shape"
        :type="type"
        :variant="variant"
        @click="$emit('click', $event)"
        style="position: relative"
    >
        <span
            v-if="isProcessing"
            class="position-absolute d-flex justify-content-center align-items-center"
            style="top: 0; right: 0; bottom: 0; left: 0"
        >
            <CSpinner class="spinner" :color="spinnerColor" size="sm" />
        </span>
        <span
            :style="{ visibility: isProcessing ? 'hidden' : 'visible' }"
            class="text-truncate d-block"
        >
            <slot />
        </span>
    </CButton>
</template>

<script>
export default {
    name: 'JetButton',

    props: {
        type: {
            type: String,
            default: 'submit',
        },
        color: {
            type: String,
            default: 'primary',
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        shape: {
            type: String,
            default: undefined,
        },
        variant: {
            type: String,
            default: undefined,
        },
    },

    computed: {
        spinnerColor() {
            if (this.color === 'warning') {
                return '';
            }

            if (!this.variant) {
                return 'white';
            }

            if (this.shape === 'pill') {
                if (this.color === 'danger') {
                    return 'danger';
                }
                return 'primary';
            }

            return 'white';
        },
    },
};
</script>
<style>
.spinner {
    position: absolute;
}
</style>
